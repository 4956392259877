import { type Image, type Prisma } from "@prisma/client";
import { map } from "bluebird";
import { type AppContext } from "~/lib/context";
import { deleteFile } from "~/lib/files/deleteFile";
import { getPublicAssetFilePath } from "~/lib/files/getPublicAssetFilePath";
import { logWarn } from "~/lib/logger";
import { getId } from "~/lib/utils";

export const ImageForDisplay = {
  select: {
    path: true,
    width: true,
    height: true,
  },
} satisfies Prisma.ImageDefaultArgs;

export type ImageForDisplay = Prisma.ImageGetPayload<typeof ImageForDisplay>;

const unsetImagesRelationships = async (ctx: AppContext, images: (Pick<Image, "id"> | null)[]) => {
  if (images.length === 0) {
    return;
  }

  await map(
    images,
    async (image) => {
      if (!image) {
        return;
      }

      await ctx.prisma.image.update({
        where: { id: image.id },
        data: {
          companiesWithCover: { set: [] },
          companiesWithLogo: { set: [] },
          employees_: { set: [] },
          externalEmployee_: { set: [] },
        },
      });
    },
    { concurrency: 10 }
  );
};

export const safeDeleteImage = async (ctx: AppContext, image: Pick<Image, "id" | "bucket" | "path"> | null) => {
  if (!image) {
    return;
  }

  try {
    await unsetImagesRelationships(ctx, [image]);
    await ctx.prisma.image.delete({ where: { id: image.id } });
    await deleteFile(ctx, {
      Bucket: image.bucket,
      Key: getPublicAssetFilePath(image.path),
    });
  } catch (error) {
    logWarn(ctx, "[image] Couldn't delete picture. It is probably used somewhere else!", { error, image });
  }
};

export const safeDeleteImages = async (ctx: AppContext, images: Pick<Image, "id" | "bucket" | "path">[]) => {
  try {
    await unsetImagesRelationships(ctx, images);
    await ctx.prisma.image.deleteMany({ where: { id: { in: images.map(getId) } } });
  } catch (error) {
    logWarn(ctx, "[image] Couldn't delete pictures. Some of them are probably used somewhere else!", {
      error,
      imageIds: images.map(getId),
    });
  }

  void map(
    images,
    async (image) => {
      try {
        await deleteFile(ctx, {
          Bucket: image.bucket,
          Key: getPublicAssetFilePath(image.path),
        });
      } catch (error) {
        logWarn(ctx, "[s3] Couldn't delete picture on bucket.", { error, image });
      }
    },
    { concurrency: 10 }
  );
};

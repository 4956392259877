import { type JobWithMetadata } from "pg-boss";
import { type AppContext } from "~/lib/context";
import { type BaseJobData } from "~/lib/queue/baseJobDataSchema";
import { QueueJobName } from "~/lib/queue/queueJobName";
import { applyCampaignRecommendationDistributionWorkerService } from "~/workers/applyCampaignRecommendationDistribution";
import { createSalaryBandsWorkerService } from "~/workers/createSalaryBands";
import { deleteCompensationReviewCampaignWorkerService } from "~/workers/deleteCompensationReviewCampaign";
import { duplicateSalaryGridForNewVersionWorkerService } from "~/workers/duplicateSalaryGridForNewVersion";
import { generateSpreadsheetWorkerService } from "~/workers/generateSpreadsheet";
import { importSpreadsheetWorkerService } from "~/workers/import/importSpreadsheet";
import { importSpreadsheetRowWorkerService } from "~/workers/import/importSpreadsheetRow";
import { postImportSpreadsheetWorkerService } from "~/workers/import/postImportSpreadsheet";
import { postSyncExternalEmployeesWorkerService } from "~/workers/postSyncExternalEmployees";
import { preSyncExternalEmployeesWorkerService } from "~/workers/preSyncExternalEmployees";
import { refreshCampaignConvertedAmountsWorkerService } from "~/workers/refreshCampaignConvertedAmounts";
import { refreshCampaignEmployeesReviewersWorkerService } from "~/workers/refreshCampaignEmployeesReviewers";
import { startCampaignSandboxWorkerService } from "~/workers/startCampaignSandbox";
import { suggestJobsMappingWorkerService } from "~/workers/suggestJobsMapping";
import { synchroniseCompensationReviewCampaignEmployeesWorkerService } from "~/workers/syncCompensationReviewCampaignEmployees";
import { syncDatasetEmployeesWorkerService } from "~/workers/syncDatasetEmployees";
import { syncExternalEmployeesWorkerService } from "~/workers/syncExternalEmployees";
import { syncSalaryRangeEmployeesWorkerService } from "~/workers/synchroniseSalaryRangeEmployees";
import { updateCampaignBudgetsWorkerService } from "~/workers/updateCampaignBudgets";
import { updateCompanyEmployeesStatsWorkerService } from "~/workers/updateCompanyEmployeesStats";
import { updateCompanyStatsWorkerService } from "~/workers/updateCompanyStats";
import { updateEmployeesStatsWorkerService } from "~/workers/updateEmployeesStats";
import { updateSalaryRangeSettingsWorkerService } from "~/workers/updateSalaryRangeSettings";

export type JobPayload<T> = Pick<
  JobWithMetadata<T>,
  "id" | "singletonKey" | "data" | "retryCount" | "retryLimit" | "output"
>;

export type WorkerParams<T extends BaseJobData> = {
  jobName: QueueJobName;
  service: WorkerService<T>;
};

export type WorkerService<T extends BaseJobData> = (ctx: AppContext, data: T) => Promise<void>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Workers = { jobName: QueueJobName; service: WorkerService<any> }[];

export const getWorkers = () => {
  const workers: Workers = [];

  const register = <T extends BaseJobData>(params: WorkerParams<T>) => {
    workers.push(params);
  };

  register({
    jobName: QueueJobName.CREATE_COMPENSATION_REVIEW_CAMPAIGN_EMPLOYEES,
    service: synchroniseCompensationReviewCampaignEmployeesWorkerService,
  });

  register({
    jobName: QueueJobName.CREATE_SALARY_BANDS,
    service: createSalaryBandsWorkerService,
  });

  register({
    jobName: QueueJobName.POST_SYNC_EXTERNAL_EMPLOYEES,
    service: postSyncExternalEmployeesWorkerService,
  });

  register({
    jobName: QueueJobName.PRE_SYNC_EXTERNAL_EMPLOYEES,
    service: preSyncExternalEmployeesWorkerService,
  });

  register({
    jobName: QueueJobName.SYNC_EXTERNAL_EMPLOYEES,
    service: syncExternalEmployeesWorkerService,
  });

  register({
    jobName: QueueJobName.SYNC_SALARY_RANGE_EMPLOYEES,
    service: syncSalaryRangeEmployeesWorkerService,
  });

  register({
    jobName: QueueJobName.UPDATE_SALARY_RANGE_SETTINGS,
    service: updateSalaryRangeSettingsWorkerService,
  });

  register({
    jobName: QueueJobName.DUPLICATE_SALARY_GRID_FOR_NEW_VERSION,
    service: duplicateSalaryGridForNewVersionWorkerService,
  });

  register({
    jobName: QueueJobName.IMPORT_SPREADSHEET,
    service: importSpreadsheetWorkerService,
  });

  register({
    jobName: QueueJobName.IMPORT_SPREADSHEET_ROW,
    service: importSpreadsheetRowWorkerService,
  });

  register({
    jobName: QueueJobName.POST_IMPORT_SPREADSHEET,
    service: postImportSpreadsheetWorkerService,
  });

  register({
    jobName: QueueJobName.GENERATE_SPREADSHEET,
    service: generateSpreadsheetWorkerService,
  });

  register({
    jobName: QueueJobName.SUGGEST_JOBS_MAPPING,
    service: suggestJobsMappingWorkerService,
  });

  register({
    jobName: QueueJobName.UPDATE_COMPANY_STATS,
    service: updateCompanyStatsWorkerService,
  });

  register({
    jobName: QueueJobName.UPDATE_COMPANY_EMPLOYEES_STATS,
    service: updateCompanyEmployeesStatsWorkerService,
  });

  register({
    jobName: QueueJobName.UPDATE_EMPLOYEES_STATS,
    service: updateEmployeesStatsWorkerService,
  });

  register({
    jobName: QueueJobName.SYNC_DATASET_EMPLOYEES,
    service: syncDatasetEmployeesWorkerService,
  });

  register({
    jobName: QueueJobName.START_COMPENSATION_REVIEW_CAMPAIGN_SANDBOX,
    service: startCampaignSandboxWorkerService,
  });

  register({
    jobName: QueueJobName.DELETE_COMPENSATION_REVIEW_CAMPAIGN,
    service: deleteCompensationReviewCampaignWorkerService,
  });

  register({
    jobName: QueueJobName.REFRESH_CAMPAIGN_CONVERTED_AMOUNTS,
    service: refreshCampaignConvertedAmountsWorkerService,
  });

  register({
    jobName: QueueJobName.APPLY_CAMPAIGN_RECOMMENDATION_DISTRIBUTION,
    service: applyCampaignRecommendationDistributionWorkerService,
  });

  register({
    jobName: QueueJobName.REFRESH_CAMPAIGN_EMPLOYEES_REVIEWERS,
    service: refreshCampaignEmployeesReviewersWorkerService,
  });

  register({
    jobName: QueueJobName.UPDATE_CAMPAIGN_BUDGETS,
    service: updateCampaignBudgetsWorkerService,
  });

  return workers;
};

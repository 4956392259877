import {
  type Company,
  CompensationFrequency,
  type ExternalEmployeeSource,
  ExternalRemunerationStatus,
  ExternalRemunerationType,
} from "@prisma/client";
import { match } from "ts-pattern";
import { isNaN, isNumber } from "~/lib/lodash";
import { type EmployeeData } from "~/services/synchronization/syncExternalEmployees";

export const buildCustomBaseSalaryRemunerationItem = (params: {
  customBaseSalary: string | null | undefined;
  baseSalaryCustomFieldFrequency: CompensationFrequency | null;
  numberMonths: number;
  company: Pick<Company, "id">;
  source: ExternalEmployeeSource;
}) => {
  if (!params.customBaseSalary || !params.baseSalaryCustomFieldFrequency) return null;

  // This will remove any non-digit in the value (such as currency symbol) except for , and .
  const numericCustomBaseSalary = parseInt(params.customBaseSalary.replaceAll(/[^0-9,._]/g, "")) * 100;

  if (!isNumber(numericCustomBaseSalary) || isNaN(numericCustomBaseSalary)) return null;

  const monthsMultiplier = params.numberMonths / 12;

  const amount = match(params.baseSalaryCustomFieldFrequency)
    .with(CompensationFrequency.MONTHLY, () => numericCustomBaseSalary * monthsMultiplier * 12)
    .with(CompensationFrequency.QUARTERLY, () => numericCustomBaseSalary * monthsMultiplier * 4)
    .with(CompensationFrequency.YEARLY, () => numericCustomBaseSalary)
    .exhaustive();

  return {
    company: {
      connect: { id: params.company.id },
    },
    source: params.source,
    externalId: "fix-salary-custom",
    amount: Math.round(amount),
    numberMonths: params.numberMonths,
    status: ExternalRemunerationStatus.LIVE,
    nature: {
      connectOrCreate: {
        where: {
          companyId_source_externalId: {
            companyId: params.company.id,
            source: params.source,
            externalId: "fix-salary-custom",
          },
        },
        create: {
          source: params.source,
          externalId: "fix-salary-custom",
          name: "Fixed salary",
          mappedType: ExternalRemunerationType.FIXED_SALARY,
          company: {
            connect: { id: params.company.id },
          },
        },
      },
    },
  } satisfies EmployeeData["remunerationItems"][number];
};

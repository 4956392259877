import { mapSeries } from "bluebird";
import { type AsyncReturnType } from "type-fest";
import { type CompensationReviewCampaignBudget } from "~/services/compensation-review/campaigns/fetchCampaign";
import { type CompensationReviewContext } from "~/services/compensation-review/compensationReviewContext";
import { prismaCompensationReviewScope } from "~/services/compensation-review/compensationReviewScope";
import { convertPopulationToExternalEmployeeWhereClause } from "~/services/employee-filter/fetchExternalEmployeeFilterOptions";
import { validatePopulationFilters } from "~/services/populations/validatePopulationFilters";

export const enrichIncreaseRulesWithPopulation = async (
  ctx: CompensationReviewContext,
  params: {
    rules: Omit<CompensationReviewCampaignBudget["increaseRules"][number], "id">[];
  }
) => {
  return mapSeries(params.rules, async (rule) => {
    if (!rule.populationId) {
      return { ...rule, employeeIds: null };
    }

    const population = await ctx.prisma.population.findUniqueOrThrow({
      where: { id: rule.populationId },
      select: { query: true, filters: true, includedExternalEmployeeIds: true, excludedExternalEmployeeIds: true },
    });

    const { where } = await convertPopulationToExternalEmployeeWhereClause(ctx, {
      ...population,
      filters: validatePopulationFilters(population.filters),
    });

    const compensationReviewEmployees = await ctx.prisma.compensationReviewEmployee.findMany({
      where: {
        ...prismaCompensationReviewScope(ctx.scope),
        eligibilities: { some: {} },
        externalEmployee: where,
      },
      select: { id: true },
    });

    return {
      ...rule,
      employeeIds: compensationReviewEmployees.map((employee) => employee.id),
    };
  });
};

export type EnrichIncreaseRulesWithPopulationResult = AsyncReturnType<typeof enrichIncreaseRulesWithPopulation>;

export const enrichCompensationReviewRules = async (ctx: CompensationReviewContext) => {
  return mapSeries(ctx.parameters.budgets, async (budget) => {
    return {
      budgetId: budget.id,
      rules: await enrichIncreaseRulesWithPopulation(ctx, { rules: budget.increaseRules }),
    };
  });
};

export type BudgetRules = AsyncReturnType<typeof enrichCompensationReviewRules>[number];

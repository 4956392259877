import { type Company, type Prisma, type User, UserLocale } from "@prisma/client";
import { formatISO } from "date-fns";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { type Recipient } from "~/lib/external/sendinblue/client";
import { type AuthenticatedUser } from "~/lib/session";
import { assertNotNil } from "~/lib/utils";
import { type MarketPositioningEmailEmployee } from "~/pages/emails/market-positioning";
import { type CompensationReviewContext } from "~/services/compensation-review/compensationReviewContext";
import { formatExternalEmployeeName } from "~/services/external-employee";
import { formatUserFullName } from "~/services/user/utils";
import { sendEmailWithBcc, sendEmailWithTo } from "./sendEmail";

type MarketPositioningInput = {
  belowTargetEmployees: MarketPositioningEmailEmployee[];
  wayBelowTargetEmployees: MarketPositioningEmailEmployee[];
  users: Recipient[];
  url: string;
};

export const sendMarketPositioningEmail = async (ctx: AppContext, input: MarketPositioningInput): Promise<void> => {
  await sendEmailWithTo(
    ctx,
    {
      path: "market-positioning",
      props: {
        belowTargetEmployees: input.belowTargetEmployees,
        wayBelowTargetEmployees: input.wayBelowTargetEmployees,
        url: input.url,
      },
    },
    {
      sender: config.emails.senders.noReply,
      to: input.users,
      tags: ["market_movement"],
      subject: ctx.t("emails.market-positioning.subject"),
    }
  );
};

type BaseInvitationEmail = {
  sender: Pick<User, "firstName" | "lastName" | "locale">;
  company: Company;
};

type InvitationEmailWithTo = BaseInvitationEmail & {
  email: string;
};

type InvitationEmailWithBcc = BaseInvitationEmail & {
  emails: string[];
};

export const sendInvitationEmail = async (ctx: AppContext, invitation: InvitationEmailWithTo): Promise<void> => {
  const { sender, company, email } = invitation;
  const name = `${sender.firstName} ${sender.lastName}`;

  await sendEmailWithTo(
    ctx,
    {
      path: "welcome",
      props: { name, company: { name: company.name } },
    },
    {
      sender: config.emails.senders.onboarding,
      to: [{ email, locale: sender.locale }],
      tags: ["user_invitation"],
      subject: ctx.t("emails.welcome.subject", { name }),
    }
  );
};

export const sendInvitationDeniedEmail = async (
  ctx: AppContext,
  invitation: { author: Pick<User, "firstName" | "email" | "locale"> | null; email: string }
): Promise<void> => {
  const { author, email } = invitation;

  const notNilAuthor = assertNotNil(author);

  await sendEmailWithTo(
    ctx,
    {
      path: "invitation-denied",
      props: { author: notNilAuthor, email },
    },
    {
      sender: config.emails.senders.onboarding,
      to: [{ email: notNilAuthor.email, locale: notNilAuthor.locale }],
      tags: ["user_invitation"],
      subject: ctx.t("emails.invitation-denied.subject", { email }),
    }
  );
};

export const sendManyInvitationEmails = async (ctx: AppContext, invitation: InvitationEmailWithBcc): Promise<void> => {
  const { sender, company, emails } = invitation;
  const name = `${sender.firstName} ${sender.lastName}`;

  await sendEmailWithBcc(
    ctx,
    {
      path: "welcome",
      props: { name, company: { name: company.name } },
    },
    {
      sender: config.emails.senders.onboarding,
      bcc: emails.map((email) => ({ email, locale: sender.locale })),
      tags: ["user_invitation"],
      subject: ctx.t("emails.welcome.subject", { name }),
    }
  );
};

type AccessRevokedEmail = {
  user: User;
};

export const sendAccessRevokedEmail = async (ctx: AppContext, input: AccessRevokedEmail): Promise<void> => {
  await sendEmailWithTo(
    ctx,
    {
      path: "access-revoked",
      props: { firstName: input.user.firstName },
    },
    {
      sender: config.emails.senders.onboarding,
      to: [input.user],
      replyTo: config.emails.senders.onboarding,
      tags: ["access_revoked"],
      subject: ctx.t("emails.access-revoked.subject"),
    }
  );
};

type MarketDataSearchEmail = {
  user: AuthenticatedUser;
  email: string;
  body: string;
  hash: string;
};

export const sendMarketDataSearchEmail = async (ctx: AppContext, input: MarketDataSearchEmail): Promise<void> => {
  const name = `${input.user.firstName} ${input.user.lastName}`;

  await sendEmailWithTo(
    ctx,
    {
      path: "market-data-search",
      props: {
        name: `${input.user.firstName} ${input.user.lastName}`,
        company: input.user.company.name,
        body: Buffer.from(input.body).toString("base64"),
        hash: input.hash,
      },
    },
    {
      sender: config.emails.senders.onboarding,
      to: [{ email: input.email, locale: input.user.locale }],
      replyTo: config.emails.senders.onboarding,
      tags: ["market_data_search"],
      subject: ctx.t("emails.market-data-search.subject", {
        name,
        companyName: input.user.company.name,
      }),
    }
  );
};

type LoginLinkEmail = {
  email: string;
  token: string;
  locale?: UserLocale;
};

export const sendLoginLinkEmail = async (ctx: AppContext, input: LoginLinkEmail): Promise<void> => {
  const locale = input.locale ?? UserLocale.EN;

  await sendEmailWithTo(
    ctx,
    {
      path: "auth-login-link",
      props: { token: input.token },
    },
    {
      sender: config.emails.senders.onboarding,
      to: [{ email: input.email, locale }],
      replyTo: config.emails.senders.onboarding,
      tags: ["auth_login_link"],
      subject: ctx.t("emails.login-link.subject", { lng: locale.toLowerCase() }),
    }
  );
};

type ProviderConfirmationEmail = {
  email: string;
  token: string;
  locale?: UserLocale;
};

export const sendUserProviderConfirmationEmail = async (
  ctx: AppContext,
  input: ProviderConfirmationEmail
): Promise<void> => {
  const locale = input.locale ?? UserLocale.EN;

  await sendEmailWithTo(
    ctx,
    {
      path: "user-provider-confirmation",
      props: { token: input.token },
    },
    {
      sender: config.emails.senders.noReply,
      to: [{ email: input.email, locale }],
      tags: ["user_provider_confirmation"],
      subject: ctx.t("emails.user-provider-confirmation.subject"),
    }
  );
};

type CompensationReviewCampaignStartEmail = {
  campaignId: number;
  campaignName: string;
  customMessage: string;
  email: string;
  locale?: UserLocale;
};

export const sendCompensationReviewCampaignStartEmail = async (
  ctx: AppContext,
  input: CompensationReviewCampaignStartEmail
): Promise<void> => {
  const locale = input.locale ?? UserLocale.EN;

  await sendEmailWithTo(
    ctx,
    {
      path: "compensation-review-campaign-start",
      props: {
        campaignId: input.campaignId,
        campaignName: input.campaignName,
        customMessage: Buffer.from(input.customMessage).toString("base64"),
      },
    },
    {
      sender: config.emails.senders.noReply,
      to: [{ email: input.email, locale }],
      tags: ["compensation_review_campaign_start"],
      subject: ctx.t("emails.compensation-review-campaign-start.subject", { lng: locale.toLowerCase() }),
    }
  );
};

type CompensationReviewCampaignReviewerReminderEmail = {
  campaignId: number;
  campaignName: string;
  campaignEndDate: Date | null;
  recommendationsCount: number;
  recommendationsNames: string[];
  email: string;
  locale?: UserLocale;
};

export const sendCompensationReviewCampaignReviewerReminderEmail = async (
  ctx: AppContext,
  input: CompensationReviewCampaignReviewerReminderEmail
): Promise<void> => {
  const locale = input.locale ?? UserLocale.EN;

  await sendEmailWithTo(
    ctx,
    {
      path: "compensation-review-campaign-reviewer-reminder",
      props: {
        campaignId: input.campaignId,
        campaignName: input.campaignName,
        campaignEndDate: input.campaignEndDate?.toISOString() ?? null,
        recommendationsCount: input.recommendationsCount,
        recommendationsNames: input.recommendationsNames,
      },
    },
    {
      sender: config.emails.senders.noReply,
      to: [{ email: input.email, locale }],
      tags: ["compensation_review_campaign_reviewer_reminder"],
      subject: ctx.t("emails.compensation-review-campaign-reviewer-reminder.subject", {
        count: input.recommendationsCount,
        lng: locale.toLowerCase(),
      }),
    }
  );
};

export const sendCompensationReviewRecommendationRequestedEmail = async (
  ctx: CompensationReviewContext,
  input: {
    originUser: AuthenticatedUser;
    user: Pick<User, "email" | "locale">;
    recommendation: Prisma.CompensationReviewRecommendationGetPayload<{
      include: { employee: { include: { externalEmployee: true } } };
    }>;
  }
) => {
  const forEmployee = formatExternalEmployeeName(input.recommendation.employee.externalEmployee);

  await sendEmailWithTo(
    ctx,
    {
      path: "compensation-review-recommendation-requested",
      props: {
        scope: ctx.scope,
        for: forEmployee,
        by: formatUserFullName(input.originUser),
        comment: input.recommendation.sentBackComment,
      },
    },
    {
      sender: config.emails.senders.noReply,
      to: [input.user],
      tags: ["compensation_review_recommendation_requested"],
      subject: ctx.t("emails.compensation-review-recommendation-requested.title", { forEmployee }),
    }
  );
};

export const sendOffCycleReviewRequestedEmail = async (
  ctx: AppContext,
  input: {
    user: Pick<User, "email" | "locale">;
    request: Prisma.OffCycleReviewRequestGetPayload<{
      include: { externalEmployee: true; user: true };
    }>;
  }
) => {
  const forEmployee = formatExternalEmployeeName(input.request.externalEmployee);
  const by = formatUserFullName(input.request.user);

  await sendEmailWithTo(
    ctx,
    {
      path: "off-cycle-review-requested",
      props: {
        for: forEmployee,
        by,
        reason: input.request.reason,
        effectiveDate: formatISO(input.request.effectiveDate),
        comment: input.request.comment,
      },
    },
    {
      sender: config.emails.senders.noReply,
      to: [input.user],
      tags: ["off_cycle_review_requested"],
      subject: ctx.t("emails.off-cycle-review-requested.title", { forEmployee, by }),
    }
  );
};

export const sendOffCycleReviewApprovedEmail = async (
  ctx: AppContext,
  input: {
    user: Pick<User, "email" | "locale">;
    request: Prisma.OffCycleReviewRequestGetPayload<{
      include: { externalEmployee: true; approvedBy: true };
    }>;
  }
) => {
  if (!input.request.approvedBy) return;

  const forEmployee = formatExternalEmployeeName(input.request.externalEmployee);
  const by = formatUserFullName(input.request.approvedBy);

  await sendEmailWithTo(
    ctx,
    {
      path: "off-cycle-review-approved",
      props: {
        for: forEmployee,
        by,
        reason: input.request.reason,
        effectiveDate: formatISO(input.request.effectiveDate),
        comment: input.request.comment,
      },
    },
    {
      sender: config.emails.senders.noReply,
      to: [input.user],
      tags: ["off_cycle_review_approved"],
      subject: ctx.t("emails.off-cycle-review-approved.title", { forEmployee, by }),
    }
  );
};

export const sendOffCycleReviewDeclinedEmail = async (
  ctx: AppContext,
  input: {
    user: Pick<User, "email" | "locale">;
    request: Prisma.OffCycleReviewRequestGetPayload<{
      include: { externalEmployee: true; declinedBy: true };
    }>;
  }
) => {
  if (!input.request.declinedBy) return;

  const forEmployee = formatExternalEmployeeName(input.request.externalEmployee);
  const by = formatUserFullName(input.request.declinedBy);

  await sendEmailWithTo(
    ctx,
    {
      path: "off-cycle-review-declined",
      props: {
        for: forEmployee,
        by,
        reason: input.request.reason,
        effectiveDate: formatISO(input.request.effectiveDate),
        comment: input.request.comment,
        declineComment: input.request.declineComment ?? ctx.t("common.not-applicable"),
      },
    },
    {
      sender: config.emails.senders.noReply,
      to: [input.user],
      tags: ["off_cycle_review_declined"],
      subject: ctx.t("emails.off-cycle-review-declined.title", { forEmployee, by }),
    }
  );
};

export const sendOffCycleReviewPendingEmail = async (
  ctx: AppContext,
  input: {
    user: Pick<User, "email" | "locale">;
    request: Prisma.OffCycleReviewRequestGetPayload<{
      include: { externalEmployee: true };
    }>;
  }
) => {
  const forEmployee = formatExternalEmployeeName(input.request.externalEmployee);

  await sendEmailWithTo(
    ctx,
    {
      path: "off-cycle-review-pending",
      props: {
        for: forEmployee,
        reason: input.request.reason,
        effectiveDate: formatISO(input.request.effectiveDate),
        comment: input.request.comment,
      },
    },
    {
      sender: config.emails.senders.noReply,
      to: [input.user],
      tags: ["off_cycle_review_pending"],
      subject: ctx.t("emails.off-cycle-review-pending.title", { forEmployee }),
    }
  );
};

export const sendOffCycleReviewCompletedEmail = async (
  ctx: AppContext,
  input: {
    user: Pick<User, "email" | "locale">;
    request: Prisma.OffCycleReviewRequestGetPayload<{
      include: { externalEmployee: true };
    }>;
  }
) => {
  const forEmployee = formatExternalEmployeeName(input.request.externalEmployee);

  await sendEmailWithTo(
    ctx,
    {
      path: "off-cycle-review-completed",
      props: {
        for: forEmployee,
        reason: input.request.reason,
        effectiveDate: formatISO(input.request.effectiveDate),
        comment: input.request.comment,
      },
    },
    {
      sender: config.emails.senders.noReply,
      to: [input.user],
      tags: ["off_cycle_review_completed"],
      subject: ctx.t("emails.off-cycle-review-completed.title", { forEmployee }),
    }
  );
};

import { type Prisma } from "@prisma/client";
import { match, P } from "ts-pattern";
import { type AsyncReturnType } from "type-fest";
import { type ExternalEmployeeForDisplay } from "~/components/external-employee/ExternalEmployeeCell";
import { type AppContext } from "~/lib/context";
import { ensure } from "~/lib/ensure";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { chain, compact, includes, lowerCase } from "~/lib/lodash";
import { logWarn } from "~/lib/logger";
import { buildPaginationResult, type OrderParams, prismaPaginationParams } from "~/lib/pagination";
import { and } from "~/lib/prismaHelpers";
import { type FetchCompensationReviewEmployeesInput } from "~/pages/api/compensation-review/fetch-compensation-review-employees";
import { externalEmployeeFieldsSelectForDisplay } from "~/services/additional-field/getAllAdditionalFields";
import { MAX_MAX_REVIEWERS_COUNT } from "~/services/compensation-review/campaigns/admin/createCampaign";
import {
  type EmployeeWithReviewerIds,
  getEmployeeReviewerIds,
  selectReviewerIds,
} from "~/services/compensation-review/campaigns/admin/employeeReviewers";
import { type CompensationReviewContext } from "~/services/compensation-review/compensationReviewContext";
import {
  type CompensationReviewScope,
  prismaCompensationReviewScope,
} from "~/services/compensation-review/compensationReviewScope";
import {
  selectCompensationReviewCurrency,
  transformCompensationReviewCurrency,
} from "~/services/compensation-review/shared/transformCompensationReviewCurrency";
import {
  buildCompensationReviewEmployeeFilterWhereClauses,
  fetchCompensationReviewEmployeeFilterOptions,
} from "~/services/employee-filter/fetchCompensationReviewEmployeeFilterOptions";
import { LEVELS_MAP } from "~/services/employee/employeeLevel";
import { externalEmployeeSelectForAutocomplete } from "~/services/external-employee/autocompleteListCompanyExternalEmployees";
import { selectExternalEmployeeUserPicture } from "~/services/external-employee/selectExternalEmployeeUserForPicture";

export const externalEmployeeSelectForDisplay = {
  id: true,
  firstName: true,
  lastName: true,
  employeeNumber: true,
  externalId: true,
  status: true,
  deletedAt: true,
  ...selectExternalEmployeeUserPicture,
} satisfies Prisma.ExternalEmployeeSelect;

export const buildFetchCompensationReviewEmployeesInput = (input?: Partial<FetchCompensationReviewEmployeesInput>) => {
  return {
    onlyEligible: null,
    onlyIneligible: null,
    onlyEligibleToBudgetId: null,
    onlyIneligibleToBudgetId: null,
    onlyPromoted: null,
    onlyNotPromoted: null,
    extraWhere: null,
    pagination: null,
    order: null,
    query: null,
    filters: null,

    ...input,
  };
};

export const compensationReviewEmployeeSalaryRangeSelect = {
  id: true,
  min: true,
  max: true,
  midpoint: true,
  band: {
    select: {
      id: true,
      measure: true,
      currency: true,
      job: { select: { id: true, name: true } },
      location: { select: { id: true, name: true } },
      ranges: { select: { id: true, level: { select: { id: true, name: true, position: true } } } },
    },
  },
  level: { select: { id: true, name: true, position: true } },
} satisfies Prisma.SalaryRangeSelect;

export type CompensationReviewEmployeeSalaryRange = Prisma.SalaryRangeGetPayload<{
  select: typeof compensationReviewEmployeeSalaryRangeSelect;
}>;

export const fetchCompensationReviewEmployees = async (
  ctx: CompensationReviewContext,
  params: FetchCompensationReviewEmployeesInput
) => {
  ensure(() => ctx.permissions.canAccessConfiguration);

  const whereFilters = params.filters
    ? await buildCompensationReviewEmployeeFilterWhereClauses(ctx, params.filters)
    : undefined;

  const where = {
    ...prismaCompensationReviewScope(params.scope),
    ...(params.onlyEligible && { eligibilities: { some: {} } }),
    ...(params.onlyIneligible && { eligibilities: { none: {} } }),
    ...(params.onlyEligibleToBudgetId && { eligibilities: { some: { budgetId: params.onlyEligibleToBudgetId } } }),
    ...(params.onlyIneligibleToBudgetId && {
      eligibilities: { none: { budgetId: params.onlyIneligibleToBudgetId } },
    }),
    ...(params.onlyPromoted && { isPromoted: true }),
    ...(params.onlyNotPromoted && { isPromoted: false }),
    ...(params.query && { AND: searchCompensationReviewEmployee(params.query) }),
  } satisfies Prisma.CompensationReviewEmployeeWhereInput;

  const paginationWhere = and([where, ...(whereFilters ?? []), params.extraWhere]);

  const filterOptions = await fetchCompensationReviewEmployeeFilterOptions(ctx, { scope: where });

  const employees = await ctx.prisma.compensationReviewEmployee.findMany({
    where: paginationWhere,
    select: {
      id: true,
      isPromoted: true,
      effectiveDate: true,
      baseSalary: true,
      variablePay: true,
      totalCash: true,
      convertedBaseSalary: true,
      defaultSalaryPeriod: true,
      recommendationOrigin: true,
      currency: { select: selectCompensationReviewCurrency },
      hasCustomReviewChain: true,
      ...selectReviewerIds,
      onTargetEarnings: true,
      convertedOnTargetEarnings: true,
      targetRangeAfterPromotion: { select: compensationReviewEmployeeSalaryRangeSelect },
      performanceRating: {
        select: {
          id: true,
          name: true,
          description: true,
          position: true,
        },
      },
      eligibilities: {
        select: {
          id: true,
          budgetId: true,
          budgetedAmount: true,
          annualPerformanceOnTargetBonus: true,
          annualPerformanceBonusPayoutPercentage: true,
        },
        orderBy: { id: "asc" },
      },
      adjustments: true,
      externalEmployee: {
        select: {
          ...externalEmployeeSelectForDisplay,
          ...externalEmployeeFieldsSelectForDisplay,
          performanceReviewRating: { select: { name: true, description: true } },
          hireDate: true,
          manager: {
            select: {
              ...externalEmployeeSelectForDisplay,
            },
          },
          mappedEmployee: {
            select: {
              job: {
                select: {
                  id: true,
                  name: true,
                },
              },
              mappingLocation: {
                select: {
                  id: true,
                  name: true,
                  country: {
                    select: {
                      id: true,
                      alpha2: true,
                    },
                  },
                },
              },
              location: {
                select: {
                  id: true,
                  name: true,
                  country: {
                    select: {
                      id: true,
                      alpha2: true,
                    },
                  },
                },
              },
              level: true,
              liveEmployeeStats: {
                select: {
                  baseSalaryMarketPositioning: true,
                  baseSalaryPercentageDifference: true,
                  onTargetEarningsMarketPositioning: true,
                  onTargetEarningsPercentageDifference: true,
                  totalCashMarketPositioning: true,
                  totalCashPercentageDifference: true,
                },
              },
            },
          },
          job: {
            select: {
              id: true,
              name: true,
              mappedJob: {
                select: {
                  id: true,
                  name: true,
                },
              },
            },
          },
          location: {
            select: {
              id: true,
              name: true,
              country: {
                select: {
                  id: true,
                  alpha2: true,
                },
              },
              mappedLocation: {
                select: {
                  id: true,
                  name: true,
                  country: {
                    select: {
                      id: true,
                      alpha2: true,
                    },
                  },
                },
              },
            },
          },
          level: {
            select: {
              id: true,
              name: true,
              mappedLevel: true,
            },
          },
        },
      },
    },
    orderBy: buildCompensationReviewEmployeeOrderBy(ctx, params.order),
    ...(params.pagination && {
      ...prismaPaginationParams(params.pagination),
    }),
  });

  const employeesWithCurrency = employees.map((employee) => ({
    ...employee,
    currency: transformCompensationReviewCurrency(employee.currency),
  }));

  const employeesWithSalaryRangeEmployees = await enrichEmployeesWithSalaryRangeEmployee(ctx, {
    employees: employeesWithCurrency,
  });

  const items = await enrichEmployeesWithReviewers(ctx, {
    scope: params.scope,
    employees: employeesWithSalaryRangeEmployees,
  });

  const count = await ctx.prisma.compensationReviewEmployee.count({
    where: paginationWhere,
  });

  return buildPaginationResult({
    items,
    count,
    meta: { filterOptions },
    pagination: params.pagination ?? undefined,
  });
};

export type FetchCompensationReviewEmployeesResult = AsyncReturnType<typeof fetchCompensationReviewEmployees>;

export const searchCompensationReviewEmployee = (query: string) => {
  const matches = { contains: query, mode: "insensitive" } as const;
  const lowerQuery = lowerCase(query);
  const employeeLevels = Object.entries(LEVELS_MAP)
    .filter(([key]) => includes(lowerCase(key), lowerQuery))
    .map(([, value]) => value);

  return {
    externalEmployee: {
      OR: compact([
        { normalisedColumns: matches },
        { level: { name: matches } },
        employeeLevels.length > 0 && { level: { mappedLevel: { in: employeeLevels } } },
        { job: { OR: [{ name: matches }, { mappedJob: { name: matches } }] } },
        {
          mappedEmployee: {
            OR: compact([
              { job: { name: matches } },
              { location: { OR: [{ name: matches }, { country: { name: matches } }] } },
              employeeLevels.length > 0 && { level: { in: employeeLevels } },
            ]),
          },
        },
        {
          location: {
            OR: [
              { name: matches },
              { country: { name: matches } },
              { mappedLocation: { OR: [{ name: matches }, { country: { name: matches } }] } },
            ],
          },
        },
      ]),
    },
  };
};

export const buildCompensationReviewEmployeeOrderBy = (ctx: AppContext, order: OrderParams | null) => {
  const { column, direction } = order ?? { column: null, direction: "asc" };
  const directionNullsLast = { sort: direction, nulls: "last" } as const;

  const defaultOrder = [
    { externalEmployee: { firstName: directionNullsLast } },
    { externalEmployee: { lastName: directionNullsLast } },
  ] satisfies Prisma.Enumerable<Prisma.CompensationReviewEmployeeOrderByWithRelationInput>;

  return match<string | null, Prisma.Enumerable<Prisma.CompensationReviewEmployeeOrderByWithRelationInput>>(column)
    .with("name", () => [
      { externalEmployee: { firstName: directionNullsLast } },
      { externalEmployee: { lastName: directionNullsLast } },
    ])
    .with("manager", () => [
      { externalEmployee: { manager: { firstName: directionNullsLast } } },
      { externalEmployee: { manager: { lastName: directionNullsLast } } },
    ])
    .with("email", () => ({ externalEmployee: { userPermissions: { user: { email: direction } } } }))
    .with("gender", () => ({ externalEmployee: { gender: directionNullsLast } }))
    .with("hire-date", () => ({
      externalEmployee: { hireDate: directionNullsLast },
    }))
    .with("job", () => [
      { externalEmployee: { job: { name: direction } } },
      { externalEmployee: { job: { mappedJob: { name: direction } } } },
      { externalEmployee: { mappedEmployee: { job: { name: direction } } } },
    ])
    .with("location", () => [
      { externalEmployee: { location: { country: { name: direction } } } },
      { externalEmployee: { location: { mappedLocation: { country: { name: direction } } } } },
      { externalEmployee: { mappedEmployee: { location: { country: { name: direction } } } } },
      { externalEmployee: { location: { name: direction } } },
      { externalEmployee: { location: { mappedLocation: { name: direction } } } },
      { externalEmployee: { mappedEmployee: { location: { name: direction } } } },
    ])
    .with("performance-review-rating", () => ({
      performanceRating: { position: direction },
    }))
    .with("is-promoted", () => ({ isPromoted: direction }))
    .with("on-target-earnings", () => ({ convertedOnTargetEarnings: direction }))
    .with(P.string.regex(/reviewer-(\d)+/), (column) => {
      const [, rawIndex] = column.split("-") as [string, string];

      if (rawIndex) {
        const index = parseInt(rawIndex);
        if (index > MAX_MAX_REVIEWERS_COUNT) {
          return defaultOrder;
        }

        const column = `reviewer${index}`;

        return [
          { [column]: { externalEmployee: { firstName: directionNullsLast } } },
          { [column]: { externalEmployee: { lastName: directionNullsLast } } },
        ];
      }

      return defaultOrder;
    })
    .otherwise((column) => {
      if (column) {
        logWarn(ctx, "[warn] Unhandled compensationReviewEmployee order column", { column });
      }

      return defaultOrder;
    });
};

const enrichEmployeesWithReviewers = async <
  T extends EmployeeWithReviewerIds & {
    externalEmployee: ExternalEmployeeForDisplay;
  },
>(
  ctx: CompensationReviewContext,
  params: {
    scope: CompensationReviewScope;
    employees: T[];
  }
) => {
  const reviewers = await ctx.prisma.compensationReviewReviewer.findMany({
    where: {
      ...prismaCompensationReviewScope(params.scope),
      id: {
        in: chain(params.employees).flatMap(getEmployeeReviewerIds).compact().uniq().value(),
      },
    },
    select: {
      id: true,
      externalEmployee: { select: externalEmployeeSelectForAutocomplete },
    },
  });

  return params.employees.map((employee) => {
    const reviewerIds = getEmployeeReviewerIds(employee).slice(0, ctx.parameters.maxReviewersCount);

    return {
      ...employee,

      reviewers: chain(reviewerIds)
        .map((reviewerId) => reviewers.find((reviewer) => reviewer.id === reviewerId) ?? null)
        .value(),
    };
  });
};

export const enrichEmployeesWithSalaryRangeEmployee = async <
  T extends { id: number; externalEmployee: { id: number } },
>(
  ctx: AppContext,
  params: {
    employees: T[];
  }
) => {
  const user = getRequiredUser(ctx);

  const salaryRangeEmployees = user.company.defaultSalaryGridId
    ? await ctx.prisma.salaryRangeEmployee.findMany({
        where: {
          gridId: user.company.defaultSalaryGridId,
          externalEmployeeId: { in: params.employees.map((employee) => employee.externalEmployee.id) },
        },
        select: {
          externalEmployeeId: true,
          onTargetEarningsRangePositioning: true,
          onTargetEarningsRangePenetration: true,
          range: { select: compensationReviewEmployeeSalaryRangeSelect },
        },
      })
    : [];

  return params.employees.map((employee) => {
    const salaryRangeEmployee = salaryRangeEmployees.find(
      (salaryRangeEmployee) => salaryRangeEmployee.externalEmployeeId === employee.externalEmployee.id
    );

    return {
      ...employee,
      salaryRangeEmployee: salaryRangeEmployee ?? null,
    };
  });
};

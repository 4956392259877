import {
  type CompensationReviewAdjustment,
  type CompensationReviewRecommendation,
  CompensationReviewRecommendationStatus,
} from "@prisma/client";
import { compact } from "~/lib/lodash";
import { assertNotNil, isIn } from "~/lib/utils";

export const canUpdateRecommendation = (recommendation: Pick<CompensationReviewRecommendation, "status">) => {
  return isIn(recommendation.status, [
    CompensationReviewRecommendationStatus.PENDING_FOR_REVIEW,
    CompensationReviewRecommendationStatus.PENDING_FOR_SUBMISSION,
    CompensationReviewRecommendationStatus.SUBMITTED,
  ]);
};

export const isPendingRecommendation = (recommendation: Pick<CompensationReviewRecommendation, "status">) => {
  return isIn(recommendation.status, [
    CompensationReviewRecommendationStatus.PENDING_FOR_REVIEW,
    CompensationReviewRecommendationStatus.PENDING_FOR_SUBMISSION,
  ]);
};

export const isDraftRecommendation = (recommendation: Pick<CompensationReviewRecommendation, "status">) => {
  return recommendation.status === CompensationReviewRecommendationStatus.PENDING_FOR_SUBMISSION;
};

export const isCompletedRecommendation = (recommendation: Pick<CompensationReviewRecommendation, "status">) => {
  return isIn(recommendation.status, [
    CompensationReviewRecommendationStatus.SUBMITTED,
    CompensationReviewRecommendationStatus.LOCKED,
  ]);
};

type ComputedLatestAdjustmentRecommendation = Pick<CompensationReviewRecommendation, "id" | "position" | "status"> & {
  adjustments: CompensationReviewAdjustment[];
};

export const computeAdjustmentPayloadFromPreviousRecommendations = (params: {
  recommendationId: number;
  recommendations: ComputedLatestAdjustmentRecommendation[];
}) => {
  const recommendation = assertNotNil(
    params.recommendations.find((recommendation) => recommendation.id === params.recommendationId)
  );

  const validRecommendations = params.recommendations.filter(
    (item) =>
      item.position < recommendation.position &&
      isIn(item.status, [
        CompensationReviewRecommendationStatus.SUBMITTED,
        CompensationReviewRecommendationStatus.LOCKED,
      ])
  );

  const adjustments = compact(
    recommendation.adjustments.map((adjustment) => {
      const matchesAdjustment = (testAdjustment: ComputedLatestAdjustmentRecommendation["adjustments"][number]) =>
        testAdjustment.compensationItem === adjustment.compensationItem &&
        testAdjustment.budgetId === adjustment.budgetId &&
        testAdjustment.submittedAmount !== null;

      const validRecommendationWithAdjustment = validRecommendations.findLast((recommendation) =>
        recommendation.adjustments.find(matchesAdjustment)
      );

      if (!validRecommendationWithAdjustment) {
        return null;
      }

      const validAdjustment = assertNotNil(validRecommendationWithAdjustment.adjustments.find(matchesAdjustment));

      return {
        ...validAdjustment,

        id: adjustment.id,
        submittedAmount: assertNotNil(validAdjustment.submittedAmount),
      };
    })
  );

  const canSubmitRecommendation = adjustments.length === recommendation.adjustments.length;

  return {
    adjustments,
    canSubmitRecommendation,
  };
};

import { SpreadsheetImportStatus, SpreadsheetImportTemplate } from "@prisma/client";
import { match } from "ts-pattern";
import { number, object } from "yup";
import { type AppContext } from "~/lib/context";
import { makeSingletonKey } from "~/lib/makeSingletonKey";
import { BaseJobDataSchema } from "~/lib/queue/baseJobDataSchema";
import { QueueJobName } from "~/lib/queue/queueJobName";
import { sendJob } from "~/lib/queue/sendJob";
import { type YupOutputType } from "~/lib/utils";
import { startImportInitial } from "~/services/external-employee/import/process/initial/startImportInitial";
import { startImportPartial } from "~/services/external-employee/import/process/partial/startImportPartial";
import { updateImportStatus } from "~/services/external-employee/import/updateImportStatus";

const ImportSpreadsheetJobDataSchema = BaseJobDataSchema.concat(
  object({
    companyId: number().required(),
    importId: number().required(),
  })
);

export type ImportSpreadsheetJobData = YupOutputType<typeof ImportSpreadsheetJobDataSchema>;

export const importSpreadsheetWorkerService = async (ctx: AppContext, data: ImportSpreadsheetJobData) => {
  const input = ImportSpreadsheetJobDataSchema.validateSync(data, { abortEarly: false });

  const externalEmployeeImport = await ctx.prisma.externalEmployeeSpreadsheetImport.findUniqueOrThrow({
    where: {
      companyId: input.companyId,
      id: input.importId,
    },
  });

  await updateImportStatus(ctx, {
    importId: externalEmployeeImport.id,
    status: SpreadsheetImportStatus.IMPORTING,
  });

  await match(externalEmployeeImport.spreadsheetTemplate)
    .with(SpreadsheetImportTemplate.INITIAL, () => {
      if (!externalEmployeeImport.importMode) {
        throw new ImportModeMissingError();
      }

      return startImportInitial(ctx, {
        companyId: input.companyId,
        collectedAt: new Date(),
        import: externalEmployeeImport,
      });
    })
    .with(SpreadsheetImportTemplate.PARTIAL, () => {
      return startImportPartial(ctx, {
        companyId: input.companyId,
        import: externalEmployeeImport,
      });
    })
    .exhaustive();

  await updateImportStatus(ctx, {
    importId: externalEmployeeImport.id,
    status: SpreadsheetImportStatus.IMPORTING,
  });
};

export const sendImportSpreadsheetJob = async (ctx: AppContext, data: ImportSpreadsheetJobData) => {
  await sendJob(ctx, {
    jobName: QueueJobName.IMPORT_SPREADSHEET,
    data,
    options: {
      singletonKey: makeSingletonKey({ companyId: data.companyId, importId: data.importId }, { unique: false }),
    },
  });
};

class ImportModeMissingError extends Error {
  constructor() {
    super("Import mode is missing from ExternalEmployeeSpreadsheetImport");
  }
}

import { number, object } from "yup";
import { type AppContext } from "~/lib/context";
import { makeSingletonKey } from "~/lib/makeSingletonKey";
import { BaseJobDataSchema } from "~/lib/queue/baseJobDataSchema";
import { QueueJobName } from "~/lib/queue/queueJobName";
import { sendJob } from "~/lib/queue/sendJob";
import { type YupOutputType } from "~/lib/utils";
import { fetchCompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";
import { CompensationReviewScopeSchema } from "~/services/compensation-review/compensationReviewScope";
import { refreshCampaignEmployeesReviewers } from "~/services/compensation-review/shared/updateCompensationReviewMaxReviewersCount";

const RefreshCampaignEmployeesReviewersJobDataSchema = BaseJobDataSchema.concat(
  object({
    scope: CompensationReviewScopeSchema.required(),
    maxReviewersCount: number().required(),
  })
);

export type RefreshCampaignEmployeesReviewersJobData = YupOutputType<
  typeof RefreshCampaignEmployeesReviewersJobDataSchema
>;

export const refreshCampaignEmployeesReviewersWorkerService = async (
  ctx: AppContext,
  data: RefreshCampaignEmployeesReviewersJobData
) => {
  const { scope, maxReviewersCount } = RefreshCampaignEmployeesReviewersJobDataSchema.validateSync(data, {
    abortEarly: false,
  });

  const context = await fetchCompensationReviewCampaignContext(ctx, { campaignId: scope.id });

  await refreshCampaignEmployeesReviewers(context, {
    maxReviewersCount,
  });
};

export const sendRefreshCampaignEmployeesReviewersJob = async (
  ctx: AppContext,
  data: RefreshCampaignEmployeesReviewersJobData
) => {
  await sendJob(ctx, {
    jobName: QueueJobName.REFRESH_CAMPAIGN_EMPLOYEES_REVIEWERS,
    data,
    options: {
      singletonKey: makeSingletonKey({ companyId: data.companyId, campaignId: data.scope.id }, { unique: false }),
    },
  });
};

import {
  CompensationReviewBudgetAdjustmentCriteria,
  CompensationReviewBudgetMarketDataPositioningMeasure,
  EmployeeMarketPositioning,
  EmployeeStatus,
  type Prisma,
  type SalaryRangeEmployeeRangePositioning,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { match } from "ts-pattern";
import { type AsyncReturnType } from "type-fest";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { ensure } from "~/lib/ensure";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { sumBy } from "~/lib/lodash";
import { assertNotNil } from "~/lib/utils";
import { type FetchCampaignRecommendationDistributionInput } from "~/pages/api/compensation-review/campaigns/fetch-campaign-recommendation-distribution";
import { generateEmployeesGroups } from "~/services/compensation-review/campaigns/generateEmployeesGroups";
import { type CompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";
import { getCompensationReviewBudget } from "~/services/compensation-review/shared/compensationReviewBudget";
import { getMarketPositioningDetails } from "~/services/marketPositioning";
import {
  DEFAULT_TIERS_MODE,
  getDefaultTierNames,
  getRelevantPositionings,
  getSalaryBandPositioningDetails,
} from "~/services/salaryBandsPositioning";

export const fetchCampaignRecommendationDistribution = async (
  ctx: CompensationReviewCampaignContext,
  input: FetchCampaignRecommendationDistributionInput
) => {
  ensure(() => ctx.permissions.canAccessConfiguration);

  if (!ctx.campaign.performanceReviewCycleId) {
    throw new Error("Performance review cycle is required to fetch recommendation matrix");
  }

  if (
    !ctx.user.company.defaultSalaryGridId &&
    input.adjustmentCriteria === CompensationReviewBudgetAdjustmentCriteria.SALARY_BANDS_POSITIONING
  ) {
    throw new Error("Default salary grid is required to fetch recommendation matrix");
  }

  const where = await convertSubBudgetIdToCompensationReviewEmployeeWhere(ctx, {
    budgetId: input.budgetId,
    subBudgetId: input.subBudgetId,
  });

  const computeMethod = match(input.adjustmentCriteria)
    .with(CompensationReviewBudgetAdjustmentCriteria.MARKET_POSITIONING, () => computeEmployeesByMarketPositioning)
    .with(CompensationReviewBudgetAdjustmentCriteria.SALARY_BANDS_POSITIONING, () => computeEmployeesByRangePositioning)
    .exhaustive();

  const columns = match(input.adjustmentCriteria)
    .with(CompensationReviewBudgetAdjustmentCriteria.MARKET_POSITIONING, () =>
      Object.values(EmployeeMarketPositioning).map((positioning) => ({
        value: positioning,
        label: getMarketPositioningDetails(ctx.t, positioning).title,
      }))
    )
    .with(CompensationReviewBudgetAdjustmentCriteria.SALARY_BANDS_POSITIONING, () => {
      const tierMode = ctx.salaryGrid?.tiersMode ?? DEFAULT_TIERS_MODE;

      return getRelevantPositionings(tierMode).map((positioning) => ({
        value: positioning,
        label: getSalaryBandPositioningDetails(ctx.t, {
          positioning,
          tiersMode: tierMode,
          tiersNames: ctx.salaryGrid?.tiersNames ?? getDefaultTierNames(ctx.t),
        }).title,
      }));
    })
    .exhaustive();

  const performanceRatings = await ctx.prisma.performanceReviewRating.findMany({
    where: { performanceReviewCycleId: ctx.campaign.performanceReviewCycleId },
    select: {
      id: true,
      position: true,
      name: true,
      description: true,
    },
    orderBy: { position: "asc" },
  });

  const employeesWithoutRatingCount = await ctx.prisma.compensationReviewEmployee.count({
    where: {
      performanceRatingId: null,
      campaignId: ctx.campaign.id,
      eligibilities: { some: {} },
      isPromoted: false,
      AND: where,
    },
  });

  const defaultPositioning = await value(async () => {
    if (input.adjustmentCriteria === CompensationReviewBudgetAdjustmentCriteria.MARKET_POSITIONING) {
      return EmployeeMarketPositioning.ON_TARGET;
    }

    const salaryGrid = await ctx.prisma.salaryGrid.findUniqueOrThrow({
      where: { id: ctx.user.company.defaultSalaryGridId ?? -1 },
      select: { tiersMode: true },
    });

    const positionings = getRelevantPositionings(salaryGrid.tiersMode);

    const positioningsCount = positionings.length ?? 0;
    const averageRatingIndex = Math.floor(positioningsCount / 2);

    return assertNotNil(positionings[averageRatingIndex]);
  });

  const items = await mapSeries(performanceRatings, async (rating) => {
    const { columns, employeesWithoutPositioningCount } = await computeMethod(ctx, {
      ratingId: rating.id,
      campaignId: ctx.campaign.id,
      defaultPositioning,
      marketDataPositioningMeasure:
        input.marketDataPositioningMeasure ?? CompensationReviewBudgetMarketDataPositioningMeasure.ON_TARGET_EARNINGS,
      where: where,
    });

    const employeesCount = sumBy(columns, (cell) => cell.employeesCount);
    const salaryMass = sumBy(columns, (cell) => cell.salaryMass);

    return {
      rating,
      columns,
      employeesCount,
      employeesWithoutPositioningCount,
      salaryMass,
    };
  });

  const employeesCount = sumBy(items, (row) => row.employeesCount);
  const employeesWithoutPositioningCount = sumBy(items, (row) => row.employeesWithoutPositioningCount);

  const employeesWithoutCellCount = employeesWithoutRatingCount + employeesWithoutPositioningCount;

  const defaultPerformanceRating = value(() => {
    const ratingsCount = items.length ?? 0;
    const averageRatingIndex = Math.floor(ratingsCount / 2);

    return items[averageRatingIndex]?.rating ?? null;
  });

  return {
    employeesCount,
    employeesWithoutCellCount,
    items,
    columns,
    defaultPerformanceRating,
    defaultPositioning,
    where,
  };
};

type ComputeResult = {
  columns: {
    positioning: EmployeeMarketPositioning | SalaryRangeEmployeeRangePositioning;
    employeesCount: number;
    salaryMass: number;
  }[];
  employeesWithoutPositioningCount: number;
};

type ComputeMethod = (
  ctx: AppContext,
  params: {
    ratingId: number;
    campaignId: number;
    defaultPositioning: EmployeeMarketPositioning | SalaryRangeEmployeeRangePositioning;
    marketDataPositioningMeasure: CompensationReviewBudgetMarketDataPositioningMeasure;
    where: Prisma.CompensationReviewEmployeeWhereInput;
  }
) => Promise<ComputeResult>;

const computeEmployeesByMarketPositioning: ComputeMethod = async (ctx: AppContext, params) => {
  const marketPositioningColumn = getMarketPositioningColumn(params.marketDataPositioningMeasure);

  const employeesByMarketPositioning = await ctx.prisma.employeeStats.groupBy({
    by: marketPositioningColumn,
    _count: true,
    where: {
      status: EmployeeStatus.LIVE,
      employee: {
        status: EmployeeStatus.LIVE,
        externalEmployee: {
          compensationReviewEmployees: {
            some: {
              performanceRatingId: params.ratingId,
              campaignId: params.campaignId,
              eligibilities: { some: {} },
              isPromoted: false,
              AND: params.where,
            },
          },
        },
      },
    },
  });

  const salaryMassesByMarketPositioning = await mapSeries(employeesByMarketPositioning, async (row) => {
    const salaryMass = await ctx.prisma.compensationReviewEmployee.aggregate({
      _sum: { convertedOnTargetEarnings: true },
      where: {
        performanceRatingId: params.ratingId,
        campaignId: params.campaignId,
        eligibilities: { some: {} },
        isPromoted: false,
        AND: params.where,
        externalEmployee: {
          mappedEmployee: {
            status: EmployeeStatus.LIVE,
            liveEmployeeStats: {
              [marketPositioningColumn]: row[marketPositioningColumn],
            },
          },
        },
      },
    });

    return {
      positioning: row[marketPositioningColumn],
      salaryMass: salaryMass._sum.convertedOnTargetEarnings ?? 0,
    };
  });

  const employeesWithoutPositioningCount =
    employeesByMarketPositioning.find((row) => row[marketPositioningColumn] === null)?._count ?? 0;

  const columns = Object.values(EmployeeMarketPositioning).map((positioning) => {
    const rows = employeesByMarketPositioning.filter((row) => {
      if (row[marketPositioningColumn] === null && positioning === params.defaultPositioning) {
        return true;
      }

      return row[marketPositioningColumn] === positioning;
    });

    const salaryMassRows = salaryMassesByMarketPositioning.filter((row) => {
      if (row.positioning === null && positioning === params.defaultPositioning) {
        return true;
      }

      return row.positioning === positioning;
    });

    return {
      positioning,
      employeesCount: sumBy(rows, (row) => row?._count ?? 0),
      salaryMass: sumBy(salaryMassRows, (row) => row?.salaryMass ?? 0),
    };
  });

  return {
    columns,
    employeesWithoutPositioningCount,
  };
};

export const getMarketPositioningColumn = (
  marketDataPositioningMeasure: CompensationReviewBudgetMarketDataPositioningMeasure
) => {
  return match(marketDataPositioningMeasure)
    .with(
      CompensationReviewBudgetMarketDataPositioningMeasure.ON_TARGET_EARNINGS,
      () => "onTargetEarningsMarketPositioning" as const
    )
    .with(CompensationReviewBudgetMarketDataPositioningMeasure.TOTAL_CASH, () => "totalCashMarketPositioning" as const)
    .with(
      CompensationReviewBudgetMarketDataPositioningMeasure.BASE_SALARY,
      () => "baseSalaryMarketPositioning" as const
    )
    .exhaustive();
};

const computeEmployeesByRangePositioning: ComputeMethod = async (ctx: AppContext, params) => {
  const user = getRequiredUser(ctx);

  const salaryGrid = await ctx.prisma.salaryGrid.findUnique({
    where: { id: user.company.defaultSalaryGridId ?? -1 },
    select: {
      tiersMode: true,
      tiersNames: true,
    },
  });

  const employeesByRangePositioning = await ctx.prisma.salaryRangeEmployee.groupBy({
    by: "onTargetEarningsRangePositioning",
    _count: true,
    where: {
      gridId: assertNotNil(user.company.defaultSalaryGridId),
      externalEmployee: {
        compensationReviewEmployees: {
          some: {
            performanceRatingId: params.ratingId,
            campaignId: params.campaignId,
            eligibilities: { some: {} },
            isPromoted: false,
            AND: params.where,
          },
        },
      },
    },
  });

  const salaryMassesByRangePositioning = await mapSeries(employeesByRangePositioning, async (row) => {
    const salaryMass = await ctx.prisma.compensationReviewEmployee.aggregate({
      _sum: { convertedOnTargetEarnings: true },
      where: {
        performanceRatingId: params.ratingId,
        campaignId: params.campaignId,
        eligibilities: { some: {} },
        isPromoted: false,
        AND: params.where,
        externalEmployee: {
          salaryRangeEmployees: {
            some: {
              gridId: assertNotNil(user.company.defaultSalaryGridId),
              onTargetEarningsRangePositioning: row.onTargetEarningsRangePositioning,
            },
          },
        },
      },
    });

    return {
      positioning: row.onTargetEarningsRangePositioning,
      salaryMass: salaryMass._sum.convertedOnTargetEarnings ?? 0,
    };
  });

  const employeesWithoutPositioningCount =
    employeesByRangePositioning.find((row) => row.onTargetEarningsRangePositioning === null)?._count ?? 0;

  const columns = getRelevantPositionings(salaryGrid?.tiersMode ?? DEFAULT_TIERS_MODE).map((positioning) => {
    const rows = employeesByRangePositioning.filter((row) => {
      if (row.onTargetEarningsRangePositioning === null && positioning === params.defaultPositioning) {
        return true;
      }

      return row.onTargetEarningsRangePositioning === positioning;
    });

    const salaryMassRows = salaryMassesByRangePositioning.filter((row) => {
      if (row.positioning === null && positioning === params.defaultPositioning) {
        return true;
      }

      return row.positioning === positioning;
    });

    return {
      positioning,
      employeesWithoutPositioningCount,
      employeesCount: sumBy(rows, (row) => row?._count ?? 0),
      salaryMass: sumBy(salaryMassRows, (row) => row?.salaryMass ?? 0),
    };
  });

  return {
    employeesWithoutPositioningCount,
    columns,
  };
};

export type FetchCampaignRecommendationDistributionResult = AsyncReturnType<
  typeof fetchCampaignRecommendationDistribution
>;

const convertSubBudgetIdToCompensationReviewEmployeeWhere = async (
  ctx: CompensationReviewCampaignContext,
  params: {
    budgetId: number;
    subBudgetId: number;
  }
) => {
  const budget = getCompensationReviewBudget(ctx, params.budgetId);

  const subBudget = budget.subBudgets.find((subBudget) => subBudget.id === params.subBudgetId);
  const groups = await generateEmployeesGroups(ctx, {
    groupBy: budget.distributionKey,
  });
  const group = groups.items.find((group) => group.name === subBudget?.name);

  return group?.where ?? {};
};

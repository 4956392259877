import { type Prisma } from "@prisma/client";
import { type CompensationReviewContext } from "~/services/compensation-review/compensationReviewContext";
import { CompensationReviewScopeType } from "~/services/compensation-review/compensationReviewScope";

export const updateCompensationReviewScope = (
  ctx: CompensationReviewContext,
  params: Prisma.CompensationReviewCampaignUncheckedUpdateInput | Prisma.OffCycleReviewConfigurationUncheckedUpdateInput
) => {
  if (ctx.scope.type === CompensationReviewScopeType.CAMPAIGN) {
    return ctx.prisma.compensationReviewCampaign.update({
      where: { id: ctx.scope.id },
      data: params,
    });
  }

  if (ctx.scope.type === CompensationReviewScopeType.OFF_CYCLE_REVIEW) {
    return ctx.prisma.offCycleReviewConfiguration.update({
      where: { id: ctx.scope.id },
      data: params,
    });
  }
};

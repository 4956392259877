import { type Prisma } from "@prisma/client";

export const selectExternalEmployeeUserPicture = {
  picture: {
    select: {
      id: true,
      path: true,
      width: true,
      height: true,
    },
  },
  userPermissions: {
    select: {
      user: {
        select: {
          profilePictureUrl: true,
        },
      },
    },
  },
} satisfies Prisma.ExternalEmployeeSelect;

import { Check, DragIndicator, LayersOutlined, VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { FormGroup, ListItemIcon, ListItemText, MenuItem, Stack, Tooltip } from "@mui/material";
import classNames from "classnames";
import { useState } from "react";
import { type ItemInterface, ReactSortable } from "react-sortablejs";
import { Button } from "~/components/ui/core/Button";
import { DropDown } from "~/components/ui/core/DropDown";
import { type Column } from "~/components/ui/core/Table";
import { useSession } from "~/hooks/useSession";
import { useI18n } from "~/lib/i18n/useI18n";
import { type TableColumnVisibilityKeys } from "~/pages/api/update-user-flag";
import { useUpdateColumnsPreferencesMutation } from "~/pages/api/users/update-columns-preferences";
import { ACTIONS_COLUMN_ID } from "~/services/table/getColumnsOrder";
import { getUserRoles } from "~/services/user/permissions/utils/getUserRoles";

type ColumnsDropDownMenuProps<Entry> = {
  orderList: Column<Entry>[];
  visibleColumns: Record<string, boolean>;
  setOrderList: (list: Column<Entry>[]) => void;
  setVisibleColumns: (columns: Record<string, boolean>) => void;
  visibleColumnsKey?: TableColumnVisibilityKeys;
  className?: string;
};

export const ColumnsDropDownMenu = <Entry,>({
  orderList,
  setOrderList,
  visibleColumns,
  setVisibleColumns,
  className,
  visibleColumnsKey,
}: ColumnsDropDownMenuProps<Entry>) => {
  const { t } = useI18n();
  const { user, updateFlag, isUpdatingFlag } = useSession();

  const [isVisibilityDropdownOpen, setIsVisibilityDropdownOpen] = useState<boolean>(false);

  const updateColumnsPreferences = useUpdateColumnsPreferencesMutation({
    successMessage: t("components.core.table.columns-saved"),
  });

  const changeVisibleColumns = (name: string | number) => {
    setVisibleColumns({
      ...visibleColumns,
      [name]: !visibleColumns[name],
    });
  };

  return (
    <DropDown
      open={isVisibilityDropdownOpen}
      onOpenChange={(open: boolean) => setIsVisibilityDropdownOpen(open)}
      className={className}
      label={t("components.core.table.columns")}
      ButtonProps={{ startIcon: <LayersOutlined fontSize="medium" /> }}
      MenuProps={{
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
        transformOrigin: { vertical: "top", horizontal: "right" },
      }}
      header={t("components.core.table.ordering-visibility")}
    >
      <FormGroup className="flex max-h-[280px] flex-col flex-nowrap overflow-x-scroll">
        <div className="overflow-x-scroll">
          <ReactSortable list={orderList} setList={setOrderList} handle=".handler">
            {orderList
              .filter((c) => !c.hideFromColumnsVisibilityDropdown)
              .filter((c) => c.id !== ACTIONS_COLUMN_ID)
              .map((item: ItemInterface) => (
                <Stack key={item.id} direction="row" alignItems="center">
                  <ListItemIcon
                    className={classNames("handler text-gray-500", {
                      "cursor-grab": !item.chosen,
                      "cursor-grabbing": item.chosen,
                    })}
                  >
                    <DragIndicator fontSize="small" />
                  </ListItemIcon>

                  <MenuItem className="w-full px-1" onClick={() => changeVisibleColumns(item.id)}>
                    <ListItemText className={classNames({ "opacity-40": !visibleColumns[item.id] })}>
                      {item.name}
                    </ListItemText>

                    <ListItemIcon className="ml-4">
                      {visibleColumns[item.id] ? (
                        <VisibilityOutlined fontSize="small" />
                      ) : (
                        <VisibilityOffOutlined fontSize="small" className="text-gray-300" />
                      )}
                    </ListItemIcon>
                  </MenuItem>
                </Stack>
              ))}
          </ReactSortable>
        </div>

        {!!visibleColumnsKey && (
          <Stack className="border-t py-2 px-1" spacing={2}>
            <Button
              variant="outlined"
              startIcon={<Check fontSize="small" />}
              isLoading={isUpdatingFlag}
              onClick={async () => {
                await updateFlag(
                  {
                    [visibleColumnsKey]: orderList.map(({ id }) => ({ id, visible: !!visibleColumns[id] })),
                  },
                  t("components.core.table.columns-saved")
                );
              }}
            >
              {t("components.core.table.save-columns")}
            </Button>

            {getUserRoles(user).isAdmin && (
              <Tooltip title={t("components.core.table.default.tooltip")}>
                <Button
                  variant="contained"
                  isLoading={updateColumnsPreferences.isLoading}
                  onClick={async () => {
                    await updateColumnsPreferences.mutateAsync({
                      columns: orderList.map(({ id }) => ({ id, visible: !!visibleColumns[id] })),
                      columnsKey: visibleColumnsKey,
                    });

                    await updateFlag({
                      [visibleColumnsKey]: orderList.map(({ id }) => ({ id, visible: !!visibleColumns[id] })),
                    });
                  }}
                >
                  {t("components.core.table.set-as-company-default")}
                </Button>
              </Tooltip>
            )}
          </Stack>
        )}
      </FormGroup>
    </DropDown>
  );
};

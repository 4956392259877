import { CompensationReviewBudgetDefinitionMethod, type Prisma } from "@prisma/client";
import { mapSeries } from "bluebird";
import { ensure } from "~/lib/ensure";
import { compact, isNil } from "~/lib/lodash";
import { convertCurrency } from "~/lib/money";
import { type UpdateBudgetDefinitionInput } from "~/pages/api/compensation-review/campaigns/update-budget-definition";
import { computeSalaryMass } from "~/services/compensation-review/campaigns/analytics/computeSalaryMass";
import { generateEmployeesGroups } from "~/services/compensation-review/campaigns/generateEmployeesGroups";
import { type CompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";
import { getCompensationReviewBudget } from "~/services/compensation-review/shared/compensationReviewBudget";
import { sendUpdateCampaignBudgetsJob } from "~/workers/updateCampaignBudgets";

export const updateBudgetDefinition = async (
  ctx: CompensationReviewCampaignContext,
  input: UpdateBudgetDefinitionInput
) => {
  ensure(() => ctx.permissions.canConfigure);

  await ctx.prisma.compensationReviewBudget.update({
    where: { id: input.budgetId },
    data: {
      budgetingMethod: input.budgetingMethod,
      prorationStartDate: input.prorationStartDate,

      ...(!isNil(input.amount) && { amount: input.amount }),

      budgetDefinitionMethod: input.budgetDefinitionMethod,
      distributionKey: input.distributionKey,

      adjustmentCriteria: null,
      recommendationsAllocation: null,
      skippedPerformanceRatingIds: [],
      skippedPositionings: [],
      subBudgets: {
        deleteMany: {},
      },
    },
  });

  await mapSeries(input.subBudgets, (subBudget) => {
    if (!subBudget.amount) return;

    return ctx.prisma.compensationReviewSubBudget.create({
      data: {
        campaignId: ctx.campaign.id,
        budgetId: input.budgetId,
        name: subBudget.name,
        amount: subBudget.amount,
      },
    });
  });

  if (
    input.budgetDefinitionMethod === CompensationReviewBudgetDefinitionMethod.PROPORTIONAL_ALLOCATION &&
    !!input.amount
  ) {
    await ctx.prisma.compensationReviewSubBudget.create({
      data: {
        campaignId: ctx.campaign.id,
        budgetId: input.budgetId,
        name: "Default",
        amount: input.amount,
      },
    });
  }

  await sendUpdateCampaignBudgetsJob(ctx, {
    companyId: ctx.companyId,
    campaignId: ctx.campaign.id,
    budgetId: input.budgetId,
  });
};

export const updateBudgets = async (ctx: CompensationReviewCampaignContext, budgetId: number) => {
  const budget = getCompensationReviewBudget(ctx, budgetId);

  if (budget.distributionKey) {
    const groups = await generateEmployeesGroups(ctx, {
      groupBy: budget.distributionKey,
    });

    await mapSeries(groups.items, async (group) => {
      const subBudget = budget.subBudgets.find((subBudget) => subBudget.name === group.name);

      if (!subBudget) return;

      await updateEmployeesBudget(ctx, {
        budgetId: budget.id,
        amount: subBudget.amount,
        where: group.where,
      });
    });

    const totalBudget = await ctx.prisma.compensationReviewBudgetEligibility.aggregate({
      _sum: { convertedBudgetedAmount: true },
      where: {
        campaignId: ctx.campaign.id,
        budgetId: budget.id,
      },
    });

    await ctx.prisma.compensationReviewBudget.update({
      where: { id: budget.id },
      data: {
        amount: totalBudget._sum?.convertedBudgetedAmount ?? null,
      },
    });
  } else if (budget.amount) {
    await updateEmployeesBudget(ctx, { budgetId: budget.id, amount: budget.amount });
  }
};

const updateEmployeesBudget = async (
  ctx: CompensationReviewCampaignContext,
  params: {
    budgetId: number;
    amount: number | null;
    where?: Prisma.CompensationReviewEmployeeWhereInput;
  }
) => {
  const where = {
    AND: compact([
      params.where,
      {
        campaignId: ctx.campaign.id,
        eligibilities: { some: { budgetId: params.budgetId } },
      },
    ]),
  } satisfies Prisma.CompensationReviewEmployeeWhereInput;

  const employees = await ctx.prisma.compensationReviewEmployee.findMany({
    where,
    select: {
      id: true,
      convertedOnTargetEarnings: true,
      currency: true,
      eligibilities: {
        where: { budgetId: params.budgetId },
      },
    },
  });

  const salaryMass = await computeSalaryMass(ctx, { where });

  await mapSeries(employees, async (employee) => {
    const eligibility = employee.eligibilities.find((eligibility) => eligibility.budgetId === params.budgetId);
    if (!eligibility) return;

    const salaryMassRatio = employee.convertedOnTargetEarnings / salaryMass.total;
    const convertedBudgetedAmount = params.amount ? salaryMassRatio * params.amount : null;
    const budgetedAmount = convertedBudgetedAmount
      ? convertCurrency(convertedBudgetedAmount, ctx.campaign.currency, employee.currency)
      : null;

    await ctx.prisma.compensationReviewBudgetEligibility.update({
      where: { id: eligibility.id },
      data: {
        budgetedAmount,
        convertedBudgetedAmount,
      },
    });
  });
};

import { type Prisma } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { assertNotNil } from "~/lib/utils";
import { selectExternalEmployeeUserPicture } from "~/services/external-employee/selectExternalEmployeeUserForPicture";
import { whereSalaryRangeIs } from "~/services/salary-bands/access/helpers";
import { externalBenchmarkSelect } from "~/services/salary-bands/external-benchmark/fetchExternalBenchmark";
import { enrichSalaryRanges } from "./enrichSalaryRanges";

export const salaryRangeExternalEmployeeSelect = {
  id: true,
  firstName: true,
  lastName: true,
  externalId: true,
  employeeNumber: true,
  status: true,
  job: {
    select: {
      id: true,
      name: true,
    },
  },
  location: {
    select: {
      id: true,
      name: true,
      country: {
        select: {
          alpha2: true,
        },
      },
    },
  },
  level: {
    select: {
      id: true,
      name: true,
    },
  },
  currency: true,
  remunerationItems: {
    select: {
      amount: true,
      nature: {
        select: {
          mappedType: true,
        },
      },
    },
  },
  performanceReviewRating: {
    select: {
      name: true,
    },
  },
  additionalFieldValues: {
    select: {
      stringValue: true,
      numberValue: true,
      dateValue: true,
      percentageValue: true,
      additionalFieldId: true,
    },
  },
  mappedEmployee: {
    select: {
      id: true,
      baseSalary: true,
      onTargetBonus: true,
      fixedBonus: true,
      currency: true,
      level: true,
      location: {
        select: {
          id: true,
          name: true,
          fallbackLocationId: true,
          country: {
            select: {
              id: true,
              name: true,
            },
          },
        },
      },
      job: {
        select: {
          id: true,
          name: true,
          familyId: true,
        },
      },
      liveEmployeeStats: {
        select: {
          onTargetEarnings: true,
        },
      },
    },
  },
  ...selectExternalEmployeeUserPicture,
} satisfies Prisma.ExternalEmployeeSelect;

export type SalaryRangeExternalEmployeeRow = Prisma.ExternalEmployeeGetPayload<{
  select: typeof salaryRangeExternalEmployeeSelect;
}>;

export const salaryRangeSelect = {
  id: true,
  bandId: true,
  midpoint: true,
  min: true,
  max: true,
  inferredPercentileRank: true,
  targetPercentileRank: true,
  baseSalaryP10: true,
  baseSalaryP25: true,
  baseSalaryP50: true,
  baseSalaryP75: true,
  baseSalaryP90: true,
  baseSalaryDataQuality: true,
  onTargetEarningsP10: true,
  onTargetEarningsP25: true,
  onTargetEarningsP50: true,
  onTargetEarningsP75: true,
  onTargetEarningsP90: true,
  onTargetEarningsDataQuality: true,
  createdWithoutMarketData: true,
  level: {
    select: {
      id: true,
      name: true,
      description: true,
      track: true,
      mappedLevels: {
        select: {
          externalLevelId: true,
          level: true,
        },
      },
      benchmarkedLevels: {
        select: {
          level: true,
        },
      },
      externalBenchmarkLevels: {
        select: {
          id: true,
        },
      },
    },
  },
  employees: {
    select: {
      id: true,
      overridden: true,
      baseSalaryCompaRatio: true,
      baseSalaryRangePenetration: true,
      onTargetEarningsCompaRatio: true,
      onTargetEarningsRangePenetration: true,
      externalEmployee: { select: salaryRangeExternalEmployeeSelect },
    },
  },
  distinctCompaniesCount: true,
  employeesCount: true,
} satisfies Prisma.SalaryRangeSelect;

export type SalaryRangeRow = AsyncReturnType<typeof enrichSalaryRanges>[number];

export const fetchSalaryRange = async (
  ctx: AppContext,
  params: {
    salaryGridId: number;
    salaryBandId: number;
    salaryRangeId: number;
  }
) => {
  const salaryRange = await ctx.prisma.salaryRange.findFirstOrThrow({
    where: whereSalaryRangeIs(params),
    select: {
      ...salaryRangeSelect,
      band: {
        select: {
          id: true,
          currency: true,
          externalBenchmarkGroups: {
            select: {
              id: true,
            },
          },
          isDraft: true,
          job: {
            select: {
              id: true,
              name: true,
            },
          },
          location: {
            select: {
              id: true,
              name: true,
            },
          },
          grid: {
            select: {
              id: true,
              status: true,
              externalBenchmarks: {
                select: externalBenchmarkSelect,
              },
            },
          },
        },
      },
    },
  });

  const [salaryRangeWithExternalBenchmarks] = await enrichSalaryRanges(ctx, {
    salaryGridId: salaryRange.band.grid.id,
    salaryRanges: [salaryRange],
    externalBenchmarks: salaryRange.band.grid.externalBenchmarks,
    externalBenchmarkGroupIds: salaryRange.band.externalBenchmarkGroups.map(({ id }) => id),
    externalBenchmarkLevelIds: salaryRange.level.externalBenchmarkLevels.map(({ id }) => id),
    currency: salaryRange.band.currency,
    getSalaryBand: () => salaryRange.band,
  });

  return {
    ...assertNotNil(salaryRangeWithExternalBenchmarks),
    currency: salaryRange.band.currency,
  };
};

export type FetchSalaryRangeResult = AsyncReturnType<typeof fetchSalaryRange>;

import { number, object } from "yup";
import { type AppContext } from "~/lib/context";
import { makeSingletonKey } from "~/lib/makeSingletonKey";
import { BaseJobDataSchema } from "~/lib/queue/baseJobDataSchema";
import { QueueJobName } from "~/lib/queue/queueJobName";
import { sendJob } from "~/lib/queue/sendJob";
import { type YupOutputType } from "~/lib/utils";
import { synchronizeSalaryRangeEmployees } from "~/services/salary-bands/configuration/synchroniseSalaryRangeEmployees";

const SyncSalaryRangeEmployeesJobDataSchema = BaseJobDataSchema.concat(object({ salaryGridId: number().required() }));

export type SyncSalaryRangeEmployeesJobData = YupOutputType<typeof SyncSalaryRangeEmployeesJobDataSchema>;

export const syncSalaryRangeEmployeesWorkerService = async (ctx: AppContext, data: SyncSalaryRangeEmployeesJobData) => {
  const { salaryGridId } = SyncSalaryRangeEmployeesJobDataSchema.validateSync(data, { abortEarly: false });

  await synchronizeSalaryRangeEmployees(ctx, { salaryGridId });
};

export const sendSyncSalaryRangeEmployeesJob = async (ctx: AppContext, data: SyncSalaryRangeEmployeesJobData) => {
  await sendJob(ctx, {
    jobName: QueueJobName.SYNC_SALARY_RANGE_EMPLOYEES,
    data,
    options: {
      singletonKey: makeSingletonKey({ companyId: data.companyId, salaryGridId: data.salaryGridId }, { unique: false }),
    },
  });
};

import { OffCycleReviewRequestStatus } from "@prisma/client";
import { mapSeries } from "bluebird";
import { config } from "~/config";
import { sendOffCycleReviewCompletedEmail } from "~/lib/external/sendinblue/templates";
import { chain } from "~/lib/lodash";
import { includeReviewers } from "~/services/compensation-review/campaigns/admin/employeeReviewers";
import { type CompensationReviewContext } from "~/services/compensation-review/compensationReviewContext";
import { fetchCompensationReviewAdmins } from "~/services/compensation-review/shared/fetchCompensationReviewAdmins";

type CompleteOffCycleRequestRequestInput = {
  offCycleReviewConfigurationId: number;
  employeeId: number;
};

export const completeOffCycleRequestRequest = async (
  ctx: CompensationReviewContext,
  input: CompleteOffCycleRequestRequestInput
) => {
  const ongoingRequest = await ctx.prisma.offCycleReviewRequest.findFirst({
    where: {
      configurationId: input.offCycleReviewConfigurationId,
      employeeId: input.employeeId,
      status: OffCycleReviewRequestStatus.ONGOING,
    },
  });

  if (!ongoingRequest) return;

  await ctx.prisma.offCycleReviewRequest.update({
    where: { id: ongoingRequest.id },
    data: {
      status: OffCycleReviewRequestStatus.COMPLETED,
      completedAt: new Date(),
    },
  });

  await notifyStakeholders(ctx, { offCycleReviewRequestId: ongoingRequest.id });
};

const notifyStakeholders = async (
  ctx: CompensationReviewContext,
  params: {
    offCycleReviewRequestId: number;
  }
) => {
  const request = await ctx.prisma.offCycleReviewRequest.findFirstOrThrow({
    where: {
      id: params.offCycleReviewRequestId,
    },
    include: {
      user: true,
      externalEmployee: true,
    },
  });

  if (!request.employeeId) return;

  const admins = await fetchCompensationReviewAdmins(ctx, { scope: ctx.scope });

  const employee = await ctx.prisma.compensationReviewEmployee.findFirstOrThrow({
    where: { id: request.employeeId },
    include: includeReviewers({
      externalEmployee: { include: { userPermissions: { include: { user: true } } } },
    }),
  });

  const stakeholders = chain([
    request.user,
    ...admins,
    // I tried doing a nice version, but TS + Prisma didn't like it
    employee.reviewer1?.externalEmployee.userPermissions?.user,
    employee.reviewer2?.externalEmployee.userPermissions?.user,
    employee.reviewer3?.externalEmployee.userPermissions?.user,
    employee.reviewer4?.externalEmployee.userPermissions?.user,
    employee.reviewer5?.externalEmployee.userPermissions?.user,
    employee.reviewer6?.externalEmployee.userPermissions?.user,
    employee.reviewer7?.externalEmployee.userPermissions?.user,
    employee.reviewer8?.externalEmployee.userPermissions?.user,
    employee.reviewer9?.externalEmployee.userPermissions?.user,
    employee.reviewer10?.externalEmployee.userPermissions?.user,
  ])
    .compact()
    .uniqBy((user) => user.id)
    .value();

  await mapSeries(stakeholders, async (stakeholder, index) => {
    if (!stakeholder) return;
    if (!config.app.isJest && !config.app.isProduction && !!index) return;

    await sendOffCycleReviewCompletedEmail(ctx, {
      user: stakeholder,
      request: request,
    });
  });
};

import { ExternalRemunerationType } from "@prisma/client";
import {
  computeEmployeeCompensation,
  type ComputeEmployeeCompensationInput,
  type ComputeEmployeeCompensationOptions,
} from "~/services/employee";
import { type RemunerationItemWithNature } from "~/services/employee/employeeMapping";
import { sumRemunerationItems } from "~/services/employee/sumRemunerationItems";

type ExternalEmployeeCompensationInput = {
  currency: {
    id: number;
    name: string;
    code: string;
    symbol: string;
    euroExchangeRate: number;
    translations: unknown;
  };
  remunerationItems: RemunerationItemWithNature[];
};

export type ExternalEmployeeCompensation = ExternalEmployeeCompensationInput & {
  mappedEmployee: ComputeEmployeeCompensationInput | null;
};

export const computeExternalEmployeeCompensation = (
  externalEmployee: ExternalEmployeeCompensation,
  options: ComputeEmployeeCompensationOptions
) => {
  const targetCurrency =
    options.targetCurrency ?? externalEmployee.mappedEmployee?.currency ?? externalEmployee.currency;

  const compensation = computeEmployeeCompensation(
    externalEmployee.mappedEmployee ?? getExternalEmployeeCompensation(externalEmployee),
    {
      targetCurrency,
      ...options,
    }
  );

  return { compensation, currency: targetCurrency };
};

const getExternalEmployeeCompensation = (externalEmployee: ExternalEmployeeCompensationInput) => {
  const { remunerationItems, currency } = externalEmployee;

  return {
    currency,
    baseSalary: sumRemunerationItems(remunerationItems, ExternalRemunerationType.FIXED_SALARY),
    fixedBonus: sumRemunerationItems(remunerationItems, ExternalRemunerationType.FIXED_BONUS),
    onTargetBonus: sumRemunerationItems(remunerationItems, ExternalRemunerationType.VARIABLE_BONUS),
    profitSharing: sumRemunerationItems(remunerationItems, ExternalRemunerationType.PROFIT_SHARING),
    equity: sumRemunerationItems(remunerationItems, ExternalRemunerationType.EQUITY),
    paidBonus: sumRemunerationItems(remunerationItems, ExternalRemunerationType.PAID_BONUS),
  };
};

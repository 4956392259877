import { SpreadsheetImportRowStatus, SpreadsheetImportStatus } from "@prisma/client";
import { number, object } from "yup";
import { type AppContext } from "~/lib/context";
import { notifyImportSpreadsheetNotSuccessful } from "~/lib/external/slack/notifications";
import { logInfo } from "~/lib/logger";
import { makeSingletonKey } from "~/lib/makeSingletonKey";
import { BaseJobDataSchema } from "~/lib/queue/baseJobDataSchema";
import { QueueJobName } from "~/lib/queue/queueJobName";
import { sendJob } from "~/lib/queue/sendJob";
import { type YupOutputType } from "~/lib/utils";
import { getImportRowsCount } from "~/services/external-employee/import/getImportRowsCount";
import { updateImportStatus } from "~/services/external-employee/import/updateImportStatus";
import { synchroniseCompanySalaryRangeEmployees } from "~/services/salary-bands/configuration/synchroniseCompanySalaryRangeEmployees";
import { sendSuggestJobsMappingJob } from "~/workers/suggestJobsMapping";

const PostImportSpreadsheetJobDataSchema = BaseJobDataSchema.concat(
  object({
    companyId: number().required(),
    importId: number().required(),
  })
);

export type PostImportSpreadsheetJobData = YupOutputType<typeof PostImportSpreadsheetJobDataSchema>;

export const postImportSpreadsheetWorkerService = async (ctx: AppContext, data: PostImportSpreadsheetJobData) => {
  const { companyId, importId } = data;

  await sendSuggestJobsMappingJob(ctx, { companyId });

  await synchroniseCompanySalaryRangeEmployees(ctx, { companyId });

  await updateImportStatus(ctx, {
    importId,
    status: SpreadsheetImportStatus.IMPORTED,
  });

  const total = await getImportRowsCount(ctx, {
    importId,
  });

  const imported = await getImportRowsCount(ctx, {
    importId,
    statuses: [SpreadsheetImportRowStatus.IMPORTED],
  });

  const errors = await getImportRowsCount(ctx, {
    importId,
    statuses: [SpreadsheetImportRowStatus.ERROR],
  });

  const warnings = await getImportRowsCount(ctx, {
    importId,
    statuses: [SpreadsheetImportRowStatus.WARNING],
  });

  logInfo(ctx, `[spreadsheet-import] Import completed`, {
    companyId,
    importId,
  });

  if (total !== imported) {
    logInfo(ctx, `[spreadsheet-import] Import failed`, {
      companyId,
      importId,
      totalRowsCount: total,
      successRowsCount: imported,
      warningRowsCount: warnings,
      errorRowsCount: errors,
    });

    await notifyImportSpreadsheetNotSuccessful(ctx, {
      importId,
      totalRowsCount: total,
      successRowsCount: imported,
      warningRowsCount: warnings,
      errorRowsCount: errors,
    });
  }
};

export const sendPostImportSpreadsheetJob = async (ctx: AppContext, data: PostImportSpreadsheetJobData) => {
  await sendJob(ctx, {
    jobName: QueueJobName.POST_IMPORT_SPREADSHEET,
    data,
    options: {
      singletonKey: makeSingletonKey({ companyId: data.companyId, importId: data.importId }, { unique: false }),
    },
  });
};

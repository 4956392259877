import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";

export const getAdditionalMonthRulesForSync = async (ctx: AppContext) =>
  ctx.prisma.additionalMonthRule.findMany({
    where: {
      companyId: getRequiredUser(ctx).companyId,
    },
    select: {
      month: true,
      country: {
        select: {
          id: true,
        },
      },
    },
  });

type AdditionalMonthRulesForSync = AsyncReturnType<typeof getAdditionalMonthRulesForSync>;

export const getExternalLocationsForSync = async (ctx: AppContext, params: { countryIds: number[] }) =>
  ctx.prisma.externalLocation.findMany({
    where: {
      companyId: getRequiredUser(ctx).companyId,
      OR: [
        {
          countryId: {
            in: params.countryIds,
          },
        },
        {
          mappedLocation: {
            countryId: {
              in: params.countryIds,
            },
          },
        },
      ],
    },
    select: {
      externalId: true,
      countryId: true,
      mappedLocation: {
        select: {
          countryId: true,
        },
      },
    },
  });

type ExternalLocationsForSync = AsyncReturnType<typeof getExternalLocationsForSync>;

export const getNumberOfMonth = (params: {
  additionalMonthRules: AdditionalMonthRulesForSync;
  externalLocations: ExternalLocationsForSync;
  externalId?: string;
}) => {
  if (!params.externalId) {
    return 12;
  }

  const location = params.externalLocations.find(({ externalId }) => externalId === params.externalId);

  if (!location) {
    return 12;
  }

  const additionalMonthRule = params.additionalMonthRules.find(
    ({ country }) => country.id === location.countryId || country.id === location.mappedLocation?.countryId
  );

  if (!additionalMonthRule) {
    return 12;
  }

  return additionalMonthRule.month;
};

export const getNumberOfMonthsFn = async (ctx: AppContext) => {
  const additionalMonthRules = await getAdditionalMonthRulesForSync(ctx);
  const externalLocations = await getExternalLocationsForSync(ctx, {
    countryIds: additionalMonthRules.map(({ country }) => country.id),
  });

  return (externalId: string | undefined) => getNumberOfMonth({ additionalMonthRules, externalLocations, externalId });
};

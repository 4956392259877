import { mapSeries } from "bluebird";
import { ensure } from "~/lib/ensure";
import {
  buildFetchCompensationReviewEmployeesInput,
  fetchCompensationReviewEmployees,
} from "~/services/compensation-review/campaigns/admin/fetchCompensationReviewEmployees";
import { type CompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";
import { prismaCompensationReviewScope } from "~/services/compensation-review/compensationReviewScope";
import { initialiseCompensationReviewRecommendations } from "./initialiseCompensationReviewRecommendations";

export const resetCampaignRecommendations = async (ctx: CompensationReviewCampaignContext) => {
  ensure(() => ctx.permissions.canConfigure);

  await ctx.prisma.compensationReviewAdjustment.deleteMany({
    where: {
      ...prismaCompensationReviewScope(ctx.scope),
      recommendationId: { not: null },
    },
  });

  await ctx.prisma.compensationReviewAdjustment.updateMany({
    where: {
      ...prismaCompensationReviewScope(ctx.scope),
      employeeId: { not: null },
    },
    data: {
      submittedAmount: null,
      convertedSubmittedAmount: null,
      proratedSubmittedAmount: null,
    },
  });

  await ctx.prisma.compensationReviewRecommendation.deleteMany({
    where: prismaCompensationReviewScope(ctx.scope),
  });

  await ctx.prisma.compensationReviewEmployee.updateMany({
    where: prismaCompensationReviewScope(ctx.scope),
    data: {
      isIncreaseDifferentFromRecommendation: false,
      onTargetEarningsIncreasePercentage: null,
      newCompaRatio: null,
      newRangePenetration: null,
      convertedNewOnTargetEarnings: null,
    },
  });

  const employees = await fetchCompensationReviewEmployees(ctx, {
    scope: ctx.scope,
    ...buildFetchCompensationReviewEmployeesInput({
      onlyEligible: true,
    }),
  });

  await mapSeries(employees.items, async (employee) => initialiseCompensationReviewRecommendations(ctx, { employee }));
};

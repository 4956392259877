import { type CompensationReviewAdjustment } from "@prisma/client";
import { type CompensationReviewData } from "~/services/compensation-review/compensationReviewContext";
import { computeAmountForCompensationItem } from "~/services/compensation-review/shared/compensationItems";
import { type ComputedBudgetRules } from "~/services/compensation-review/shared/rules/computeIncreaseRules";

export const processBudgetRules = (
  ctx: CompensationReviewData,
  params: {
    rules: ComputedBudgetRules[];
    adjustments: CompensationReviewAdjustment[];
  }
) => {
  const rulesResults = params.rules.map((rule) => {
    const onTargetEarningsIncrease = computeAmountForCompensationItem(params.adjustments, "onTargetEarnings", [
      "submittedAmount",
    ]);

    return {
      rule,
      isWithinHardLimits: rule.isWithinHardLimits(onTargetEarningsIncrease),
      isWithinSoftLimits: rule.isWithinSoftLimits(onTargetEarningsIncrease),
    };
  });

  return {
    rulesResults,
    isWithinHardLimits: rulesResults.every((result) => result.isWithinHardLimits),
    isWithinSoftLimits: rulesResults.every((result) => result.isWithinSoftLimits),
  };
};

export type ProcessedBudgetRules = ReturnType<typeof processBudgetRules>;

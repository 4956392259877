import { type IntegrationSettings, type IntegrationSource } from "@prisma/client";
import { randomUUID } from "crypto";
import { createReadStream, createWriteStream, statSync } from "fs";
import sizeOf from "image-size";
import { type Response } from "node-fetch";
import { tmpdir } from "os";
import { join } from "path";
import uniqid from "uniqid";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { getPublicAssetFilePath } from "~/lib/files/getPublicAssetFilePath";
import { uploadFile } from "~/lib/files/uploadFile";
import { logInfo, logWarn } from "~/lib/logger";

export const getUploadedEmployeeProfilePicture = async (
  ctx: AppContext,
  options: {
    apiEmployeeId: string;
    source: IntegrationSource;
    fetch: () => Promise<Response>;
    integrationSettings: IntegrationSettings;
  }
) => {
  const picturePath = await getEmployeeProfilePicture(ctx, {
    apiEmployeeId: options.apiEmployeeId,
    source: options.source,
    fetch: options.fetch,
    integrationSettings: options.integrationSettings,
  });

  if (!picturePath) {
    return undefined;
  }

  return handleEmployeeProfilePicture(ctx, {
    picturePath,
    companyId: options.integrationSettings.companyId,
    apiEmployeeId: options.apiEmployeeId,
    source: options.source,
    isIntegrationAnonymous: options.integrationSettings.anonymous,
  });
};

const getEmployeeProfilePicture = async (
  ctx: AppContext,
  options: {
    apiEmployeeId: string;
    source: IntegrationSource;
    fetch: () => Promise<Response>;
    integrationSettings: IntegrationSettings;
  }
): Promise<string | undefined> => {
  if (options.integrationSettings.anonymous || config.dev.ignoreIntegrationProfilePics) {
    return undefined;
  }

  const path = join(tmpdir(), `${options.source}-image-${options.apiEmployeeId}-${randomUUID()}.png`);

  try {
    const res = await options.fetch();

    return new Promise((resolve, reject) => {
      const fileStream = createWriteStream(path);

      res.body.pipe(fileStream);

      res.body.on("error", (err) => {
        reject(err);
      });

      fileStream.on("finish", function () {
        resolve(path);
      });
    });
  } catch (error) {
    logInfo(ctx, `[${options.source}] There was an error when fetching an employee's profile picture.`, {
      apiEmployeeId: options.apiEmployeeId,
      source: options.source,
      error,
    });
  }
};

const uploadProfilePicture = async (ctx: AppContext, { picturePath }: { picturePath: string }) => {
  try {
    const fileStats = statSync(picturePath);
    const { width, height } = sizeOf(picturePath) as { width: number; height: number };
    const path = `${config.files.prefixes.employeePictures}/${uniqid()}.png`;

    await uploadFile(ctx, {
      Bucket: config.files.bucket,
      Key: getPublicAssetFilePath(path),
      Body: createReadStream(picturePath),
      ContentType: "image/png",
      ContentLength: fileStats.size,
    });

    return { path, width, height, size: fileStats.size };
  } catch (error) {
    logWarn(ctx, "[sync] Error while copying employee profile picture", { error });

    return undefined;
  }
};

const handleEmployeeProfilePicture = async (
  ctx: AppContext,
  params: {
    picturePath: string | undefined;
    companyId: number;
    apiEmployeeId: string;
    source: IntegrationSource;
    isIntegrationAnonymous: boolean | undefined;
  }
) => {
  if (!params.picturePath || params.isIntegrationAnonymous) {
    logInfo(ctx, "[sync] No profile picture to handle for employee", {
      companyId: params.companyId,
      apiEmployeeId: params.apiEmployeeId,
      source: params.source,
    });
    return undefined;
  }

  return uploadProfilePicture(ctx, { picturePath: params.picturePath });
};

import {
  type CompensationReviewAdjustment,
  CompensationReviewCompensationItem,
  type CompensationReviewEmployee,
} from "@prisma/client";
import { roundTo } from "~/lib/math";
import { convertCurrency } from "~/lib/money";
import { type CompensationReviewCampaignBudget } from "~/services/compensation-review/campaigns/fetchCampaign";
import { type CompensationReviewContext } from "~/services/compensation-review/compensationReviewContext";
import { type TransformCompensationReviewCurrencyResult } from "~/services/compensation-review/shared/transformCompensationReviewCurrency";

export const updateSalaryAdjustmentRecommendations = async (
  ctx: CompensationReviewContext,
  params: {
    budget: CompensationReviewCampaignBudget;
    employee: Pick<CompensationReviewEmployee, "baseSalary" | "variablePay" | "effectiveDate"> & {
      currency: TransformCompensationReviewCurrencyResult;
      adjustments: CompensationReviewAdjustment[];
    };
    onTargetEarningsRecommendation: number | null;
  }
) => {
  const { employee } = params;

  const onTargetEarningsRecommendation =
    params.onTargetEarningsRecommendation !== null ? roundTo(params.onTargetEarningsRecommendation, 100) : null;

  const variablePayRatio = params.budget.compensationItems.includes(CompensationReviewCompensationItem.VARIABLE_PAY)
    ? employee.variablePay / (employee.baseSalary + employee.variablePay)
    : 0;

  const variablePayRecommendation =
    onTargetEarningsRecommendation !== null ? roundTo(onTargetEarningsRecommendation * variablePayRatio, 100) : null;

  const baseSalaryRecommendation =
    onTargetEarningsRecommendation !== null && variablePayRecommendation !== null
      ? roundTo(onTargetEarningsRecommendation - variablePayRecommendation, 100)
      : null;

  const convertAmount = (amount: number | null) =>
    amount ? convertCurrency(amount, employee.currency, ctx.parameters.currency) : null;

  const baseSalaryAdjustment = employee.adjustments.find(
    (adjustment) =>
      adjustment.compensationItem === CompensationReviewCompensationItem.BASE_SALARY &&
      adjustment.budgetId === params.budget.id
  );
  if (baseSalaryAdjustment) {
    await ctx.prisma.compensationReviewAdjustment.update({
      where: { id: baseSalaryAdjustment.id },
      data: {
        recommendedAmount: baseSalaryRecommendation,
        convertedRecommendedAmount: convertAmount(baseSalaryRecommendation),
      },
    });
  }

  const variablePayAdjustment = employee.adjustments.find(
    (adjustment) =>
      adjustment.compensationItem === CompensationReviewCompensationItem.VARIABLE_PAY &&
      adjustment.budgetId === params.budget.id
  );
  if (variablePayAdjustment) {
    await ctx.prisma.compensationReviewAdjustment.update({
      where: { id: variablePayAdjustment.id },
      data: {
        recommendedAmount: variablePayRecommendation,
        convertedRecommendedAmount: convertAmount(variablePayRecommendation),
      },
    });
  }
};

import { type CompensationReviewAdjustment, CompensationReviewCompensationItem } from "@prisma/client";

import { type TFunction } from "i18next";

import { match } from "ts-pattern";
import { chain, isNil, sum } from "~/lib/lodash";

export const formatCompensationItem = (t: TFunction, item: CompensationReviewCompensationItem) =>
  match(item)
    .with(CompensationReviewCompensationItem.BASE_SALARY, () => ({
      label: t("enum.compensation-review-compensation-item.base-salary"),
      description: t("enum.compensation-review-compensation-item.base-salary-details"),
    }))
    .with(CompensationReviewCompensationItem.VARIABLE_PAY, () => ({
      label: t("enum.compensation-review-compensation-item.variable-pay"),
      description: t("enum.compensation-review-compensation-item.variable-pay-details"),
    }))
    .with(CompensationReviewCompensationItem.ANNUAL_PERFORMANCE_BONUS, () => ({
      label: t("enum.compensation-review-compensation-item.annual-performance-bonus"),
      description: t("enum.compensation-review-compensation-item.annual-performance-bonus-details"),
    }))
    .with(CompensationReviewCompensationItem.EXCEPTIONAL_BONUS, () => ({
      label: t("enum.compensation-review-compensation-item.exceptional-bonus"),
      description: t("enum.compensation-review-compensation-item.exceptional-bonus-details"),
    }))
    .exhaustive();

export const isSalaryCompensationItem = (item: CompensationReviewCompensationItem) =>
  item === CompensationReviewCompensationItem.BASE_SALARY || item === CompensationReviewCompensationItem.VARIABLE_PAY;

export type AdjustmentPayload = Pick<
  CompensationReviewAdjustment,
  "compensationItem" | "submittedAmount" | "recommendedAmount" | "proratedSubmittedAmount"
>;

export const getAdjustmentAmountsForCompensationItem = (
  adjustments: AdjustmentPayload[],
  compensationItem: CompensationReviewCompensationItem | "onTargetEarnings" | "totalCash",
  fields: ("submittedAmount" | "recommendedAmount" | "proratedSubmittedAmount")[]
) => {
  return chain(adjustments)
    .filter((adjustment) => {
      if (compensationItem === "onTargetEarnings" || compensationItem === "totalCash") {
        return isSalaryCompensationItem(adjustment.compensationItem);
      }

      return adjustment.compensationItem === compensationItem;
    })
    .map((adjustment) => {
      const field = fields.find((field) => !isNil(adjustment[field]));
      if (!field) return null;

      return adjustment[field];
    })
    .compact()
    .value();
};

export const computeAmountForCompensationItem = (
  adjustments: AdjustmentPayload[],
  compensationItem: CompensationReviewCompensationItem | "onTargetEarnings" | "totalCash",
  fields: ("submittedAmount" | "recommendedAmount" | "proratedSubmittedAmount")[]
) => {
  const adjustmentsForCompensationItem = getAdjustmentAmountsForCompensationItem(adjustments, compensationItem, fields);

  return sum(adjustmentsForCompensationItem);
};

export const computeIncreaseAmounts = (params: {
  adjustments: AdjustmentPayload[];
  fields: ("submittedAmount" | "recommendedAmount" | "proratedSubmittedAmount")[];
}) => {
  const baseSalary = computeAmountForCompensationItem(
    params.adjustments,
    CompensationReviewCompensationItem.BASE_SALARY,
    params.fields
  );

  const variablePay = computeAmountForCompensationItem(
    params.adjustments,
    CompensationReviewCompensationItem.VARIABLE_PAY,
    params.fields
  );

  const annualPerformanceBonus = computeAmountForCompensationItem(
    params.adjustments,
    CompensationReviewCompensationItem.ANNUAL_PERFORMANCE_BONUS,
    params.fields
  );

  const exceptionalBonus = computeAmountForCompensationItem(
    params.adjustments,
    CompensationReviewCompensationItem.EXCEPTIONAL_BONUS,
    params.fields
  );

  const hasAnyIncrease = baseSalary > 0 || variablePay > 0 || annualPerformanceBonus > 0 || exceptionalBonus > 0;

  return {
    baseSalary,
    variablePay,
    onTargetEarnings: baseSalary + variablePay,
    annualPerformanceBonus,
    exceptionalBonus,
    hasAnyIncrease,
  };
};

export type IncreaseAmounts = Omit<ReturnType<typeof computeIncreaseAmounts>, "hasAnyIncrease">;

export const computeFteAmount = (amount: number, fteCoefficient: number) => {
  return amount / fteCoefficient;
};

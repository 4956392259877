/*
 * Borrowed from https://github.com/dmytropaduchak/simple-bilinear-interpolation/tree/master and adapted to handle extrapolation
 */

import linearInterpolator from "linear-interpolator";

/** Bilinear Interpolation Point Interface */
export interface BilinearInterpolationPoint {
  x: number;
  y: number;
  z: number;
}

/** Bilinear Interpolation execute function Type */
export type BilinearInterpolationFunction = (params: Pick<BilinearInterpolationPoint, "x" | "y">) => number;
const MATRIX_LENGTH = 4;

/**
 * Implementation of bilinear interpolation
 * @param {array} points interpolation matrix data
 * @return {function} interpolation execute function
 */
export function bilinearInterpolation(points: BilinearInterpolationPoint[]): BilinearInterpolationFunction {
  if (points.length <= MATRIX_LENGTH - 1) {
    throw new Error("Can't calculate bilinear interpolation, please provide more points");
  }

  return (params: Pick<BilinearInterpolationPoint, "x" | "y">): number => {
    const x1 = points.sort((a, b) => b.x - a.x).find((i) => i.x <= params.x);
    const x2 = points.sort((a, b) => a.x - b.x).find((i) => i.x >= params.x);

    if (!x1 || !x2) {
      throw new Error(`Can't calculate bilinear interpolation for x: '${params.x}'`);
    }

    const y1 = points.sort((a, b) => b.y - a.y).find((i) => i.y <= params.y) as BilinearInterpolationPoint;
    const y2 = points.sort((a, b) => a.y - b.y).find((i) => i.y >= params.y) as BilinearInterpolationPoint;

    if (x1.x === x2.x) {
      const data = points.filter((i) => i.x === x1.x).map(({ y: x, z: y }) => [x, y] as [number, number]);
      return linearInterpolator(data)(params.y);
    }

    if (y1?.y === y2?.y) {
      const data = points.filter((i) => i.y === y1?.y).map(({ x, z: y }) => [x, y] as [number, number]);
      return linearInterpolator(data)(params.x);
    }

    const z11 = points.find((i) => i.x === x1.x && i.y === y1?.y);
    const z12 = points.find((i) => i.x === x2.x && i.y === y1?.y);
    const z21 = points.find((i) => i.x === x1.x && i.y === y2?.y);
    const z22 = points.find((i) => i.x === x2.x && i.y === y2?.y);

    if (!z11 || !z12 || !z21 || !z22) {
      throw new Error(`Can't calculate bilinear interpolation for x: '${params.x}' and y: '${params.y}'`);
    }

    const p1 = (((x2.x - params.x) * (y2.y - params.y)) / ((x2.x - x1.x) * (y2.y - y1.y))) * z11.z;
    const p2 = (((params.x - x1.x) * (y2.y - params.y)) / ((x2.x - x1.x) * (y2.y - y1.y))) * z12.z;
    const p3 = (((x2.x - params.x) * (params.y - y1.y)) / ((x2.x - x1.x) * (y2.y - y1.y))) * z21.z;
    const p4 = (((params.x - x1.x) * (params.y - y1.y)) / ((x2.x - x1.x) * (y2.y - y1.y))) * z22.z;

    return p1 + p2 + p3 + p4;
  };
}

import { type AppContext } from "~/lib/context";
import { logError, logInfo } from "~/lib/logger";
import { makeSingletonKey } from "~/lib/makeSingletonKey";
import { BaseJobDataSchema } from "~/lib/queue/baseJobDataSchema";
import { QueueJobName } from "~/lib/queue/queueJobName";
import { sendJob } from "~/lib/queue/sendJob";
import { type YupOutputType } from "~/lib/utils";
import { updateCompanyStats } from "~/services/updateCompanyStats";

const UpdateCompanyStatsJobDataSchema = BaseJobDataSchema;

export type UpdateCompanyStatsJobData = YupOutputType<typeof UpdateCompanyStatsJobDataSchema>;

export const updateCompanyStatsWorkerService = async (ctx: AppContext, data: UpdateCompanyStatsJobData) => {
  const { companyId } = UpdateCompanyStatsJobDataSchema.validateSync(data, { abortEarly: false });

  try {
    await updateCompanyStats(ctx, { companyId });

    logInfo(ctx, "[company-stats] Updated survey stats for company", { companyId });
  } catch (error) {
    logError(ctx, "[company-stats] There was an error while updating survey stats for company", {
      error,
      companyId,
    });
  }
};

export const sendUpdateCompanyStatsJob = async (ctx: AppContext, data: UpdateCompanyStatsJobData) => {
  return sendJob(ctx, {
    jobName: QueueJobName.UPDATE_COMPANY_STATS,
    data,
    options: { singletonKey: makeSingletonKey({ companyId: data.companyId }, { unique: false }) },
  });
};

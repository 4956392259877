import { CompensationReviewCampaignStatus } from "@prisma/client";
import { isPast } from "date-fns";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { parseISO } from "~/lib/dates";
import { assertNotNil } from "~/lib/utils";
import {
  selectCompensationReviewCurrency,
  transformCompensationReviewCurrency,
} from "~/services/compensation-review/shared/transformCompensationReviewCurrency";
import { externalEmployeeSelectForAutocomplete } from "~/services/external-employee/autocompleteListCompanyExternalEmployees";

export const fetchCampaign = async (
  ctx: AppContext,
  params: {
    campaignId: number;
  }
) => {
  const campaign = await ctx.prisma.compensationReviewCampaign.findFirstOrThrow({
    where: { id: params.campaignId },
    select: {
      id: true,
      companyId: true,
      performanceReviewCycleId: true,
      name: true,
      configurationStep: true,
      validatedConfigurationSteps: true,
      highlightedConfigurationSteps: true,
      requiresSandboxReset: true,
      status: true,
      startDate: true,
      endDate: true,
      currency: { select: selectCompensationReviewCurrency },
      maxReviewersCount: true,
      allowReviewersToSetCurrentReviewer: true,
      preferedSalaryDisplay: true,
      launchNotificationEnabled: true,
      launchNotificationContent: true,
      reminderNotificationEnabled: true,
      reviewerGuidelines: true,
      admins: { select: { userId: true } },
      company: {
        select: {
          defaultSalaryGrid: {
            select: {
              tiersMode: true,
              tiersNames: true,
            },
          },
        },
      },
      budgets: {
        select: {
          id: true,
          budgetingMethod: true,
          prorationStartDate: true,
          name: true,
          amount: true,
          compensationItems: true,
          isAdminBudget: true,
          isPromotionBudget: true,
          relativeVariablePayEnabled: true,
          budgetDefinitionMethod: true,
          recommendationDefinitionMethod: true,
          distributionKey: true,
          adjustmentCriteria: true,
          marketDataPositioningMeasure: true,
          recommendationsAllocation: true,
          skippedPerformanceRatingIds: true,
          skippedPositionings: true,
          subBudgets: {
            select: {
              id: true,
              name: true,
              amount: true,
              adjustmentCriteria: true,
              marketDataPositioningMeasure: true,
              recommendationsAllocation: true,
              performanceRewardFactor: true,
              adjustmentFactor: true,
              postSmoothingBudgetDifference: true,
              postSmoothingOverriddenEmployeesCount: true,
              skippedPerformanceRatingIds: true,
              skippedPositionings: true,
            },
            orderBy: { name: "asc" },
          },
          increaseRules: {
            select: {
              id: true,
              position: true,
              populationId: true,
              target: true,
              type: true,
              unit: true,
              amount: true,
              hardLimit: true,
            },
            orderBy: { position: "asc" },
          },
        },
        orderBy: { id: "asc" },
      },
      finalReviewer: {
        select: {
          id: true,
          externalEmployee: {
            select: externalEmployeeSelectForAutocomplete,
          },
        },
      },
      _count: {
        select: {
          employees: true,
        },
      },
    },
  });

  return {
    ...campaign,

    isConfiguring: campaign.status === CompensationReviewCampaignStatus.CONFIGURATION,
    isSandbox: campaign.status === CompensationReviewCampaignStatus.SANDBOX,
    isInProgress: campaign.status === CompensationReviewCampaignStatus.IN_PROGRESS,
    isClosed: campaign.status === CompensationReviewCampaignStatus.CLOSED,

    isPastEndDate: !!campaign.endDate && isPast(parseISO(campaign.endDate)),

    currency: transformCompensationReviewCurrency(assertNotNil(campaign.currency)),
  };
};

export type FetchCampaignResult = AsyncReturnType<typeof fetchCampaign>;

export type CompensationReviewCampaignBudget = FetchCampaignResult["budgets"][number];

export type CompensationReviewRule = CompensationReviewCampaignBudget["increaseRules"][number];

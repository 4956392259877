import { ExternalEmployeeStatus, type Prisma } from "@prisma/client";
import { match } from "ts-pattern";
import { type AsyncReturnType } from "type-fest";
import { value } from "~/components/helpers";
import { selectForExternalEmployeeBadgeList } from "~/components/ui/ExternalEmployeeBadgeList";
import { locationSelectForSelector } from "~/components/ui/LocationSelector";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { chain, compact, keys } from "~/lib/lodash";
import { logWarn } from "~/lib/logger";
import {
  buildPaginationResult,
  type OrderParams,
  type PaginationParams,
  prismaPaginationParams,
} from "~/lib/pagination";
import { getId, getKeys } from "~/lib/utils";
import { type CountExternalEmployeesByStatusInput } from "~/pages/api/external-employee/count-external-employees-by-status";
import { type FetchExternalEmployeesSampleByLevelInput } from "~/pages/api/external-employee/fetch-external-employees-sample-by-level";
import { externalEmployeeFieldsSelectForDisplay } from "~/services/additional-field/getAllAdditionalFields";
import { type FilterOptionsInput } from "~/services/employee-filter";
import {
  buildExternalEmployeeFilterWhereClauses,
  fetchExternalEmployeeFilterOptions,
} from "~/services/employee-filter/fetchExternalEmployeeFilterOptions";
import { companyEmployeesSelect } from "~/services/employee/employeeRead";
import { externalEmployeeSelectForRemunerationComputation } from "~/services/external-employee";
import { selectExternalEmployeeData } from "~/services/external-employee/selectExternalEmployeeData";
import { selectExternalEmployeeUserPicture } from "~/services/external-employee/selectExternalEmployeeUserForPicture";
import { searchExternalEmployee } from "~/services/users/search";

export const ImportedEmployeeStatusEnum = {
  ...ExternalEmployeeStatus,
  FLAGGED: "FLAGGED",
} as const;

export type ImportedEmployeeStatus = (typeof ImportedEmployeeStatusEnum)[keyof typeof ImportedEmployeeStatusEnum];

export type FetchExternalEmployeesPaginationResult = AsyncReturnType<typeof fetchExternalEmployees>;

export type ExternalEmployeeRow = FetchExternalEmployeesPaginationResult["items"][number];

export type ExternalEmployeesCountByStatusResult = { status: ImportedEmployeeStatus; count: number }[];

export const countExternalEmployeesByStatus = async (
  ctx: AppContext,
  { query, filters }: CountExternalEmployeesByStatusInput
): Promise<ExternalEmployeesCountByStatusResult> => {
  const user = getRequiredUser(ctx);

  const countByStatus = await ctx.prisma.externalEmployee.groupBy({
    by: ["status"],
    where: {
      companyId: user.companyId,
      ...(user.permissions.allowedCountries.length && {
        location: {
          countryId: {
            in: user.permissions.allowedCountries.map(getId),
          },
        },
      }),
      ...(query && { AND: searchExternalEmployee(query) }),
      ...(filters && (await buildExternalEmployeeFilterWhereClauses(ctx, filters))),
    },
    _count: true,
  });

  const countFlagged = await ctx.prisma.externalEmployee.count({
    where: {
      companyId: user.companyId,
      ...(user.permissions.allowedCountries.length && {
        location: {
          countryId: {
            in: user.permissions.allowedCountries.map(getId),
          },
        },
      }),
      ...(query && { AND: searchExternalEmployee(query) }),
      mappedEmployee: {
        employeeDataValidationFlags: {
          some: {
            isLive: true,
          },
        },
      },
    },
  });

  return [
    ...getKeys(ExternalEmployeeStatus).map((status) => ({
      status,
      count: countByStatus.find((row) => row.status === status)?._count ?? 0,
    })),
    {
      status: ImportedEmployeeStatusEnum.FLAGGED,
      count: countFlagged,
    },
  ];
};

const selectMappedEmployeeForFetch = {
  id: true,
  employeeNumber: true,
  firstName: true,
  lastName: true,
  gender: true,
  baseSalary: true,
  fixedBonus: true,
  fixedBonusPercentage: true,
  onTargetBonus: true,
  onTargetBonusPercentage: true,
  externalJobTitle: true,
  externalLevel: true,
  birthDate: true,
  hireDate: true,
  level: true,
  isFounder: true,
  source: true,
  updateReason: true,
  user: { select: { firstName: true, lastName: true, email: true } },
  currency: true,
  mappingLocation: {
    select: locationSelectForSelector,
  },
  jobId: true,
  locationId: true,
  job: {
    select: {
      id: true,
      name: true,
      description: true,
      descriptionTranslations: true,
      availableLevels: true,
    },
  },
  location: { select: { name: true, country: { select: { name: true } } } },
  externalEmployee: {
    select: {
      ...selectExternalEmployeeData,
      performanceReviewRating: {
        select: {
          id: true,
          name: true,
        },
      },
    },
  },
  employeeDataValidationFlags: true,
} satisfies Prisma.ExternalEmployee$mappedEmployeeArgs["select"];

const selectExternalEmployeeForFetch = {
  ...selectExternalEmployeeUserPicture,
  id: true,
  externalId: true,
  employeeNumber: true,
  firstName: true,
  lastName: true,
  birthDate: true,
  status: true,
  source: true,
  mappedEmployeeId: true,
  isFounder: true,
  performanceReviewRatingId: true,
  driftFields: true,
  email: true,
  job: {
    select: {
      id: true,
      name: true,
      mappedJobId: true,
      mappedJob: {
        select: {
          id: true,
          name: true,
          description: true,
          descriptionTranslations: true,
          availableLevels: true,
        },
      },
    },
  },
  level: { select: { id: true, name: true, mappedLevel: true } },
  location: {
    select: {
      id: true,
      name: true,
      country: { select: { name: true, alpha2: true } },
      mappedLocation: {
        select: locationSelectForSelector,
      },
    },
  },
  currency: true,
  mappedEmployee: {
    select: selectMappedEmployeeForFetch,
  },
  ...externalEmployeeFieldsSelectForDisplay,
  ...externalEmployeeSelectForRemunerationComputation,
  ...selectExternalEmployeeUserPicture,
  businessUnit: true,
  deletedAt: true,
  offCycleReviewRequests: true,
} satisfies Prisma.ExternalEmployeeSelect;

const includeExternalEmployeeForForm = {
  currency: true,
  manager: {
    include: {
      level: true,
      job: {
        include: {
          mappedJob: { include: { family: true } },
        },
      },
      ...selectExternalEmployeeUserPicture,
    },
  },
  job: {
    include: {
      mappedJob: { include: { family: true } },
    },
  },
  level: true,
  location: {
    include: {
      country: true,
      mappedLocation: { include: { country: true } },
    },
  },
  remunerationItems: { include: { nature: true } },
  mappedEmployee: { select: companyEmployeesSelect },
  offCycleReviewRequests: true,
  additionalFieldValues: {
    select: {
      id: true,
      dateValue: true,
      numberValue: true,
      stringValue: true,
      percentageValue: true,
      additionalFieldId: true,
      additionalFieldMapping: {
        select: {
          integrationSettings: {
            select: {
              source: true,
            },
          },
        },
      },
    },
  },
  performanceReviewRating: true,
  ...selectExternalEmployeeUserPicture,
};

export const fetchExternalEmployees = async (
  ctx: AppContext,
  params: {
    query: string | null;
    status: ImportedEmployeeStatus | null;
    pagination: PaginationParams;
    order: OrderParams;
    filters?: FilterOptionsInput;
  }
) => {
  const user = getRequiredUser(ctx);

  const baseWhere: Prisma.ExternalEmployeeWhereInput = {
    companyId: user.companyId,
    ...(params.status &&
      keys(ExternalEmployeeStatus).includes(params.status) && { status: params.status as ExternalEmployeeStatus }),
    /**
     * User with country permission can only see employees from this/these country/ies
     * Filter external employees for user with permission
     */
    ...(user.permissions.allowedCountries.length && {
      location: {
        countryId: {
          in: user.permissions.allowedCountries.map(getId),
        },
      },
    }),
    ...(params.query && { AND: searchExternalEmployee(params.query) }),
  };

  const whereFilters = params.filters ? await buildExternalEmployeeFilterWhereClauses(ctx, params.filters) : undefined;

  const extraWhereClause =
    params.status === ImportedEmployeeStatusEnum.FLAGGED
      ? { mappedEmployee: { employeeDataValidationFlags: { some: { isLive: true } } } }
      : {};

  const where = {
    AND: compact([baseWhere, extraWhereClause, whereFilters]),
  };

  const direction = params.order.direction;
  const directionNullsLast = { sort: params.order.direction, nulls: "last" } as const;
  const orderBy = match<string | null, Prisma.Enumerable<Prisma.ExternalEmployeeOrderByWithRelationInput>>(
    params.order.column
  )
    .with("business-unit", () => ({ businessUnit: direction }))
    .with("source", () => ({ source: direction }))
    .with("employeeNumber", () => ({ employeeNumber: direction }))
    .with("name", () => [{ firstName: directionNullsLast }, { lastName: directionNullsLast }])
    .with("job", () => ({ job: { name: direction } }))
    .with("location", () => [{ location: { country: { name: direction } } }, { location: { name: direction } }])
    .with("gender", () => ({ gender: directionNullsLast }))
    .with("level", () => ({ level: { name: direction } }))
    .with("hire-date", () => ({ hireDate: directionNullsLast }))
    .with("fte", () => ({ fteDivider: direction }))
    .with("email", () => ({ email: direction }))
    .with("performance-review-rating", () => ({ performanceReviewRating: { position: direction } }))
    .otherwise((column) => {
      if (column) {
        logWarn(ctx, "[warn] Unhandled externalEmployee order column", { column });
      }

      return { employeeNumber: direction };
    });

  // Find all external employees that need mapping.
  const [externalEmployees, count, filterOptions] = await Promise.all([
    ctx.prisma.externalEmployee.findMany({
      where,
      ...prismaPaginationParams(params.pagination),
      orderBy,
      select: selectExternalEmployeeForFetch,
    }),
    ctx.prisma.externalEmployee.count({ where }),
    fetchExternalEmployeeFilterOptions(ctx),
  ]);

  return buildPaginationResult({
    items: externalEmployees,
    count,
    pagination: params.pagination,
    meta: {
      filterOptions,
    },
  });
};

export const fetchExternalEmployeeForFormFromCompensationReview = async (
  ctx: AppContext,
  externalEmployeeId: number
) => {
  const user = getRequiredUser(ctx);

  const compensationReviewEmployee = await ctx.prisma.compensationReviewEmployee.findFirstOrThrow({
    where: { companyId: user.companyId, externalEmployeeId: externalEmployeeId },
    include: {
      externalEmployee: { include: includeExternalEmployeeForForm },
    },
  });

  return compensationReviewEmployee.externalEmployee;
};

export const fetchExternalEmployeeForForm = async (ctx: AppContext, externalEmployeeId: number) => {
  const user = getRequiredUser(ctx);

  return ctx.prisma.externalEmployee.findFirstOrThrow({
    where: { companyId: user.companyId, id: externalEmployeeId },
    include: includeExternalEmployeeForForm,
  });
};

export type ExternalEmployeeForForm = AsyncReturnType<typeof fetchExternalEmployeeForForm>;

export const fetchExternalEmployeesSampleByJob = async (ctx: AppContext, { jobIds }: { jobIds: number[] }) => {
  const user = getRequiredUser(ctx);

  return ctx.prisma.externalJob.findMany({
    where: {
      id: { in: jobIds },
      companyId: user.companyId,
    },
    select: {
      id: true,
      employees: {
        select: selectForExternalEmployeeBadgeList,
        take: 5,
      },
    },
  });
};

export const fetchExternalEmployeesSampleByLevel = async (
  ctx: AppContext,
  input: FetchExternalEmployeesSampleByLevelInput
) => {
  const user = getRequiredUser(ctx);

  const levelsWithEmployees = await value(async () => {
    if (input.levels) {
      const externalEmployees = await ctx.prisma.externalEmployee.findMany({
        where: {
          companyId: user.companyId,
          mappedEmployee: {
            level: { in: input.levels },
          },
          level: null,
        },
        select: {
          ...selectForExternalEmployeeBadgeList,
          mappedEmployee: { select: { level: true } },
        },
        take: 5,
      });
      return chain(externalEmployees)
        .filter((employee) => employee.mappedEmployee?.level !== null)
        .groupBy((employee) => employee.mappedEmployee?.level)
        .map((employees, level) => ({
          id: level,
          employees,
        }))
        .value();
    }
    return [];
  });

  const externalLevels = await ctx.prisma.externalLevel.findMany({
    where: {
      id: { in: input.levelIds },
      companyId: user.companyId,
    },
    select: {
      id: true,
      employees: {
        select: selectForExternalEmployeeBadgeList,
        take: 5,
      },
    },
  });

  return [...levelsWithEmployees, ...externalLevels];
};

export const fetchExternalEmployeesSampleByLocation = async (
  ctx: AppContext,
  { locationIds }: { locationIds: number[] }
) => {
  const user = getRequiredUser(ctx);

  return ctx.prisma.externalLocation.findMany({
    where: {
      id: { in: locationIds },
      companyId: user.companyId,
    },
    select: {
      id: true,
      employees: {
        select: selectForExternalEmployeeBadgeList,
        take: 5,
      },
    },
  });
};

import type { GetServerSidePropsResult as NextGetServerSidePropsResult } from "next";
import { type GetServerSidePropsContext, type Route } from "nextjs-routes";
import React, { useContext } from "react";
import { pick } from "~/lib/lodash";
import { parseNumber } from "~/lib/queryParams";
import { useFetchCampaignQuery } from "~/pages/api/compensation-review/campaigns/fetch-campaign";
import {
  type CompensationReviewCampaignContext,
  type CompensationReviewCampaignData,
  type CompensationReviewContext as CompensationReviewCtx,
  type CompensationReviewData,
  fetchCompensationReviewCampaignContext,
} from "~/services/compensation-review/compensationReviewContext";

type Handler<Props, Path extends Route["pathname"]> = (
  context: GetServerSidePropsContext<Path> & CompensationReviewCampaignContext
) => Promise<NextGetServerSidePropsResult<Omit<Props, "campaign" | "authenticatedReviewer" | "permissions">>>;

export const campaignHandler = <Props, Path extends Route["pathname"] = "/compensation-review/campaigns/[campaignId]">(
  handler: Handler<Props, Path>
) => {
  return async (ctx: GetServerSidePropsContext<Path>) => {
    const campaignId = parseNumber(ctx.query, "campaignId", { orThrow: true });

    const campaignCtx = await fetchCompensationReviewCampaignContext(ctx.req, { campaignId });

    const result = await handler({ ...ctx, ...campaignCtx });

    if ("props" in result) {
      return {
        props: {
          ...result.props,
          ...extractCompensationReviewData(campaignCtx),
        },
      };
    }

    return result;
  };
};

export const campaignPage = <Props,>(
  Component: React.FC<
    Props & CompensationReviewCampaignData & { campaignQuery: ReturnType<typeof useFetchCampaignQuery> }
  >
) => {
  return function Page(props: Props & CompensationReviewCampaignData) {
    const campaignQuery = useFetchCampaignQuery({
      input: { campaignId: props.campaign?.id },
      options: { initialData: props.campaign, enabled: !!props.campaign },
    });

    const data = {
      ...props,
      campaign: campaignQuery.data,
    };

    return (
      <CompensationReviewContext.Provider value={data}>
        <Component {...data} campaignQuery={campaignQuery} />
      </CompensationReviewContext.Provider>
    );
  };
};

export const CompensationReviewContext = React.createContext<CompensationReviewData | null>(null);

export const CompensationReviewContextProvider = CompensationReviewContext.Provider;

export const useCompensationReview = () => {
  const ctx = useContext(CompensationReviewContext);

  if (!ctx) {
    throw new Error("useCompensationReview must be used within a CompensationReviewContext.Provider");
  }

  return ctx;
};

export const useCampaign = () => {
  const ctx = useCompensationReview();

  if (!ctx.campaign) {
    throw new Error("useCampaign must be used within a CampaignContext.Provider with a campaign scope");
  }

  return ctx as CompensationReviewCampaignData;
};

export const extractCompensationReviewData = (ctx: CompensationReviewCtx) => {
  return pick(
    ctx,
    "scope",
    "campaign",
    "authenticatedReviewer",
    "salaryGrid",
    "parameters",
    "permissions",
    "companyId"
  );
};

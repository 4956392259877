import { mapSeries } from "bluebird";
import { number, object } from "yup";
import { type AppContext } from "~/lib/context";
import { makeSingletonKey } from "~/lib/makeSingletonKey";
import { BaseJobDataSchema } from "~/lib/queue/baseJobDataSchema";
import { QueueJobName } from "~/lib/queue/queueJobName";
import { sendJob } from "~/lib/queue/sendJob";
import { type YupOutputType } from "~/lib/utils";
import { updateCampaignRecommendationMatrix } from "~/services/compensation-review/campaigns/admin/updateCampaignRecommendationMatrix";
import { fetchCompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";
import { getCompensationReviewBudget } from "~/services/compensation-review/shared/compensationReviewBudget";

const ApplyCampaignRecommendationDistributionJobDataSchema = BaseJobDataSchema.concat(
  object({
    campaignId: number().required(),
    budgetId: number().required(),
  })
);

export type ApplyCampaignRecommendationDistributionJobData = YupOutputType<
  typeof ApplyCampaignRecommendationDistributionJobDataSchema
>;

export const applyCampaignRecommendationDistributionWorkerService = async (
  ctx: AppContext,
  data: ApplyCampaignRecommendationDistributionJobData
) => {
  const { campaignId } = ApplyCampaignRecommendationDistributionJobDataSchema.validateSync(data, { abortEarly: false });

  const campaignContext = await fetchCompensationReviewCampaignContext(ctx, { campaignId });
  const budget = getCompensationReviewBudget(campaignContext, data.budgetId);

  await mapSeries(budget.subBudgets, async (subBudget) => {
    await updateCampaignRecommendationMatrix(campaignContext, {
      budgetId: budget.id,
      subBudget,
    });
  });
};

export const sendApplyCampaignRecommendationDistributionJob = async (
  ctx: AppContext,
  data: ApplyCampaignRecommendationDistributionJobData
) => {
  await sendJob(ctx, {
    jobName: QueueJobName.APPLY_CAMPAIGN_RECOMMENDATION_DISTRIBUTION,
    data,
    options: {
      singletonKey: makeSingletonKey({ companyId: data.companyId, campaignId: data.campaignId }, { unique: false }),
    },
  });
};

import { KeyboardDoubleArrowUp } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { CompensationReviewSalaryDisplay } from "@prisma/client";
import React, { useMemo } from "react";
import { value } from "~/components/helpers";
import { RangePenetrationIndicator } from "~/components/salary-bands/RangePenetrationIndicator";
import { PercentageDifference } from "~/components/ui/PercentageDifference";
import { useMoney } from "~/hooks/useMoney";
import { useI18n } from "~/lib/i18n/useI18n";
import { useCompensationReview } from "~/services/compensation-review/campaigns/campaignHandler";
import {
  computeFteAmount,
  computeIncreaseAmounts,
  type IncreaseAmounts,
} from "~/services/compensation-review/shared/compensationItems";
import { computeNewBandPositioning } from "~/services/compensation-review/shared/computeNewBandPositioning";
import { type CompensationReviewRecommendationEmployee } from "~/services/compensation-review/shared/fetchCompensationReviewRecommendations";
import { formatSalaryRangeName } from "~/services/salary-bands/helpers/formatSalaryBandName";

type Props = {
  employee: CompensationReviewRecommendationEmployee;
  increaseAmounts?: IncreaseAmounts;
};

export const RecommendationSummary: React.FC<Props> = ({ employee, increaseAmounts }) => {
  const { t } = useI18n();
  const money = useMoney(employee.currency);
  const compensationReview = useCompensationReview();

  const newAmounts = useMemo(() => {
    const increaseValues =
      increaseAmounts ??
      computeIncreaseAmounts({
        adjustments: employee.adjustments,
        fields: ["submittedAmount", "recommendedAmount"],
      });

    return {
      baseSalary: employee.baseSalary + increaseValues.baseSalary,
      variablePay: employee.variablePay + (increaseValues?.variablePay ?? 0),
      onTargetEarnings: employee.onTargetEarnings + increaseValues.onTargetEarnings,
      annualPerformanceBonus: increaseValues.annualPerformanceBonus,
      exceptionalBonus: increaseValues.exceptionalBonus,
    };
  }, [employee, increaseAmounts]);

  const hasSalaryIncrease = !increaseAmounts || increaseAmounts.onTargetEarnings > 0;
  const showTotalAmount =
    !increaseAmounts || (increaseAmounts.baseSalary > 0 && (increaseAmounts.variablePay > 0 || !!employee.otherBonus));

  const salaryBandData = value(() => {
    if (!employee.salaryRangeEmployee) return null;

    const targetRange = employee.targetRangeAfterPromotion ?? employee.salaryRangeEmployee.range;
    const measure = targetRange.band.measure;

    if (!increaseAmounts) {
      return {
        measure,
        currentCompaRatio: employee.compaRatio,
        previewCompaRatio: employee.newCompaRatio,
        currentRangePenetration: employee.rangePenetration,
        previewRangePenetration: employee.newRangePenetration,
      };
    }

    const newBandPositioning = computeNewBandPositioning({
      employee,
      newValues: {
        fteBaseSalary: computeFteAmount(employee.baseSalary + increaseAmounts.baseSalary, employee.fteCoefficient),
        fteOnTargetEarnings: computeFteAmount(
          employee.onTargetEarnings + increaseAmounts.onTargetEarnings,
          employee.fteCoefficient
        ),
      },
    });

    return {
      measure,
      currentCompaRatio: employee.compaRatio,
      previewCompaRatio: newBandPositioning?.newCompaRatio ?? null,
      currentRangePenetration: employee.rangePenetration,
      previewRangePenetration: newBandPositioning?.newRangePenetration ?? null,
    };
  });

  return (
    <Stack>
      {employee.targetRangeAfterPromotion && (
        <Stack direction="row" alignItems="center" spacing={1} className="mb-4">
          <KeyboardDoubleArrowUp className="-ml-1" color="info" />

          <Typography variant="subtitle1" noWrap marginBottom={4}>
            {t("pages.compensation-review.recommendation-panel.promotion-to", {
              jobTitle: formatSalaryRangeName(
                employee.targetRangeAfterPromotion.band,
                employee.targetRangeAfterPromotion.level
              ),
            })}
          </Typography>
        </Stack>
      )}

      {!increaseAmounts ||
        (increaseAmounts.baseSalary > 0 && (
          <Grid container alignItems="baseline" spacing={2}>
            <Grid xs={5} component={Typography} variant="subtitle1" noWrap>
              {t("pages.compensation-review.recommendation-panel.base-salary")}
            </Grid>
            <Grid xs={3} component={Typography} color="text.secondary" className="text-right">
              {money.format(employee.baseSalary, { roundTo: 1, salaryPeriodConfig: employee })}
            </Grid>
            <Grid xs={1} component={Typography} className="text-center">
              →
            </Grid>
            <Grid xs={2} component={Typography} className="text-right font-medium">
              {money.format(newAmounts.baseSalary, { roundTo: 1, salaryPeriodConfig: employee })}
            </Grid>
            <Grid xs={1} component={Typography} className="text-right" color="text.secondary" variant="caption">
              <PercentageDifference compare={newAmounts.baseSalary} to={employee.baseSalary} decimals={2} />
            </Grid>
          </Grid>
        ))}

      {!increaseAmounts ||
        (increaseAmounts.variablePay > 0 && (
          <Grid container alignItems="baseline" spacing={2}>
            <Grid xs={5} component={Typography} variant="subtitle1" noWrap>
              {t("pages.compensation-review.recommendation-panel.variable-pay")}
            </Grid>
            <Grid xs={3} component={Typography} color="text.secondary" className="text-right">
              {money.format(employee.variablePay, { roundTo: 1, salaryPeriodConfig: employee })}
            </Grid>
            <Grid xs={1} component={Typography} className="text-center">
              →
            </Grid>
            <Grid xs={2} component={Typography} className="text-right font-medium">
              {money.format(newAmounts.variablePay, { roundTo: 1, salaryPeriodConfig: employee })}
            </Grid>
            <Grid xs={1} component={Typography} className="text-right" color="text.secondary" variant="caption">
              <PercentageDifference compare={newAmounts.variablePay} to={employee.variablePay} decimals={2} />
            </Grid>
          </Grid>
        ))}

      {!!employee.otherBonus &&
        hasSalaryIncrease &&
        compensationReview.parameters.preferedSalaryDisplay === CompensationReviewSalaryDisplay.TOTAL_CASH && (
          <Grid container alignItems="baseline" spacing={2}>
            <Grid xs={5} component={Typography} variant="subtitle1" noWrap>
              {t("pages.compensation-review.recommendation-panel.other-bonus")}
            </Grid>
            <Grid xs={3} component={Typography} color="text.secondary" className="text-right">
              {money.format(employee.otherBonus, { roundTo: 1, salaryPeriodConfig: employee })}
            </Grid>
            <Grid xs={1} component={Typography} className="text-center">
              →
            </Grid>
            <Grid xs={2} component={Typography} className="text-right font-medium">
              {money.format(employee.otherBonus, { roundTo: 1, salaryPeriodConfig: employee })}
            </Grid>
          </Grid>
        )}

      {showTotalAmount &&
        compensationReview.parameters.preferedSalaryDisplay === CompensationReviewSalaryDisplay.ON_TARGET_EARNINGS && (
          <Grid container alignItems="baseline" spacing={2}>
            <Grid xs={5} component={Typography} variant="subtitle1" noWrap>
              {t("pages.compensation-review.recommendation-panel.on-target-earnings")}
            </Grid>
            <Grid xs={3} component={Typography} color="text.secondary" className="text-right">
              {money.format(employee.onTargetEarnings, { roundTo: 1, salaryPeriodConfig: employee })}
            </Grid>
            <Grid xs={1} component={Typography} className="text-center">
              →
            </Grid>
            <Grid xs={2} component={Typography} className="text-right font-medium">
              {money.format(newAmounts.onTargetEarnings, { roundTo: 1, salaryPeriodConfig: employee })}
            </Grid>
            <Grid xs={1} component={Typography} className="text-right" color="text.secondary" variant="caption">
              <PercentageDifference compare={newAmounts.onTargetEarnings} to={employee.onTargetEarnings} decimals={2} />
            </Grid>
          </Grid>
        )}

      {showTotalAmount &&
        compensationReview.parameters.preferedSalaryDisplay === CompensationReviewSalaryDisplay.TOTAL_CASH && (
          <Grid container alignItems="baseline" spacing={2}>
            <Grid xs={5} component={Typography} variant="subtitle1" noWrap>
              {t("pages.compensation-review.recommendation-panel.total-cash")}
            </Grid>
            <Grid xs={3} component={Typography} color="text.secondary" className="text-right">
              {money.format(employee.totalCash, { roundTo: 1, salaryPeriodConfig: employee })}
            </Grid>
            <Grid xs={1} component={Typography} className="text-center">
              →
            </Grid>
            <Grid xs={2} component={Typography} className="text-right font-medium">
              {money.format(newAmounts.onTargetEarnings + employee.otherBonus, {
                roundTo: 1,
                salaryPeriodConfig: employee,
              })}
            </Grid>
            <Grid xs={1} component={Typography} className="text-right" color="text.secondary" variant="caption">
              <PercentageDifference
                compare={newAmounts.onTargetEarnings + employee.otherBonus}
                to={employee.totalCash}
                decimals={2}
              />
            </Grid>
          </Grid>
        )}

      {!!newAmounts.annualPerformanceBonus && (
        <Grid container alignItems="baseline" marginTop={hasSalaryIncrease ? 4 : 0}>
          <Grid xs={5} component={Typography} variant="subtitle1" noWrap>
            {t("pages.compensation-review.recommendation-panel.annual-performance-bonus")}
          </Grid>
          <Grid xs={4} />
          <Grid xs={2} component={Typography} className="text-right font-medium">
            {money.format(newAmounts.annualPerformanceBonus, { roundTo: 1 })}
          </Grid>
        </Grid>
      )}

      {!!newAmounts.exceptionalBonus && (
        <Grid container alignItems="baseline" marginTop={!newAmounts.annualPerformanceBonus ? 4 : 0}>
          <Grid xs={5} component={Typography} variant="subtitle1" noWrap>
            {t("pages.compensation-review.recommendation-panel.exceptional-bonus")}
          </Grid>
          <Grid xs={4} />
          <Grid xs={2} component={Typography} className="text-right font-medium">
            {money.format(newAmounts.exceptionalBonus, { roundTo: 1 })}
          </Grid>
        </Grid>
      )}

      {salaryBandData && employee.targetRangeAfterPromotion && salaryBandData.previewCompaRatio && (
        <>
          <Grid container marginTop={4}>
            <Grid xs={5} component={Typography} variant="subtitle1" noWrap>
              {t("pages.compensation-review.recommendation-panel.new-compa-ratio")}
            </Grid>
            <Grid xs={6} component={Typography} className="text-right font-medium">
              {salaryBandData.previewCompaRatio.toFixed(2)}
            </Grid>
          </Grid>
          <Grid container marginTop={2} alignItems="center">
            <Grid xs={5} component={Typography} variant="subtitle1">
              {t("pages.compensation-review.recommendation-panel.position-in-new-range")}
            </Grid>
            <Grid xs={6} component={Stack} alignItems="end">
              <RangePenetrationIndicator
                measure={salaryBandData.measure}
                salaryRangeEmployee={{
                  baseSalaryRangePenetration: salaryBandData.previewRangePenetration,
                  onTargetEarningsRangePenetration: salaryBandData.previewRangePenetration,
                }}
                tiersMode={compensationReview.salaryGrid?.tiersMode}
              />
            </Grid>
          </Grid>
        </>
      )}

      {salaryBandData && !employee.targetRangeAfterPromotion && salaryBandData.previewCompaRatio && (
        <>
          <Grid container marginTop={4}>
            <Grid xs={5} component={Typography} variant="subtitle1" noWrap>
              Compa-ratio
            </Grid>
            {salaryBandData.currentCompaRatio && (
              <>
                <Grid xs={3} component={Typography} color="text.secondary" className="text-right">
                  {salaryBandData.currentCompaRatio.toFixed(2)}
                </Grid>
                <Grid xs={1} component={Typography} className="text-center">
                  →
                </Grid>
              </>
            )}
            <Grid xs={2} component={Typography} className="text-right font-medium">
              {salaryBandData.previewCompaRatio.toFixed(2)}
            </Grid>
          </Grid>
          <Grid container marginTop={2}>
            <Grid xs={5} component={Typography} variant="subtitle1" noWrap>
              {t("pages.compensation-review.recommendation-panel.position-in-range")}
            </Grid>
            <Grid xs={6} component={Stack} alignItems="end">
              <RangePenetrationIndicator
                measure={salaryBandData.measure}
                salaryRangeEmployee={{
                  baseSalaryRangePenetration: salaryBandData.currentRangePenetration,
                  onTargetEarningsRangePenetration: salaryBandData.currentRangePenetration,
                }}
                newRangePenetration={salaryBandData.previewRangePenetration ?? undefined}
                tiersMode={compensationReview.salaryGrid?.tiersMode}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Stack>
  );
};

import { type Prisma } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { type CompensationReviewContext } from "~/services/compensation-review/compensationReviewContext";

export const computeSalaryMass = async (
  ctx: CompensationReviewContext,
  params: {
    where: Prisma.CompensationReviewEmployeeWhereInput;
  }
) => {
  const { where } = params;

  const salaryMassData = await ctx.prisma.compensationReviewEmployee.aggregate({
    _sum: {
      convertedOnTargetEarnings: true,
    },
    where,
  });

  const total = salaryMassData._sum.convertedOnTargetEarnings ?? 0;

  return {
    total,
  };
};

export type ComputeSalaryMassResult = AsyncReturnType<typeof computeSalaryMass>;

import { CompensationReviewRecommendationOrigin } from "@prisma/client";
import { mapSeries } from "bluebird";
import { ensure } from "~/lib/ensure";
import { isNil } from "~/lib/lodash";
import { convertCurrency } from "~/lib/money";
import { transaction } from "~/lib/transaction";
import { computeEmployeesWithMatrixRecommendations } from "~/services/compensation-review/campaigns/admin/computeEmployeesWithMatrixRecommendations";
import { fetchCampaignRecommendationDistribution } from "~/services/compensation-review/campaigns/admin/fetchCampaignRecommendationDistribution";
import { updateSalaryAdjustmentRecommendations } from "~/services/compensation-review/campaigns/admin/updateSalaryAdjustmentRecommendations";
import { type FetchCampaignResult } from "~/services/compensation-review/campaigns/fetchCampaign";
import { type CompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";
import { getCompensationReviewBudget } from "~/services/compensation-review/shared/compensationReviewBudget";

export const updateCampaignRecommendationMatrix = async (
  ctx: CompensationReviewCampaignContext,
  input: {
    budgetId: number;
    subBudget: FetchCampaignResult["budgets"][number]["subBudgets"][number];
  }
) => {
  const { subBudget } = input;
  const {
    adjustmentCriteria,
    marketDataPositioningMeasure,
    recommendationsAllocation,
    performanceRewardFactor,
    adjustmentFactor,
  } = subBudget;

  ensure(() => ctx.permissions.canConfigure);

  if (
    isNil(adjustmentCriteria) ||
    isNil(recommendationsAllocation) ||
    isNil(performanceRewardFactor) ||
    isNil(adjustmentFactor)
  )
    return;

  const budget = getCompensationReviewBudget(ctx, input.budgetId);

  const recommendationDistribution = await fetchCampaignRecommendationDistribution(ctx, {
    campaignId: ctx.campaign.id,
    budgetId: input.budgetId,
    subBudgetId: subBudget.id,
    adjustmentCriteria,
    marketDataPositioningMeasure,
  });

  const { employees, budgetDifference } = await computeEmployeesWithMatrixRecommendations(ctx, {
    totalBudget: subBudget.amount ?? 0,
    budgetId: input.budgetId,
    subBudget: {
      ...subBudget,
      adjustmentCriteria,
      recommendationsAllocation,
      performanceRewardFactor,
      adjustmentFactor,
    },
    recommendationDistribution,
    enableSmoothing: true,
    enableRulesOverride: true,
  });

  const overridenEmployeesCount = employees.filter(
    (employee) => employee.adjustment.origin === CompensationReviewRecommendationOrigin.RULE
  ).length;

  await transaction(ctx, async (ctx) => {
    await ctx.prisma.compensationReviewSubBudget.update({
      where: { id: subBudget.id, campaignId: ctx.campaign.id },
      data: {
        postSmoothingBudgetDifference: budgetDifference,
        postSmoothingOverriddenEmployeesCount: overridenEmployeesCount,
      },
    });

    await ctx.prisma.compensationReviewBudgetEligibility.updateMany({
      where: {
        campaignId: ctx.campaign.id,
        budgetId: input.budgetId,
        employee: recommendationDistribution.where,
      },
      data: {
        budgetedAmount: null,
        convertedBudgetedAmount: null,
      },
    });

    await ctx.prisma.compensationReviewAdjustment.updateMany({
      where: {
        campaignId: ctx.campaign.id,
        budgetId: input.budgetId,
        employee: recommendationDistribution.where,
      },
      data: {
        recommendedAmount: null,
        convertedRecommendedAmount: null,
      },
    });

    await mapSeries(employees, async (employee) => {
      const convertedOnTargetEarningsRecommendation = employee.adjustment.recommendation;
      const convertedBudgetedAmount = convertedOnTargetEarningsRecommendation / recommendationsAllocation;
      const onTargetEarningsRecommendation = convertCurrency(
        convertedOnTargetEarningsRecommendation,
        ctx.campaign.currency,
        employee.currency
      );
      const budgetedAmount = convertCurrency(convertedBudgetedAmount, ctx.campaign.currency, employee.currency);

      const eligibility = employee.eligibilities.find((eligibility) => eligibility.budgetId === input.budgetId);
      if (!eligibility) throw new Error("Eligibility not found");

      await ctx.prisma.compensationReviewBudgetEligibility.update({
        where: { id: eligibility.id },
        data: { budgetedAmount, convertedBudgetedAmount },
      });

      await updateSalaryAdjustmentRecommendations(ctx, {
        employee,
        budget,
        onTargetEarningsRecommendation,
      });
    });
  });
};

import { generateUserHMAC } from "~/lib/crypto";
import { type MiddlewareContext, next } from "~/lib/middleware/middleware";
import { getFromSession, SessionKey, setInSession } from "~/lib/session";
import { type NullableAuthenticatedUser, type RequiredAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";

export const generateUserHash = async (
  ctx: MiddlewareContext<{ user: RequiredAuthenticatedUser; impersonatingUser: NullableAuthenticatedUser }>
) => {
  const userHash = getFromSession(ctx.req, { key: SessionKey.USER_HASH });

  if (userHash) {
    return next(ctx);
  }

  const hmac = generateUserHMAC(ctx.req.user);
  if (hmac) {
    setInSession(ctx.req, { key: SessionKey.USER_HASH, value: hmac });
    await ctx.req.session.save();
  }

  return next(ctx);
};

import { type MiddlewareContext, next } from "~/lib/middleware/middleware";
import { getFromSession, SessionKey } from "~/lib/session";
import { type RequiredAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";
import { resolveImpersonatedUser } from "~/services/auth/user-impersonation/resolveImpersonatedUser";

export const resolveUserImpersonation = async (ctx: MiddlewareContext<{ user: RequiredAuthenticatedUser }>) => {
  const impersonatedUser = await resolveImpersonatedUser(ctx.req, {
    subsidiaryId: getFromSession(ctx.req, { key: SessionKey.IMPERSONATED_SUBSIDIARY_ID }),
    impersonatingUser: ctx.user,
    impersonatedUserId: getFromSession(ctx.req, { key: SessionKey.IMPERSONATED_USER_ID }) ?? null,
  });

  if (impersonatedUser) {
    return next({
      user: impersonatedUser,
      impersonatingUser: ctx.user,
    });
  }

  return next({
    ...ctx,
    impersonatingUser: null,
  });
};

import { CompensationReviewCampaignStatus } from "@prisma/client";
import { ensure } from "~/lib/ensure";
import { resetCampaignRecommendations } from "~/services/compensation-review/campaigns/admin/resetCampaignRecommendations";
import { type CompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";

export const startCampaignSandbox = async (ctx: CompensationReviewCampaignContext) => {
  ensure(() => ctx.permissions.canConfigure);

  await resetCampaignRecommendations(ctx);

  await ctx.prisma.compensationReviewCampaign.update({
    where: { id: ctx.campaign.id },
    data: {
      status: CompensationReviewCampaignStatus.SANDBOX,
      requiresSandboxReset: false,
      highlightedConfigurationSteps: [],
    },
  });
};

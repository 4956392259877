import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { BusinessLogicError } from "~/lib/errors/businessLogicError";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { type updateColumnsPreferencesInput } from "~/pages/api/users/update-columns-preferences";
import { getUserRoles } from "~/services/user/permissions/utils/getUserRoles";

export const updateColumnsPreferences = async (ctx: AppContext, input: updateColumnsPreferencesInput) => {
  const user = getRequiredUser(ctx);

  if (!getUserRoles(user).isAdmin) {
    throw new BusinessLogicError("You need to be an admin to update company default columns");
  }

  const companySettings = await ctx.prisma.companySettings.findUnique({
    where: {
      companyId: user.companyId,
    },
  });

  if (!companySettings) {
    return ctx.prisma.companySettings.create({
      data: {
        companyId: user.companyId,
        [input.columnsKey]: input.columns,
      },
    });
  }

  await ctx.prisma.companySettings.update({
    where: {
      companyId: user.companyId,
    },
    data: {
      [input.columnsKey]: input.columns,
    },
  });
};

export type updateColumnsPreferencesResult = AsyncReturnType<typeof updateColumnsPreferences>;

import { SalaryGridMeasure } from "@prisma/client";
import { convertCurrency } from "~/lib/money";
import { computeCompaRatio } from "~/services/salary-bands/benchmark/compaRatio";
import { computeRangePenetration } from "~/services/salary-bands/benchmark/rangePenetration";

type TargetRange = {
  band: { measure: SalaryGridMeasure; currency: { code: string; euroExchangeRate: number } };
  midpoint: number;
  min: number;
  max: number;
};

export const computeNewBandPositioning = (params: {
  employee: {
    currency: { code: string; euroExchangeRate: number };
    targetRangeAfterPromotion: TargetRange | null;
    salaryRangeEmployee: { range: TargetRange } | null;
  };
  newValues: {
    fteBaseSalary: number;
    fteOnTargetEarnings: number;
  };
}) => {
  if (!params.employee.salaryRangeEmployee) {
    return null;
  }

  const targetRange = params.employee.targetRangeAfterPromotion ?? params.employee.salaryRangeEmployee.range;

  const measure = targetRange.band.measure;

  const previewSalary =
    measure === SalaryGridMeasure.BASE_SALARY ? params.newValues.fteBaseSalary : params.newValues.fteOnTargetEarnings;

  // Avoid converting currency if the employee and the target range are the same.
  // This allows to reconcile amounts in the same currency but using a different exchange rate
  // due to compensation review fixed exchange rates.
  const convertedPreviewSalary =
    params.employee.currency.code === targetRange.band.currency.code
      ? previewSalary
      : convertCurrency(previewSalary, params.employee.currency, targetRange.band.currency);

  const newCompaRatio = computeCompaRatio({
    amount: convertedPreviewSalary,
    midpoint: targetRange.midpoint,
  });

  const newRangePenetration = computeRangePenetration({
    amount: convertedPreviewSalary,
    min: targetRange.min,
    max: targetRange.max,
  });

  return { newCompaRatio, newRangePenetration };
};

import { ExternalEmployeeSource, HrisSyncCampaignStatus, HrisSyncStatus, IntegrationSource } from "@prisma/client";
import { mixed, number, object, string } from "yup";
import { type AppContext } from "~/lib/context";
import {
  notifyHrisSyncCampaignFinished,
  notifyHrisSyncFailedDueToError,
  notifyHrisSyncFailedDueToFailedEmployees,
} from "~/lib/external/slack/notifications";
import { sumBy } from "~/lib/lodash";
import { logInfo, logWarn } from "~/lib/logger";
import { makeSingletonKey } from "~/lib/makeSingletonKey";
import { BaseJobDataSchema } from "~/lib/queue/baseJobDataSchema";
import { filterValidRowsForSchema, jobCacheTransaction } from "~/lib/queue/queueJobCache";
import { QueueJobName } from "~/lib/queue/queueJobName";
import { sendJob } from "~/lib/queue/sendJob";
import { isIn, type YupOutputType } from "~/lib/utils";
import {
  postSyncExternalEmployees,
  prepareAndSyncHierarchicalRelationships,
  updateIntegrationCounters,
} from "~/services/synchronization/postSyncExternalEmployees";
import { integrationSettingsForSyncInclude } from "~/services/synchronization/syncExternalEmployees";

const PostSyncExternalEmployeesJobDataSchema = BaseJobDataSchema.concat(object({ hrisSyncId: number().required() }));

export const PostSyncExternalEmployeesDataSchema = object({
  source: mixed<IntegrationSource>().oneOf(Object.values(IntegrationSource)).required(),
  deleted: number(),
  created: number(),
  updated: number(),
  externalEmployeeId: number(),
  managerExternalId: string(),
});

export const PostSyncExternalEmployeesDeletesReportDataSchema = object({
  source: mixed<IntegrationSource>().oneOf(Object.values(IntegrationSource)).required(),
  deleted: number().required(),
});

export const PostSyncExternalEmployeesUpdatesReportDataSchema = object({
  source: mixed<IntegrationSource>().oneOf(Object.values(IntegrationSource)).required(),
  created: number().required(),
  updated: number().required(),
});

export const PostSyncExternalEmployeesHierarchyDataSchema = object({
  source: mixed<ExternalEmployeeSource>().oneOf(Object.values(ExternalEmployeeSource)).required(),
  externalEmployeeId: number().required(),
  managerExternalId: string().nullable().required(),
});

export type PostSyncExternalEmployeesJobData = YupOutputType<typeof PostSyncExternalEmployeesJobDataSchema>;

const processDataRows = (data: YupOutputType<typeof PostSyncExternalEmployeesDataSchema>[]) => {
  return {
    deletes: filterValidRowsForSchema(data, PostSyncExternalEmployeesDeletesReportDataSchema),
    updates: filterValidRowsForSchema(data, PostSyncExternalEmployeesUpdatesReportDataSchema),
    hierarchy: filterValidRowsForSchema(data, PostSyncExternalEmployeesHierarchyDataSchema),
  };
};

export const postSyncExternalEmployeesWorkerService = async (
  ctx: AppContext,
  data: PostSyncExternalEmployeesJobData
) => {
  const { companyId, hrisSyncId } = PostSyncExternalEmployeesJobDataSchema.validateSync(data, {
    abortEarly: false,
  });

  const company = await ctx.prisma.company.findUniqueOrThrow({
    where: { id: companyId },
    include: {
      integrationSettings: {
        where: { enabled: true },
        include: integrationSettingsForSyncInclude,
      },
      defaultCountry: true,
    },
  });

  const hrisSync = await ctx.prisma.hrisSync.findUniqueOrThrow({
    where: { id: hrisSyncId },
    include: { campaign: true },
  });

  if (hrisSync.processedEmployeeCount !== hrisSync.totalEmployeeCount) {
    logWarn(ctx, "[post-sync-external-employees] Discrepancy in processed employees", {
      companyId: company.id,
      hrisSyncId: hrisSync.id,
      totalEmployeeCount: hrisSync.totalEmployeeCount,
      processedEmployeeCount: hrisSync.processedEmployeeCount,
      ...(hrisSync.campaignId && { hrisSyncCampaignId: hrisSync.campaignId }),
    });
  }

  try {
    await jobCacheTransaction(
      ctx,
      {
        companyId: company.id,
        queueJobName: QueueJobName.POST_SYNC_EXTERNAL_EMPLOYEES,
        validationSchema: PostSyncExternalEmployeesDataSchema,
      },
      async (ctx, data) => {
        const { updates, deletes, hierarchy } = processDataRows(data);

        await updateIntegrationCounters(ctx, { company, updates, deletes });

        await prepareAndSyncHierarchicalRelationships(ctx, hierarchy, company);
      }
    );

    await postSyncExternalEmployees(ctx, { company });
  } catch (error) {
    logWarn(ctx, "[post-sync-external-employees] Failed to complete HRIS Sync", {
      error,
      companyId: company.id,
      hrisSyncId: hrisSync.id,
      ...(hrisSync.campaignId && { hrisSyncCampaignId: hrisSync.campaignId }),
    });

    await ctx.prisma.hrisSync.update({
      where: { id: hrisSync.id },
      data: {
        status: HrisSyncStatus.FAILURE,
      },
    });

    await notifyHrisSyncFailedDueToError(ctx, {
      error,
      company,
      hrisSyncId: hrisSync.id,
      hrisSyncCampaignId: hrisSync.campaignId,
      campaignMessageTimestamp: hrisSync.campaign?.messageTimestamp,
    });

    if (!!hrisSync.campaignId) {
      await checkIfHrisSyncCampaignCompletedAndNotify(ctx, { hrisSyncCampaignId: hrisSync.campaignId });
    }

    return;
  }

  const isHrisSyncFailingDueToFailedEmployees = hrisSync.failedEmployeeNumbers.length > 0;

  await ctx.prisma.hrisSync.update({
    where: { id: hrisSync.id },
    data: {
      status: isHrisSyncFailingDueToFailedEmployees ? HrisSyncStatus.FAILURE : HrisSyncStatus.SUCCESS,
    },
  });

  if (isHrisSyncFailingDueToFailedEmployees) {
    logWarn(ctx, "[post-sync-external-employees] HRIS Sync Failed", {
      companyId: company.id,
      hrisSyncId: hrisSync.id,
      failedEmployeeNumberCount: hrisSync.failedEmployeeNumbers.length,
      failedEmployeeNumbers: hrisSync.failedEmployeeNumbers,
      ...(hrisSync.campaignId && { hrisSyncCampaignId: hrisSync.campaignId }),
    });

    await notifyHrisSyncFailedDueToFailedEmployees(ctx, {
      company,
      hrisSyncId: hrisSync.id,
      hrisSyncCampaignId: hrisSync.campaignId,
      failedEmployeeNumbers: hrisSync.failedEmployeeNumbers,
      campaignMessageTimestamp: hrisSync.campaign?.messageTimestamp,
    });
  }

  if (!!hrisSync.campaignId) {
    await checkIfHrisSyncCampaignCompletedAndNotify(ctx, { hrisSyncCampaignId: hrisSync.campaignId });
  }
};

const checkIfHrisSyncCampaignCompletedAndNotify = async (ctx: AppContext, params: { hrisSyncCampaignId: number }) => {
  const hrisSyncCampaign = await ctx.prisma.hrisSyncCampaign.findUniqueOrThrow({
    where: { id: params.hrisSyncCampaignId },
    include: { hrisSyncs: true },
  });

  if (hrisSyncCampaign.status === HrisSyncCampaignStatus.COMPLETED) {
    return;
  }

  const areAllSyncsCompleted = hrisSyncCampaign.hrisSyncs.every(({ status }) =>
    isIn(status, [HrisSyncStatus.SUCCESS, HrisSyncStatus.FAILURE])
  );

  if (!areAllSyncsCompleted) {
    return;
  }

  await ctx.prisma.hrisSyncCampaign.update({
    where: { id: params.hrisSyncCampaignId },
    data: { status: HrisSyncCampaignStatus.COMPLETED },
  });

  const failedSyncs = hrisSyncCampaign.hrisSyncs.filter(({ status }) => status === HrisSyncStatus.FAILURE);
  const didAtLeastOneSyncFail = failedSyncs.length > 0;

  await notifyHrisSyncCampaignFinished(ctx, {
    isSuccessful: !didAtLeastOneSyncFail,
    hrisSyncCampaignId: hrisSyncCampaign.id,
    totalFailedSyncs: failedSyncs.length,
    totalFailedEmployeeCount: sumBy(hrisSyncCampaign.hrisSyncs, (hrisSync) => hrisSync.failedEmployeeNumbers.length),
    totalProcessedEmployeeCount: sumBy(hrisSyncCampaign.hrisSyncs, (hrisSync) => hrisSync.processedEmployeeCount),
    campaignMessageTimestamp: hrisSyncCampaign.messageTimestamp,
  });

  if (didAtLeastOneSyncFail) {
    logWarn(ctx, "[post-sync-external-employees] HRIS Sync Campaign Failed", {
      hrisSyncCampaignId: params.hrisSyncCampaignId,
    });

    return;
  }

  logInfo(ctx, "[post-sync-external-employees] HRIS Sync Campaign Successful", {
    hrisSyncCampaignId: params.hrisSyncCampaignId,
  });
};

export const sendPostSyncExternalEmployeesJob = async (ctx: AppContext, data: PostSyncExternalEmployeesJobData) => {
  const job = await sendJob(ctx, {
    jobName: QueueJobName.POST_SYNC_EXTERNAL_EMPLOYEES,
    data,
    options: { singletonKey: makeSingletonKey({ companyId: data.companyId }, { unique: true }), retryLimit: 0 },
  });

  if (job) {
    await ctx.prisma.hrisSync.update({
      where: { id: data.hrisSyncId },
      data: {
        queueJobIds: { push: job.id },
      },
    });
  }
};

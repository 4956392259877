import { type AppContext } from "~/lib/context";
import { transaction } from "~/lib/transaction";

export const deleteCampaign = async (ctx: AppContext, params: { campaignId: number }) => {
  return transaction(
    ctx,
    async ({ prisma }) => {
      await prisma.compensationReviewReviewerBudgetPermission.deleteMany({
        where: { campaignId: params.campaignId },
      });

      await prisma.compensationReviewAdjustment.deleteMany({
        where: { campaignId: params.campaignId },
      });

      await prisma.compensationReviewBudgetEligibility.deleteMany({
        where: { campaignId: params.campaignId },
      });

      await prisma.compensationReviewRecommendation.deleteMany({
        where: { campaignId: params.campaignId },
      });

      await prisma.compensationReviewCampaign.update({
        where: { id: params.campaignId },
        data: { finalReviewerId: null, currencyId: null },
      });

      await prisma.compensationReviewAdmin.deleteMany({
        where: { campaignId: params.campaignId },
      });

      await prisma.compensationReviewEmployee.deleteMany({
        where: { campaignId: params.campaignId },
      });

      await prisma.compensationReviewReviewer.deleteMany({
        where: { campaignId: params.campaignId },
      });

      await prisma.compensationReviewCurrency.deleteMany({
        where: { campaignId: params.campaignId },
      });

      await prisma.compensationReviewIncreaseRule.deleteMany({
        where: { campaignId: params.campaignId },
      });

      await prisma.compensationReviewSubBudget.deleteMany({
        where: { campaignId: params.campaignId },
      });

      await prisma.compensationReviewBudget.deleteMany({
        where: { campaign: { id: params.campaignId } },
      });

      await prisma.compensationReviewCampaign.delete({
        where: { id: params.campaignId },
      });
    },
    {
      maxWait: 60_000,
      timeout: 60_000,
    }
  );
};

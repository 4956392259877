import {
  type CompensationReviewBudgetAdjustmentCriteria,
  EmployeeMarketPositioning,
  SalaryRangeEmployeeRangePositioning,
} from "@prisma/client";
import interpolate from "color-interpolate";
import { match } from "ts-pattern";
import { value } from "~/components/helpers";
import { isNil, max, min, sumBy } from "~/lib/lodash";
import { theme } from "~/lib/theme";
import { type FetchCampaignRecommendationDistributionResult } from "~/services/compensation-review/campaigns/admin/fetchCampaignRecommendationDistribution";
import {
  type CompensationReviewCampaignBudget,
  type FetchCampaignResult,
} from "~/services/compensation-review/campaigns/fetchCampaign";

const interpolateColor = interpolate(["white", theme.colors.primary[400]]);

export const computeRecommendationMatrix = (params: {
  campaign: FetchCampaignResult;
  subBudget: CompensationReviewCampaignBudget["subBudgets"][number];
  recommendationDistribution: FetchCampaignRecommendationDistributionResult;
  adjustmentCriteria: CompensationReviewBudgetAdjustmentCriteria;
  recommendationsAllocation: number;
  performanceRewardFactor: number;
  adjustmentFactor: number;
  skippedPerformanceRatingIds: number[];
  skippedPositionings: string[];
}) => {
  const totalBudget = (params.subBudget.amount ?? 0) * params.recommendationsAllocation;

  const baselineRatingIndex =
    params.recommendationDistribution.items.length > 1
      ? Math.floor(params.recommendationDistribution.items.length / 2)
      : 1;

  const computePerformanceIncreaseFactor = (ratingIndex: number) => {
    const step = 0.8 * params.performanceRewardFactor;

    const computeIncrement = (distanceFromBaseline: number): number => {
      return distanceFromBaseline ? step * distanceFromBaseline + computeIncrement(distanceFromBaseline - 1) : 0;
    };

    const diff = Math.abs(ratingIndex - baselineRatingIndex);
    const sign = ratingIndex > baselineRatingIndex ? -1 : 1;
    const incr = computeIncrement(diff) * sign;

    return Math.max(0, baselineRatingIndex + incr);
  };

  const unadjustedMatrix = params.recommendationDistribution.items.map((row, rowIndex) => {
    const isSkipped = params.skippedPerformanceRatingIds.includes(row.rating.id);
    const performanceIncreaseFactor = isSkipped ? 0 : computePerformanceIncreaseFactor(rowIndex);

    return {
      ...row,
      columns: row.columns.map((cell) => {
        if (!cell.employeesCount) {
          return {
            ...cell,
            increaseFactor: 0,
            targetBudgetRatio: 0,
          };
        }

        const employeesRatio = cell.employeesCount / params.recommendationDistribution.employeesCount;

        const adjustmentIncreaseFactor = params.skippedPositionings.includes(cell.positioning)
          ? 0
          : computeAdjustmentIncreaseFactor(cell.positioning, params.adjustmentFactor);

        const increaseFactor =
          (performanceIncreaseFactor * adjustmentIncreaseFactor * cell.salaryMass * cell.employeesCount) /
          employeesRatio;
        const targetBudgetRatio = totalBudget / cell.salaryMass;

        return {
          ...cell,
          increaseFactor,
          targetBudgetRatio,
        };
      }),
    };
  });

  const unadjustedIncreasesSum = sumBy(unadjustedMatrix, (row) => sumBy(row.columns, (cell) => cell.increaseFactor));

  const adjustedMatrix = unadjustedMatrix.map((row) => {
    return {
      ...row,
      columns: row.columns.map((cell) => {
        const recommendationPercentage = unadjustedIncreasesSum
          ? (cell.increaseFactor / unadjustedIncreasesSum) * cell.targetBudgetRatio
          : 0;

        return {
          ...cell,
          recommendationPercentage,
        };
      }),
    };
  });

  const getColor = value(() => {
    const minValue = min(adjustedMatrix.flatMap((row) => row.columns.map((cell) => cell.recommendationPercentage)));
    const maxValue = max(adjustedMatrix.flatMap((row) => row.columns.map((cell) => cell.recommendationPercentage)));

    return (value: number) => {
      if (maxValue === minValue || isNil(minValue) || isNil(maxValue)) {
        return "transparent";
      }

      const index = (value - minValue) / (maxValue - minValue);

      return interpolateColor(index);
    };
  });

  return adjustedMatrix.map((row) => ({
    ...row,
    columns: row.columns.map((cell) => ({
      ...cell,
      color: getColor(cell.recommendationPercentage),
    })),
  }));
};

const computeAdjustmentIncreaseFactor = (
  positioning: SalaryRangeEmployeeRangePositioning | EmployeeMarketPositioning,
  factor: number
) => {
  const baseFactor = match(positioning)
    .with(SalaryRangeEmployeeRangePositioning.ABOVE_RANGE, () => 0.3)
    .with(SalaryRangeEmployeeRangePositioning.TOP_RANGE, () => 0.5)
    .with(SalaryRangeEmployeeRangePositioning.TOP_MID_RANGE, () => 0.75)
    .with(SalaryRangeEmployeeRangePositioning.MID_RANGE, () => 1)
    .with(SalaryRangeEmployeeRangePositioning.BOTTOM_MID_RANGE, () => 1.25)
    .with(SalaryRangeEmployeeRangePositioning.BOTTOM_RANGE, () => 1.5)
    .with(SalaryRangeEmployeeRangePositioning.BELOW_RANGE, () => 3)
    .with(EmployeeMarketPositioning.WAY_ABOVE, () => 0.3)
    .with(EmployeeMarketPositioning.ABOVE, () => 0.5)
    .with(EmployeeMarketPositioning.ON_TARGET, () => 1)
    .with(EmployeeMarketPositioning.BELOW, () => 1.5)
    .with(EmployeeMarketPositioning.WAY_BELOW, () => 3)
    .exhaustive();

  return baseFactor ** factor;
};

import tracer from "dd-trace";
import { getIronSession } from "iron-session";
import { getCookieConfig } from "~/config";
import { type MiddlewareContext, next } from "~/lib/middleware/middleware";
import { getFromSession, SessionKey, validateSession } from "~/lib/session";

export const parseSessionCookies = async (ctx: MiddlewareContext) => {
  if (process.env.IS_JEST) return next(ctx);

  ctx.req.session = await getIronSession(ctx.req, ctx.res, getCookieConfig());

  const userId = getFromSession(ctx.req, { key: SessionKey.USER_ID });

  if (userId) {
    // This makes no sense but fixes a weird compilation issue.
    if (typeof window === "undefined") {
      tracer.setUser({ id: userId });
    }

    await validateSession(ctx.req, userId);
  }

  return next(ctx);
};

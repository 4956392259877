/**
 * Everything you need to know (and more !) about integrations is in Thee Holy Bible :
 * https://www.notion.so/figures-hr/Integrations-Bible-e574387e1c64417082dd90f34f6c4334
 */

import {
  type Company,
  type Currency,
  ExternalEmployeeStatus,
  ExternalRemunerationStatus,
  ExternalRemunerationType,
  Gender,
  IntegrationSource,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { isBefore, parseISO } from "date-fns";
import { type ParsedUrlQueryInput } from "querystring";
import { match } from "ts-pattern";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { fetch } from "~/lib/fetch";
import { buildCustomBaseSalaryRemunerationItem } from "~/lib/hris/helpers/buildCustomBaseSalaryRemunerationItem";
import { computeAdditionalFieldValuePayloads } from "~/lib/hris/helpers/computeAdditionalFieldValuePayloads";
import { getMissingCustomFields, type IntegrationCustomFields } from "~/lib/hris/helpers/getMissingCustomFields";
import { getNumberOfMonth } from "~/lib/hris/helpers/getNumberOfMonth";
import { getUploadedEmployeeProfilePicture } from "~/lib/hris/helpers/getUploadedEmployeeProfilePicture";
import { mapCustomRemunerationItem } from "~/lib/hris/helpers/mapCustomRemunerationItem";
import { type StaticModels } from "~/lib/integration";
import { chain, compact, isArray, omit } from "~/lib/lodash";
import { logWarn } from "~/lib/logger";
import { convertCurrency } from "~/lib/money";
import { buildExternalUrl } from "~/lib/url";
import { assertProps, isIn } from "~/lib/utils";
import { type IntegrationDiagnostic } from "~/services/synchronization/fetchCompanyIntegrationDiagnostics";
import { type EmployeeData, type IntegrationSettingsForSync } from "~/services/synchronization/syncExternalEmployees";

type Credentials = {
  clientSecret: string;
  anonymous: boolean;
};

export type HumaansEmployee = {
  id: string;
  employeeId?: string;

  firstName?: string;
  lastName?: string;
  email?: string;
  birthday?: string;
  employmentStartDate: string;
  gender: "Female" | "Male";

  locationId: "remote" | string;
  remoteCountryCode: string;
  remoteCity: string;

  // Other size variants of the profile picture are available, check the dump in /tmp/dumps
  profilePhoto?: {
    variants: {
      156: string;
    };
  };

  status: "active" | "offboarded" | "newHire";
};

export type HumaansJob = {
  id: string;
  personId: string;
  jobTitle: string;
  reportingTo: string;
};

export type HumaansLocation = {
  id: string;
  city: string;
  countryCode: string;
};

// noinspection JSUnusedGlobalSymbols
export const enum HumaansPeriod {
  ANNUAL = "annual",
  QUARTERLY = "quarterly",
  MONTHLY = "monthly",
  BIWEEKLY = "biweekly",
  WEEKLY = "weekly",
  DAILY = "daily",
  HOURLY = "hourly",
  FIXED = "fixed",
  SALE = "sale",
}

export type HumaansCompensation = {
  id: string;
  type: "salary" | "bonus" | "commission" | "equity";
  personId: string;
  amount: string;
  currency: string;
  mappedCurrency: Currency;
  period: HumaansPeriod;
  endDate: string | null;
  effectiveDate: string;
};

type BonusType = Exclude<HumaansCompensation["type"], "equity" | "salary">;

export type CompleteHumaansEmployee = HumaansEmployee & {
  job?: HumaansJob;
  location?: HumaansLocation;
  mappedCurrency: Currency;
  compensation: HumaansCompensation[];
  historicalFixedSalaries: HumaansCompensation[];
  customValues?: HumaansCustomValue[];
};

export type HumaansCustomField = {
  id: string;
  name: string;
  type: "text" | "longText" | "select" | "multiSelect" | "date" | "person" | "link";
};

type HumaansResponse<T> = {
  total: number;
  limit: number;
  skip: number;
  data: T[];
};

export const humaansFetch = async <T>(
  credentials: Credentials,
  endpoint: string,
  {
    method = "GET",
    query,
    body,
    paginate = true,
  }: {
    method?: "POST" | "GET";
    query?: ParsedUrlQueryInput;
    body?: Record<string, unknown>;
    paginate?: boolean;
  } = {}
): Promise<T[]> => {
  const baseUrl = `https://app.humaans.io/api/${endpoint}`;

  const res = await fetch(buildExternalUrl(baseUrl, query), {
    method,
    headers: {
      "Accept": "application/json",
      "Authorization": `Bearer ${credentials.clientSecret}`,
      "Content-Type": "application/json",
    },
    ...(body && { body: JSON.stringify(body) }),
  });

  if (!res.ok) {
    const { message } = await res.json();
    throw new Error(`[humaans] ${res.status} ${res.statusText} : ${message}`);
  }

  const json: HumaansResponse<T> = await res.json();

  // If we have reached the end of paginated data just return what we have
  if (json.data.length < json.limit || !paginate) {
    return json.data;
  }

  const nextPage = await humaansFetch<T>(credentials, endpoint, {
    method,
    body,
    query: {
      ...query,
      $skip: json.skip + json.limit,
      $limit: json.limit,
    },
  });

  return [...json.data, ...nextPage];
};

const getHumaansProfilePicture = async (
  ctx: AppContext,
  options: { integrationSettings: SafeIntegrationSettings; apiEmployee: CompleteHumaansEmployee }
) => {
  if (!options.apiEmployee.profilePhoto?.variants["156"]) {
    return undefined;
  }

  return getUploadedEmployeeProfilePicture(ctx, {
    apiEmployeeId: options.apiEmployee.id,
    source: IntegrationSource.HUMAANS,
    integrationSettings: options.integrationSettings,
    fetch: () =>
      fetch(options.apiEmployee.profilePhoto?.variants["156"] as string, {
        headers: {
          "User-Agent": "Figures-Integration-Bot",
        },
      }),
  });
};

const getCustomField = (apiEmployee: CompleteHumaansEmployee, fieldId: string | null): string | null => {
  if (!isArray(apiEmployee.customValues) || !fieldId) {
    return null;
  }

  const customField = apiEmployee.customValues.find((value) => value.customFieldId === fieldId);

  if (!customField) {
    return null;
  }

  if (isArray(customField.value)) {
    return customField.value[0];
  }

  return customField.value.toString();
};

export const mapHumaansEmployee = async (
  ctx: AppContext,
  company: Company,
  apiEmployee: CompleteHumaansEmployee,
  integrationSettings: SafeIntegrationSettings,
  staticModels: StaticModels,
  ignoreProfilePicture?: boolean
): Promise<EmployeeData> => {
  // Handle custom fields
  const {
    fteCustomFieldName,
    levelCustomFieldName,
    externalIdCustomFieldName,
    holidayAllowanceCustomFieldName,
    businessUnitCustomFieldName,
    baseSalaryCustomFieldName,
    baseSalaryCustomFieldFrequency,
    locationCustomFieldName,
    jobCustomFieldName,
    additionalFieldMappings = [],
    customRemunerationItemMappings = [],
  } = integrationSettings;
  const customFte = getCustomField(apiEmployee, fteCustomFieldName);
  const customLevel = getCustomField(apiEmployee, levelCustomFieldName);
  const customEmployeeNumber = getCustomField(apiEmployee, externalIdCustomFieldName);
  const customBaseSalary = getCustomField(apiEmployee, baseSalaryCustomFieldName);
  const holidayAllowanceValue = getCustomField(apiEmployee, holidayAllowanceCustomFieldName);
  const businessUnit = getCustomField(apiEmployee, businessUnitCustomFieldName);
  const customLocation = getCustomField(apiEmployee, locationCustomFieldName);
  const customJob = getCustomField(apiEmployee, jobCustomFieldName);

  const additionalFieldValues = additionalFieldMappings.map(({ hrisFieldName, additionalFieldId, id }) => ({
    additionalFieldMappingId: id,
    additionalFieldId,
    value: getCustomField(apiEmployee, hrisFieldName),
  }));

  const customRemunerationItemsValues = customRemunerationItemMappings.map((customRemunerationItemMapping) => ({
    ...customRemunerationItemMapping,
    value: getCustomField(apiEmployee, customRemunerationItemMapping.hrisFieldName),
  }));

  // Humaans locations are pretty trustworthy with city and country name so we try to map it directly
  const mappedLocation = await value(() => {
    // If the employee is marked as remote search for a remote location in their country
    if (apiEmployee.locationId === "remote") {
      if (!apiEmployee.remoteCountryCode) {
        return null;
      }

      const country = staticModels.countries.find((country) => country.alpha2 === apiEmployee.remoteCountryCode);
      if (!country) {
        logWarn(ctx, "[humaans] Unhandled country code", {
          company: omit(company, ["humaansIntegrationSettings"]),
          countryCode: apiEmployee.remoteCountryCode,
        });
        return null;
      }

      return ctx.prisma.employeeLocation.findFirst({
        where: {
          isRemote: true,
          countryId: country?.id,
        },
      });
    }
    if (!apiEmployee.location?.city || !apiEmployee.location?.countryCode) {
      return null;
    }
    // Employee is not remote, try to find their location from the city name and country code
    const country = staticModels.countries.find((country) => country.alpha2 === apiEmployee.location?.countryCode);

    if (!country) {
      logWarn(ctx, "[humaans] Unhandled country code", {
        company: omit(company, ["humaansIntegrationSettings"]),
        countryCode: apiEmployee.remoteCountryCode,
      });
      return null;
    }

    return ctx.prisma.employeeLocation.findFirst({
      where: { name: { contains: apiEmployee.location.city }, countryId: country.id },
    });
  });

  const job = value(() => {
    const jobTitle = customJob ?? apiEmployee.job?.jobTitle;

    if (!jobTitle) return;

    return {
      job: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: jobTitle,
            },
          },
          create: {
            name: jobTitle,
            externalId: jobTitle,
            company: {
              connect: { id: company.id },
            },
          },
        },
      },
    };
  });

  const externalLocation = value(() => {
    if (customLocation) {
      return {
        location: {
          connectOrCreate: {
            where: {
              companyId_externalId: {
                companyId: company.id,
                externalId: customLocation,
              },
            },
            create: {
              externalId: customLocation,
              name: customLocation,
              autoMappingEnabled: true,
              company: {
                connect: { id: company.id },
              },
            },
          },
        },
      };
    }

    if (apiEmployee.locationId === "remote" && !!apiEmployee.remoteCountryCode) {
      return {
        location: {
          connectOrCreate: {
            where: {
              companyId_externalId: {
                companyId: company.id,
                externalId: `remote-${apiEmployee.remoteCountryCode}`,
              },
            },
            create: {
              externalId: `remote-${apiEmployee.remoteCountryCode}`,
              name: `Remote ${apiEmployee.remoteCountryCode}`,
              autoMappingEnabled: true,
              company: {
                connect: { id: company.id },
              },
              country: {
                connect: { alpha2: apiEmployee.remoteCountryCode },
              },
              ...(!!mappedLocation?.id && {
                mappedLocation: { connect: { id: mappedLocation.id } },
              }),
            },
          },
        },
      };
    }

    if (apiEmployee.locationId !== "remote" && !!apiEmployee.location) {
      return {
        location: {
          connectOrCreate: {
            where: {
              companyId_externalId: {
                companyId: company.id,
                externalId: apiEmployee.location.id,
              },
            },
            create: {
              externalId: apiEmployee.location.id,
              name: `${apiEmployee.location.city} ${apiEmployee.location.countryCode}`,
              autoMappingEnabled: true,
              company: {
                connect: { id: company.id },
              },
              country: {
                connect: { alpha2: apiEmployee.location.countryCode },
              },
              ...(!!mappedLocation?.id && {
                mappedLocation: { connect: { id: mappedLocation.id } },
              }),
            },
          },
        },
      };
    }
  });

  const employeeNumber = value(() => {
    if (customEmployeeNumber) {
      return customEmployeeNumber;
    }
    return apiEmployee.employeeId || apiEmployee.id;
  });

  const input: EmployeeData["input"] = {
    source: IntegrationSource.HUMAANS,
    externalId: apiEmployee.id,
    status: ExternalEmployeeStatus.UNMAPPED,
    firstName: apiEmployee.firstName,
    lastName: apiEmployee.lastName,
    email: apiEmployee.email,
    employeeNumber,
    gender: value(() => {
      if (!apiEmployee.gender) {
        return null;
      }
      if (apiEmployee.gender === "Female") {
        return Gender.FEMALE;
      }
      if (apiEmployee.gender === "Male") {
        return Gender.MALE;
      }
      return Gender.UNDISCLOSED;
    }),
    hireDate: parseISO(apiEmployee.employmentStartDate),
    birthDate: apiEmployee.birthday ? parseISO(apiEmployee.birthday) : null,
    company: {
      connect: { id: company.id },
    },
    currency: {
      connect: { id: apiEmployee.mappedCurrency.id },
    },
    ...externalLocation,
    ...job,

    ...(customLevel && {
      level: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: customLevel,
            },
          },
          create: {
            externalId: customLevel,
            name: customLevel,
            company: {
              connect: { id: company.id },
            },
          },
        },
      },
    }),
    ...(businessUnit && { businessUnit }),
  };

  const numberMonths = getNumberOfMonth({
    externalId: externalLocation?.location?.connectOrCreate?.create?.externalId,
    additionalMonthRules: staticModels.additionalMonthRules,
    externalLocations: staticModels.externalLocations,
  });

  const customBaseSalaryRemunerationItem = buildCustomBaseSalaryRemunerationItem({
    customBaseSalary,
    baseSalaryCustomFieldFrequency,
    numberMonths,
    company,
    source: IntegrationSource.HUMAANS,
  });

  const baseSalaries = customBaseSalaryRemunerationItem
    ? [customBaseSalaryRemunerationItem]
    : apiEmployee.compensation
        .filter((compensation) => compensation.type === "salary")
        .map((compensation) =>
          mapHumaansBaseFalary(ctx, {
            company,
            compensation,
            externalRemunerationStatus: ExternalRemunerationStatus.LIVE,
            defaultCurrency: apiEmployee.mappedCurrency,
            numberMonths,
          })
        );

  const remunerationItems: EmployeeData["remunerationItems"] = compact([
    ...baseSalaries,

    // Bonuses
    ...apiEmployee.compensation
      .filter((compensation) => compensation.type !== "salary")
      .map((compensation) =>
        mapHumaansBonus(ctx, {
          company,
          compensation,
          defaultCurrency: apiEmployee.mappedCurrency,
        })
      ),

    // Historical fixed salaries
    ...apiEmployee.historicalFixedSalaries.map((compensation) =>
      mapHumaansBaseFalary(ctx, {
        company,
        compensation,
        externalRemunerationStatus: ExternalRemunerationStatus.HISTORICAL,
        defaultCurrency: apiEmployee.mappedCurrency,
        numberMonths,
      })
    ),
  ]);

  if (customRemunerationItemsValues.length > 0) {
    const customItems = compact(
      customRemunerationItemsValues.map((customRemunerationItem) =>
        mapCustomRemunerationItem(integrationSettings, customRemunerationItem)
      )
    );
    remunerationItems.push(...customItems);
  }

  return {
    input,
    profilePicture: !ignoreProfilePicture
      ? await getHumaansProfilePicture(ctx, { integrationSettings, apiEmployee })
      : undefined,
    remunerationItems,
    additionalFieldValues: computeAdditionalFieldValuePayloads(company, additionalFieldValues),
    managerExternalId: apiEmployee.job?.reportingTo,
    holidayAllowanceValue,
    ...(customFte && {
      fte: customFte,
      ignoreFte: !!apiEmployee.compensation?.find(({ period }) => period === HumaansPeriod.HOURLY),
    }),
  };
};

const mapHumaansBaseFalary = (
  ctx: AppContext,
  params: {
    company: Company;
    compensation: HumaansCompensation;
    externalRemunerationStatus: ExternalRemunerationStatus;
    defaultCurrency: Currency;
    numberMonths: number;
  }
): EmployeeData["remunerationItems"][number] | null => {
  const { company, compensation, externalRemunerationStatus, numberMonths, defaultCurrency } = params;

  // We currently do not handle percentage bonuses in Humaans.
  // Even though we technically could, this would create issues with some clients that misconfigured their Humaans configuration.
  // So it's an intentional choice that could be re-visited later if required.
  // @see https://linear.app/figures/issue/FIG-2409/huumans-error#comment-8fae6903
  if (compensation.amount.includes("%")) {
    return null;
  }

  const baseAmount = parseInt(compensation.amount);

  if (!baseAmount) {
    return null;
  }

  const interval = compensation.period;

  if (!isIn(interval, ["hourly", "annual", "quarterly", "monthly"])) {
    logWarn(ctx, `[humaans] Unhandled Humaans fix salary interval, treating as yearly`, { interval });
  }

  const numberMonthsMultiplier = numberMonths ? numberMonths / 12 : 1;

  const multiplier = value(() => {
    if (interval === "hourly") {
      return 40 * 52 * numberMonthsMultiplier;
    }

    if (interval === "monthly") {
      return 12 * numberMonthsMultiplier;
    }

    if (interval === "quarterly") {
      return 4 * numberMonthsMultiplier;
    }

    return 1;
  });

  // We want to convert all compensation items for this employee to their default currency
  const yearlySalary =
    Math.round(convertCurrency(baseAmount * multiplier, compensation.mappedCurrency, defaultCurrency)) * 100;

  const externalIdSuffix = match(externalRemunerationStatus)
    .with(ExternalRemunerationStatus.LIVE, () => "")
    .with(ExternalRemunerationStatus.HISTORICAL, () => `-historical-${compensation.id}`)
    .exhaustive();

  return {
    company: {
      connect: { id: company.id },
    },
    source: IntegrationSource.HUMAANS,
    externalId: `fix-salary${externalIdSuffix}`,
    amount: yearlySalary,
    numberMonths,
    date: parseISO(compensation.effectiveDate),
    status: externalRemunerationStatus,
    nature: {
      connectOrCreate: {
        where: {
          companyId_source_externalId: {
            companyId: company.id,
            source: IntegrationSource.HUMAANS,
            externalId: "fix-salary",
          },
        },
        create: {
          source: IntegrationSource.HUMAANS,
          externalId: "fix-salary",
          name: "Fixed salary",
          mappedType: ExternalRemunerationType.FIXED_SALARY,
          company: {
            connect: {
              id: company.id,
            },
          },
        },
      },
    },
  } satisfies EmployeeData["remunerationItems"][number];
};

const mapHumaansBonus = (
  ctx: AppContext,
  params: {
    company: Company;
    compensation: HumaansCompensation;
    defaultCurrency: Currency;
  }
): EmployeeData["remunerationItems"][number] | null => {
  const { company, compensation, defaultCurrency } = params;

  if (!isIn(compensation.type, ["commission", "bonus"])) {
    return null;
  }

  if (!!compensation.endDate && isBefore(parseISO(compensation.endDate), new Date())) {
    return null;
  }

  // We currently do not handle percentage bonuses in Humaans.
  // Even though we technically could, this would create issues with some clients that misconfigured their Humaans configuration.
  // So it's an intentional choice that could be re-visited later if required.
  // @see https://linear.app/figures/issue/FIG-2409/huumans-error#comment-8fae6903
  if (compensation.amount.includes("%")) {
    return null;
  }

  const baseAmount = parseInt(compensation.amount);

  if (!baseAmount) {
    return null;
  }

  const interval = compensation.period;

  if (!isIn(interval, ["hourly", "annual", "quarterly", "monthly"])) {
    logWarn(ctx, `[humaans] Unhandled Humaans bonus interval, treating as yearly`, { interval });
  }

  const multiplier = value(() => {
    if (interval === "hourly") {
      return 40 * 52;
    }

    if (interval === "monthly") {
      return 12;
    }

    if (interval === "quarterly") {
      return 4;
    }

    return 1;
  });

  // We want to convert all compensation items for this employee to their default currency
  const yearlySalary =
    Math.round(convertCurrency(baseAmount * multiplier, compensation.mappedCurrency, defaultCurrency)) * 100;

  const mappedType = match<BonusType, ExternalRemunerationType>(compensation.type as BonusType)
    .with("bonus", () => ExternalRemunerationType.FIXED_BONUS)
    .with("commission", () => ExternalRemunerationType.VARIABLE_BONUS)
    .exhaustive();

  return {
    company: {
      connect: { id: company.id },
    },
    source: IntegrationSource.HUMAANS,
    externalId: compensation.id,
    amount: yearlySalary,
    date: parseISO(compensation.effectiveDate),
    status: ExternalRemunerationStatus.LIVE,
    nature: {
      connectOrCreate: {
        where: {
          companyId_source_externalId: {
            companyId: company.id,
            source: IntegrationSource.HUMAANS,
            externalId: compensation.type,
          },
        },
        create: {
          source: IntegrationSource.HUMAANS,
          externalId: compensation.type,
          name: compensation.type,
          mappedType,
          company: {
            connect: {
              id: company.id,
            },
          },
        },
      },
    },
  } satisfies EmployeeData["remunerationItems"][number];
};

export const getHumaansCustomFields = async (credentials: Credentials): Promise<HumaansCustomField[]> => {
  const customFields = await humaansFetch<HumaansCustomField>(credentials, "custom-fields");

  return customFields.filter((field) => isIn(field.type, ["text", "longText", "select", "multiSelect"]));
};

const anonymise = (credentials: Credentials) => {
  return (employee: HumaansEmployee): HumaansEmployee => {
    if (credentials.anonymous) {
      delete employee.firstName;
      delete employee.lastName;
      delete employee.birthday;
      delete employee.profilePhoto;
      delete employee.email;
    }
    return employee;
  };
};

type HumaansIntegrationSettingsInput = Credentials & IntegrationCustomFields;

export type HumaansCustomValue = {
  personId: string;
  customFieldId: string;
  value: string;
};

const getBaseSalaryForEmployee = (compensations: HumaansCompensation[]) =>
  chain(compensations)
    .filter((compensation) => compensation.type === "salary")
    // Compensation is current (effective date is in the past)
    .filter((compensation) => isBefore(parseISO(compensation.effectiveDate), new Date()))
    .orderBy("effectiveDate", "desc")
    .value();

export const getHumaansEmployees = async (
  ctx: AppContext,
  company: Company,
  credentials: Credentials
): Promise<CompleteHumaansEmployee[]> => {
  // Get all relevant data from Humaans API
  const [employees, locations, jobs, compensations, customValues] = await Promise.all([
    humaansFetch<HumaansEmployee>(credentials, "people"),
    humaansFetch<HumaansLocation>(credentials, "locations"),
    humaansFetch<HumaansJob>(credentials, "job-roles"),
    humaansFetch<HumaansCompensation>(credentials, "compensations"),
    humaansFetch<HumaansCustomValue>(credentials, "custom-values"),
  ]);

  // Prefetch all the currency stuff
  const [countries, currencies] = await Promise.all([ctx.prisma.country.findMany(), ctx.prisma.currency.findMany()]);
  const euro = currencies.find((currency) => currency.code === "EUR") as Currency;
  const companyDefaultCurrency = value(() => {
    const defaultCountry = countries.find((country) => country.id === company.defaultCountryId);

    return currencies.find((currency) => currency.id === defaultCountry?.defaultCurrencyId) || euro;
  });

  return employees
    .filter((employee) => employee.status === "active")
    .map(anonymise(credentials))
    .map((employee) => {
      const location = locations.find((location) => location.id === employee.locationId);

      const employeeDefaultCountry = value(() => {
        if (employee.locationId === "remote") {
          return countries.find((country) => country.alpha2 === employee.remoteCountryCode);
        }
        return countries.find((country) => country.alpha2 === location?.countryCode);
      });
      const employeeDefaultCurrency =
        currencies.find((currency) => currency.id === employeeDefaultCountry?.defaultCurrencyId) ||
        companyDefaultCurrency;

      const employeeCompensations = compensations.filter((compensation) => compensation.personId === employee.id);

      const [fixedSalary, ...historicalFixedSalaries] = getBaseSalaryForEmployee(employeeCompensations);
      const otherRemunerationItems = employeeCompensations.filter((compensation) => compensation.type !== "salary");
      // take historical rem here
      return {
        ...employee,
        mappedCurrency: employeeDefaultCurrency,
        location,
        job: jobs.find((job) => job.personId === employee.id),
        customValues: customValues.filter((value) => value.personId === employee.id),
        historicalFixedSalaries: compact(
          historicalFixedSalaries.map((compensation) => ({
            ...compensation,
            mappedCurrency:
              currencies.find((currency) => currency.code === compensation.currency) || employeeDefaultCurrency,
          }))
        ),
        compensation: compact([fixedSalary, ...otherRemunerationItems]).map((compensation) => {
          return {
            ...compensation,
            mappedCurrency:
              currencies.find((currency) => currency.code === compensation.currency) || employeeDefaultCurrency,
          };
        }),
      };
    });
};

const assertSafeIntegrationSettings = (integrationSettings: IntegrationSettingsForSync) =>
  assertProps(integrationSettings, ["clientSecret"]);
export type SafeIntegrationSettings = ReturnType<typeof assertSafeIntegrationSettings>;

export const getMappedHumaansEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync,
  staticModels: StaticModels,
  ignoreProfilePicture = false
): Promise<EmployeeData[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  const humaansEmployees = await getHumaansEmployees(ctx, company, safeIntegrationSettings);

  return mapSeries(humaansEmployees, (humaansEmployee) =>
    mapHumaansEmployee(ctx, company, humaansEmployee, safeIntegrationSettings, staticModels, ignoreProfilePicture)
  );
};

export const getHumaansDiagnostic = async (
  ctx: AppContext,
  credentials: HumaansIntegrationSettingsInput
): Promise<IntegrationDiagnostic> => {
  try {
    const [employee] = await humaansFetch<HumaansEmployee>(credentials, "people", {
      paginate: false,
      query: {
        $limit: 1,
      },
    });

    if (!employee) {
      return {
        connection: false,
        connectionError: "We could not find any employees within your Humaans account",
        missingFields: [],
        availableFields: [],
      };
    }

    const availableFields = await getHumaansCustomFields(credentials);

    const missingFields = getMissingCustomFields(credentials, availableFields);

    return { connection: true, connectionError: "", missingFields, availableFields };
  } catch (error) {
    return { connection: false, connectionError: error.message, missingFields: [], availableFields: [] };
  }
};

export const getRawHumaansEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync
): Promise<HumaansEmployee[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  return getHumaansEmployees(ctx, company, safeIntegrationSettings);
};

import {
  CompensationReviewIncreaseRuleTarget,
  CompensationReviewIncreaseRuleType,
  CompensationReviewIncreaseRuleUnit,
  type Currency,
  type Prisma,
  SalaryGridMeasure,
} from "@prisma/client";
import { type TFunction } from "next-i18next";
import { match } from "ts-pattern";
import { chain, maxBy, minBy } from "~/lib/lodash";
import { convertCurrency } from "~/lib/money";
import { type CompensationReviewData } from "~/services/compensation-review/compensationReviewContext";
import {
  type BudgetRules,
  type EnrichIncreaseRulesWithPopulationResult,
} from "~/services/compensation-review/shared/rules/enrichIncreaseRulesWithPopulation";

type EmployeeForIncreaseRules = Prisma.CompensationReviewEmployeeGetPayload<{
  select: {
    id: true;
    isPromoted: true;
    baseSalary: true;
    onTargetEarnings: true;
    currency: {
      select: {
        euroExchangeRate: true;
      };
    };
    targetRangeAfterPromotion: {
      select: {
        min: true;
        max: true;
        midpoint: true;
        band: {
          select: {
            measure: true;
            currency: true;
          };
        };
      };
    };
  };
}> & {
  salaryRangeEmployee: {
    range: {
      min: number;
      max: number;
      midpoint: number;
      band: {
        measure: SalaryGridMeasure;
        currency: Currency;
      };
    };
  } | null;
};

export const computeIncreaseRules = (
  ctx: CompensationReviewData,
  params: {
    rules: EnrichIncreaseRulesWithPopulationResult;
    employee: EmployeeForIncreaseRules;
    skipPermissions?: boolean;
  }
) => {
  const { employee, rules, skipPermissions = false } = params;

  const baseSalaryInCampaignCurrency = convertCurrency(employee.baseSalary, employee.currency, ctx.parameters.currency);

  const onTargetEarningsInCampaignCurrency = convertCurrency(
    employee.onTargetEarnings,
    employee.currency,
    ctx.parameters.currency
  );

  const targetRange = employee.isPromoted ? employee.targetRangeAfterPromotion : employee.salaryRangeEmployee?.range;

  const comparedSalaryBandAmount = match(targetRange?.band.measure)
    .with(SalaryGridMeasure.BASE_SALARY, () => baseSalaryInCampaignCurrency)
    .with(SalaryGridMeasure.ON_TARGET_EARNINGS, () => onTargetEarningsInCampaignCurrency)
    .otherwise(() => null);

  const items = chain(rules)
    .filter((rule) => {
      if (rule.employeeIds === null) return true;

      return rule.employeeIds.includes(employee.id);
    })
    .filter((rule) => {
      return employee.isPromoted
        ? rule.target === CompensationReviewIncreaseRuleTarget.PROMOTED_EMPLOYEES
        : rule.target === CompensationReviewIncreaseRuleTarget.NON_PROMOTED_EMPLOYEES;
    })
    .map((rule) => {
      const targetAmount = match(rule.unit)
        .with(CompensationReviewIncreaseRuleUnit.AMOUNT, () => rule.amount)
        .with(CompensationReviewIncreaseRuleUnit.PERCENTAGE, () => onTargetEarningsInCampaignCurrency * rule.amount)
        .with(CompensationReviewIncreaseRuleUnit.COMPA_RATIO, () => {
          if (!targetRange || !comparedSalaryBandAmount) return null;

          return (
            convertCurrency(targetRange.midpoint, targetRange.band.currency, ctx.parameters.currency) * rule.amount -
            comparedSalaryBandAmount
          );
        })
        .with(CompensationReviewIncreaseRuleUnit.RANGE_PENETRATION, () => {
          if (!targetRange || !comparedSalaryBandAmount) return null;

          const min = convertCurrency(targetRange.min, targetRange.band.currency, ctx.parameters.currency);
          const max = convertCurrency(targetRange.max, targetRange.band.currency, ctx.parameters.currency);

          const targetAmount = min + (max - min) * rule.amount;

          return targetAmount - comparedSalaryBandAmount;
        })
        .exhaustive();

      if (targetAmount === null) return null;

      return {
        ...rule,
        targetAmount,
        minAmount: null,
        maxAmount: null,
        recommendationAmount: null,
        ...(rule.type === CompensationReviewIncreaseRuleType.MINIMUM_INCREASE && { minAmount: targetAmount }),
        ...(rule.type === CompensationReviewIncreaseRuleType.MAXIMUM_INCREASE && { maxAmount: targetAmount }),
        ...(rule.type === CompensationReviewIncreaseRuleType.PROMOTION_RECOMMENDATION && {
          recommendationAmount: targetAmount,
        }),
      };
    })
    .compact()
    .value();

  type Rule = (typeof items)[number];

  const hardMin = chain(items)
    .filter((rule) => !!rule.minAmount && rule.hardLimit)
    .maxBy((rule) => rule.minAmount)
    .value() as Rule | undefined;

  const hardMax = chain(items)
    .filter((rule) => !!rule.maxAmount && rule.hardLimit)
    .minBy((rule) => rule.maxAmount)
    .value() as Rule | undefined;

  const softMin = chain(items)
    .filter((rule) => !!rule.minAmount && !rule.hardLimit)
    .maxBy((rule) => rule.minAmount)
    .value() as Rule | undefined;

  const softMax = chain(items)
    .filter((rule) => !!rule.maxAmount && !rule.hardLimit)
    .minBy((rule) => rule.maxAmount)
    .value() as Rule | undefined;

  const overallMin = maxBy([hardMin, softMin], (rule) => rule?.minAmount);
  const overallMax = minBy([hardMax, softMax], (rule) => rule?.maxAmount);

  return {
    items,

    overallLimits: { min: overallMin, max: overallMax },

    isWithinSoftLimits: (value: number) => {
      if (overallMin?.minAmount && value < overallMin.minAmount) return false;
      if (overallMax?.maxAmount && value > overallMax.maxAmount) return false;

      return true;
    },

    isWithinHardLimits: (value: number) => {
      if (!skipPermissions && ctx.permissions.canBypassRules) return true;

      if (hardMin?.minAmount && value < hardMin.minAmount) return false;
      if (hardMax?.maxAmount && value > hardMax.maxAmount) return false;

      return true;
    },
  };
};

export type ComputeIncreaseRulesResult = ReturnType<typeof computeIncreaseRules>;

export const computeBudgetRules = (
  ctx: CompensationReviewData,
  params: { rules: BudgetRules[]; employee: EmployeeForIncreaseRules; skipPermissions?: boolean }
) => {
  return params.rules.map((rule) => {
    return {
      budgetId: rule.budgetId,
      ...computeIncreaseRules(ctx, {
        employee: params.employee,
        rules: rule.rules,
        skipPermissions: params.skipPermissions,
      }),
    };
  });
};

export type ComputedBudgetRules = ReturnType<typeof computeBudgetRules>[number];

export const formatUnit = (t: TFunction, unit: CompensationReviewIncreaseRuleUnit) => {
  return match(unit)
    .with(CompensationReviewIncreaseRuleUnit.AMOUNT, () => t("enum.compensation-review-increase-rule-unit.amount"))
    .with(CompensationReviewIncreaseRuleUnit.PERCENTAGE, () =>
      t("enum.compensation-review-increase-rule-unit.percentage")
    )
    .with(CompensationReviewIncreaseRuleUnit.COMPA_RATIO, () =>
      t("enum.compensation-review-increase-rule-unit.compa-ratio")
    )
    .with(CompensationReviewIncreaseRuleUnit.RANGE_PENETRATION, () =>
      t("enum.compensation-review-increase-rule-unit.range-penetration")
    )
    .exhaustive();
};

import { type Column } from "~/components/ui/core/Table";
import { compact, partition } from "~/lib/lodash";
import { arrayHasValues } from "~/lib/utils";
import { type TableColumnVisibilityKeys } from "~/pages/api/update-user-flag";
import { type NullableAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";

export const ACTIONS_COLUMN_ID = "actions";

type OrderedColumn = Pick<Column<unknown>, "id" | "visible">;

export const getColumnsOrder = <Entry>(
  user: NullableAuthenticatedUser,
  visibleColumnsKey: TableColumnVisibilityKeys | undefined,
  defaultColumns: Column<Entry>[]
): Column<Entry>[] => {
  if (!visibleColumnsKey || !user) return defaultColumns;

  const userPreferences = user.flags[visibleColumnsKey] as OrderedColumn[];
  const companyDefaults = user.company?.companySettings?.[visibleColumnsKey] as OrderedColumn[];

  const savedOrder = arrayHasValues(userPreferences)
    ? userPreferences
    : arrayHasValues(companyDefaults)
    ? companyDefaults
    : null;

  if (!savedOrder) return defaultColumns;

  const [existingColumns, newColumns] = partition(defaultColumns, (column) =>
    savedOrder.some((saved) => saved.id === column.id)
  );

  return compact([
    ...savedOrder.map((saved) => {
      const column = existingColumns.find((col) => col.id === saved.id);
      if (!column) return null;
      return {
        ...column,
        visible: saved.visible,
      };
    }),
    ...newColumns,
  ]);
};

/**
 * Everything you need to know (and more !) about integrations is in Thee Holy Bible :
 * https://www.notion.so/figures-hr/Integrations-Bible-e574387e1c64417082dd90f34f6c4334
 */

import {
  type Company,
  CompensationFrequency,
  ExternalEmployeeSource,
  ExternalEmployeeStatus,
  ExternalRemunerationStatus,
  ExternalRemunerationType,
  Gender,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { isAfter, isBefore, parseISO } from "date-fns";
import { match } from "ts-pattern";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { fetch } from "~/lib/fetch";
import { computeAdditionalFieldValuePayloads } from "~/lib/hris/helpers/computeAdditionalFieldValuePayloads";
import { type IntegrationCustomFields } from "~/lib/hris/helpers/getMissingCustomFields";
import { getNumberOfMonth } from "~/lib/hris/helpers/getNumberOfMonth";
import { type StaticModels } from "~/lib/integration";
import { chain, compact, groupBy, map, minBy } from "~/lib/lodash";
import { logWarn } from "~/lib/logger";
import { buildExternalUrl } from "~/lib/url";
import { assertProps, idIs, idIsIn, isIn } from "~/lib/utils";
import { type IntegrationDiagnostic } from "~/services/synchronization/fetchCompanyIntegrationDiagnostics";
import { type EmployeeData, type IntegrationSettingsForSync } from "~/services/synchronization/syncExternalEmployees";

type Credentials = {
  clientSecret: string;
  anonymous: boolean;
};

export type FactorialEmployee = {
  id: number;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  email?: string;
  birthday_on?: string;
  terminated_on: string | null;
  gender: "male" | "female";
  bank_number?: string;
  country?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  address_line_1?: string;
  address_line_2?: string;
  swift_bic?: string;
  legal_entity_id: number;
  manager_id: number;
  social_security_number?: string;
  phone_number?: string;
};

export type FactorialLegalEntity = {
  id: number;
  legal_name: string;
  city: string;
  country: string;
};

export type FactorialContract = {
  id: number;
  employee_id: number;

  role: string;
  level: string;

  effective_on: string;
  ends_on: string | null;
  starts_on: string;

  salary_amount?: number;
  salary_frequency: "daily" | "hourly" | "weekly" | "monthly" | "yearly";

  compensation_ids: number[];
};

const recurrenceMultipliers = {
  monthly: 12,
  every_2_months: 12 / 2,
  every_3_months: 12 / 3,
  every_4_months: 12 / 4,
  every_5_months: 12 / 5,
  every_6_months: 12 / 6,
  every_7_months: 12 / 7,
  every_8_months: 12 / 8,
  every_9_months: 12 / 9,
  every_10_months: 12 / 10,
  every_11_months: 12 / 11,
  every_12_months: 12 / 12,
} as const;

enum FactorialCompensationType {
  "FIXED" = "fixed",
  "UP_TO" = "up_to",
}

export type FactorialCompensation = {
  id: number;
  description: string;
  compensation_type: FactorialCompensationType | string;
  amount: number;
  recurrence: keyof typeof recurrenceMultipliers | null;
  starts_on: string;
  unit: "money";
};

export type CompleteFactorialEmployee = FactorialEmployee & {
  legalEntity?: FactorialLegalEntity;
  contract?: FactorialContract;
  employeeCompensations: FactorialCompensation[];
  historicalContracts: FactorialContract[];
};

type FactorialIntegrationSettingsInput = Credentials & IntegrationCustomFields;

const factorialFetch = async <
  T extends FactorialEmployee | FactorialCompensation | FactorialLegalEntity | FactorialContract,
>(
  credentials: Credentials,
  endpoint: string
) => {
  const baseUrl = `https://api.factorialhr.com/api/${endpoint}/`;

  const res = await fetch(buildExternalUrl(baseUrl), {
    headers: {
      "Accept": "application/json",
      "x-api-key": `${credentials.clientSecret}`,
      "Content-Type": "application/json",
    },
  });

  if (!res.ok) {
    const { errors } = await res.json();
    throw new Error(`${res.status} ${res.statusText} : ${errors?.join(" / ")}`);
  }

  const json: T[] = await res.json();

  return json;
};

// on hold waiting for Factorial support
const getCustomField = () => {
  return null;
};

export const mapFactorialEmployee = async (
  ctx: AppContext,
  company: Company,
  apiEmployee: CompleteFactorialEmployee,
  integrationSettings: SafeIntegrationSettings,
  staticModels: StaticModels
): Promise<EmployeeData> => {
  const { variableCustomFieldFrequency, additionalFieldMappings = [] } = integrationSettings;
  const customFte = getCustomField();
  const customVariable = getCustomField();
  const customEmployeeNumber = getCustomField();
  const holidayAllowanceValue = getCustomField();

  const additionalFieldValues = additionalFieldMappings.map(({ additionalFieldId, id }) => ({
    additionalFieldId,
    additionalFieldMappingId: id,
    value: getCustomField(),
  }));

  const country = value(() => {
    if (!apiEmployee.legalEntity?.country) {
      return null;
    }
    return staticModels.countries.find((country) => country.alpha2 === apiEmployee.legalEntity?.country.toUpperCase());
  });
  const currency = staticModels.currencies.find(idIs(country?.defaultCurrencyId));

  const locationExternalId = apiEmployee?.legalEntity?.id.toString();

  const input: EmployeeData["input"] = {
    source: ExternalEmployeeSource.FACTORIAL,
    externalId: apiEmployee.id.toString(),
    status: ExternalEmployeeStatus.UNMAPPED,
    firstName: apiEmployee.first_name,
    lastName: apiEmployee.last_name,
    email: apiEmployee.email,
    employeeNumber: customEmployeeNumber ? customEmployeeNumber : apiEmployee.id.toString(),
    gender: value(() => {
      if (!apiEmployee.gender) {
        return null;
      }
      if (apiEmployee.gender === "female") {
        return Gender.FEMALE;
      }
      if (apiEmployee.gender === "male") {
        return Gender.MALE;
      }
      return Gender.UNDISCLOSED;
    }),
    hireDate: !!apiEmployee.contract?.starts_on ? parseISO(apiEmployee.contract.starts_on) : null,
    company: {
      connect: { id: company.id },
    },
    currency: {
      connect: { code: currency?.code || "EUR" },
    },
    ...(!!apiEmployee.legalEntity && {
      location: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: apiEmployee.legalEntity.id.toString(),
            },
          },
          create: {
            externalId: apiEmployee.legalEntity.id.toString(),
            name: `${apiEmployee.legalEntity.legal_name} ${apiEmployee.legalEntity.city}`,
            autoMappingEnabled: true,
            company: {
              connect: { id: company.id },
            },
            ...(country && { country: { connect: { id: country.id } } }),
          },
        },
      },
    }),
    ...(!!apiEmployee.contract?.role && {
      job: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: apiEmployee.contract.role,
            },
          },
          create: {
            name: apiEmployee.contract.role,
            externalId: apiEmployee.contract.role,
            company: {
              connect: { id: company.id },
            },
          },
        },
      },
    }),
    ...(!!apiEmployee.contract?.level && {
      level: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: apiEmployee.contract.level,
            },
          },
          create: {
            externalId: apiEmployee.contract.level,
            name: apiEmployee.contract.level,
            company: {
              connect: { id: company.id },
            },
          },
        },
      },
    }),
  };

  const numberMonths = getNumberOfMonth({
    externalId: locationExternalId,
    additionalMonthRules: staticModels.additionalMonthRules,
    externalLocations: staticModels.externalLocations,
  });

  const remunerationItems: EmployeeData["remunerationItems"] = compact([
    // Live data
    mapFactorialBaseSalary(ctx, {
      company,
      contract: apiEmployee?.contract,
      externalRemunerationStatus: ExternalRemunerationStatus.LIVE,
      numberMonths,
    }),
    ...apiEmployee.employeeCompensations
      .filter(({ compensation_type }) => isIn(compensation_type, Object.values(FactorialCompensationType)))
      .map((compensation) => mapFactorialBonus({ company, compensation })),

    // Historical data
    ...apiEmployee.historicalContracts.map((contract) =>
      mapFactorialBaseSalary(ctx, {
        company,
        contract,
        externalRemunerationStatus: ExternalRemunerationStatus.HISTORICAL,
        numberMonths,
      })
    ),
  ]);

  if (customVariable && variableCustomFieldFrequency) {
    const multiplier = match(variableCustomFieldFrequency)
      .with(CompensationFrequency.MONTHLY, () => 12)
      .with(CompensationFrequency.QUARTERLY, () => 4)
      .with(CompensationFrequency.YEARLY, () => 1)
      .exhaustive();

    const amount = Math.round(parseInt(customVariable) * multiplier);

    remunerationItems.push({
      company: {
        connect: { id: company.id },
      },
      source: ExternalEmployeeSource.FACTORIAL,
      externalId: "variable-bonus",
      amount,
      status: ExternalRemunerationStatus.LIVE,
      nature: {
        connectOrCreate: {
          where: {
            companyId_source_externalId: {
              companyId: company.id,
              source: ExternalEmployeeSource.FACTORIAL,
              externalId: "variable-bonus",
            },
          },
          create: {
            source: ExternalEmployeeSource.FACTORIAL,
            externalId: "variable-bonus",
            name: "Variable bonus",
            mappedType: ExternalRemunerationType.VARIABLE_BONUS,
            company: {
              connect: {
                id: company.id,
              },
            },
          },
        },
      },
    } satisfies EmployeeData["remunerationItems"][number]);
  }

  return {
    input,
    remunerationItems,
    additionalFieldValues: computeAdditionalFieldValuePayloads(company, additionalFieldValues),
    managerExternalId: apiEmployee.manager_id?.toString(),
    holidayAllowanceValue,
    ...(customFte ? { fte: customFte, ignoreFte: apiEmployee.contract?.salary_frequency === "hourly" } : {}),
  };
};

const mapFactorialBaseSalary = (
  ctx: AppContext,
  params: {
    company: Company;
    contract?: FactorialContract;
    externalRemunerationStatus: ExternalRemunerationStatus;
    numberMonths: number;
  }
): EmployeeData["remunerationItems"][number] | null => {
  const { company, contract, externalRemunerationStatus } = params;
  // Get Fixed salary
  if (!contract?.salary_amount) {
    return null;
  }

  const amount = contract?.salary_amount;

  if (amount === 0) {
    return null;
  }

  const interval = contract?.salary_frequency;

  if (!["hourly", "weekly", "monthly", "yearly"].includes(interval)) {
    logWarn(ctx, `[sync] Unhandled Factorial fix salary interval`, { interval });
  }

  const numberOfMonthMultiplier = params.numberMonths / 12;

  const multiplier = value(() => {
    if (interval === "hourly") {
      return 40 * 52 * numberOfMonthMultiplier;
    }

    if (interval === "weekly") {
      return 52 * numberOfMonthMultiplier;
    }

    if (interval === "monthly") {
      return 12 * numberOfMonthMultiplier;
    }

    return 1;
  });

  const yearlySalary = Math.round(amount * multiplier);

  const externalIdSuffix = match(externalRemunerationStatus)
    .with(ExternalRemunerationStatus.LIVE, () => "")
    .with(ExternalRemunerationStatus.HISTORICAL, () => `-historical-${contract.id}`)
    .exhaustive();

  return {
    company: {
      connect: { id: company.id },
    },
    source: ExternalEmployeeSource.FACTORIAL,
    externalId: `fix-salary${externalIdSuffix}`,
    amount: yearlySalary,
    status: externalRemunerationStatus,
    numberMonths: params.numberMonths,
    date: contract?.effective_on ? parseISO(contract.effective_on) : null,
    endsAt: contract?.ends_on ? parseISO(contract.ends_on) : null,
    nature: {
      connectOrCreate: {
        where: {
          companyId_source_externalId: {
            companyId: company.id,
            source: ExternalEmployeeSource.FACTORIAL,
            externalId: `fix-salary`,
          },
        },
        create: {
          source: ExternalEmployeeSource.FACTORIAL,
          externalId: `fix-salary`,
          name: "Fixed salary",
          mappedType: ExternalRemunerationType.FIXED_SALARY,
          company: {
            connect: {
              id: company.id,
            },
          },
        },
      },
    },
  } satisfies EmployeeData["remunerationItems"][number];
};

const mapFactorialBonus = (params: {
  company: Company;
  compensation: FactorialCompensation;
}): EmployeeData["remunerationItems"][number] | null => {
  const { company, compensation } = params;
  const compensationType = compensation.compensation_type as FactorialCompensationType;
  const multiplier = compensation.recurrence ? recurrenceMultipliers[compensation.recurrence] : 12;

  const amount = Math.round(compensation.amount * multiplier);

  const externalId = value(() => {
    const base = compensation.id ?? "";

    return match(compensationType)
      .with(FactorialCompensationType.UP_TO, () => `variable-bonus${base}`)
      .with(FactorialCompensationType.FIXED, () => `fixed-bonus${base}`)
      .exhaustive();
  });

  const name = value(() => {
    if (compensation.description) {
      return compensation.description;
    }

    return match(compensationType)
      .with(FactorialCompensationType.UP_TO, () => "Variable bonus")
      .with(FactorialCompensationType.FIXED, () => "Fixed bonus")
      .exhaustive();
  });

  const mappedType = match<FactorialCompensationType, ExternalRemunerationType>(compensationType)
    .with(FactorialCompensationType.UP_TO, () => ExternalRemunerationType.VARIABLE_BONUS)
    .with(FactorialCompensationType.FIXED, () => ExternalRemunerationType.FIXED_BONUS)
    .exhaustive();

  return {
    company: {
      connect: { id: company.id },
    },
    source: ExternalEmployeeSource.FACTORIAL,
    externalId,
    amount,
    status: ExternalRemunerationStatus.LIVE,
    date: compensation.starts_on ? parseISO(compensation.starts_on) : null,
    nature: {
      connectOrCreate: {
        where: {
          companyId_source_externalId: {
            companyId: company.id,
            source: ExternalEmployeeSource.FACTORIAL,
            externalId,
          },
        },
        create: {
          source: ExternalEmployeeSource.FACTORIAL,
          externalId,
          name,
          mappedType,
          company: {
            connect: {
              id: company.id,
            },
          },
        },
      },
    },
  } satisfies EmployeeData["remunerationItems"][number];
};

const anonymise = (credentials: Credentials) => {
  return (employee: FactorialEmployee) => {
    if (credentials.anonymous) {
      delete employee.first_name;
      delete employee.last_name;
      delete employee.full_name;
      delete employee.birthday_on;
      delete employee.email;
    }
    return employee;
  };
};

export const getFactorialEmployees = async (credentials: Credentials): Promise<CompleteFactorialEmployee[]> => {
  const [employees, legalEntities, contracts, compensations] = await Promise.all([
    factorialFetch<FactorialEmployee>(credentials, "v2/core/employees"),
    factorialFetch<FactorialLegalEntity>(credentials, "v1/core/legal_entities"),
    factorialFetch<FactorialContract>(credentials, "v1/payroll/contract_versions"),
    factorialFetch<FactorialCompensation>(credentials, "v1/payroll/compensations"),
  ]);

  return employees
    .map((employee) => {
      delete employee.bank_number;
      delete employee.country;
      delete employee.city;
      delete employee.state;
      delete employee.postal_code;
      delete employee.address_line_1;
      delete employee.address_line_2;
      delete employee.swift_bic;
      delete employee.social_security_number;
      delete employee.phone_number;

      return employee;
    })
    .map(anonymise(credentials))
    .filter((employee) => !employee.terminated_on)
    .map((employee) => {
      const allContracts = chain(contracts)
        .filter((contract) => contract.employee_id === employee.id)
        .value();

      const contract = chain(allContracts)
        .filter((contract) => isBefore(parseISO(contract.effective_on), new Date()))
        .filter((contract) => !contract.ends_on || isAfter(parseISO(contract.ends_on), new Date()))
        .maxBy((contract) => contract.effective_on)
        .value();

      const historicalContracts = value(() => {
        const contracts = allContracts
          .filter((historicalContract) => !!historicalContract?.salary_amount)
          .filter((historicalContract) => historicalContract?.salary_amount !== contract?.salary_amount)
          .filter((historicalContract) => historicalContract.id !== contract?.id);

        const filteredContracts = map(groupBy(contracts, "salary_amount"), (contract) =>
          minBy(contract, "effective_on")
        ) as FactorialContract[];

        return filteredContracts;
      });

      const legalEntity = legalEntities.find(idIs(employee.legal_entity_id));
      const employeeCompensations = !!contract
        ? compensations
            .filter((compensation) => isBefore(parseISO(compensation.starts_on), new Date()))
            .filter((compensation) => compensation.unit === "money")
            .filter(idIsIn(contract.compensation_ids))
        : [];

      return {
        ...employee,
        contract,
        legalEntity,
        employeeCompensations,
        historicalContracts,
      };
    });
};

const assertSafeIntegrationSettings = (integrationSettings: IntegrationSettingsForSync) =>
  assertProps(integrationSettings, ["clientSecret"]);
export type SafeIntegrationSettings = ReturnType<typeof assertSafeIntegrationSettings>;

export const getMappedFactorialEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync,
  staticModels: StaticModels
): Promise<EmployeeData[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  const factorialEmployees = await getFactorialEmployees(safeIntegrationSettings);

  return mapSeries(factorialEmployees, (factorialEmployee) =>
    mapFactorialEmployee(ctx, company, factorialEmployee, safeIntegrationSettings, staticModels)
  );
};

export const getFactorialDiagnostic = async (
  ctx: AppContext,
  input: FactorialIntegrationSettingsInput
): Promise<IntegrationDiagnostic> => {
  try {
    const legalEntities = await factorialFetch<FactorialLegalEntity>(input, "v1/core/legal_entities");

    if (legalEntities.length === 0) {
      return {
        connection: false,
        connectionError: "We could not find any legal entities within your Factorial HR account",
        missingFields: [],
        availableFields: [],
      };
    }

    return {
      connection: true,
      connectionError: "",
      missingFields: [],
      availableFields: [],
    };
  } catch (error) {
    return {
      connection: false,
      connectionError: error.message,
      missingFields: [],
      availableFields: [],
    };
  }
};

export const getRawFactorialEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync
): Promise<FactorialEmployee[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  return getFactorialEmployees(safeIntegrationSettings);
};

import { OpenInNew } from "@mui/icons-material";
import { Chip, Stack, Tab, Tabs, Typography } from "@mui/material";
import { type Currency } from "@prisma/client";
import { useRouter } from "next/router";
import React, { type ComponentProps, useContext } from "react";
import { PerformanceRatingTooltip } from "~/components/compensation-review/PerformanceRatingTooltip";
import { EmployeePicture } from "~/components/employee/EmployeePicture";
import { LegacyExternalEmployeeCompensationHistory } from "~/components/external-employee/ExternalEmployeeCompensationHistory";
import { ExternalEmployeeOverview } from "~/components/external-employee/ExternalEmployeeOverview";
import { OffCycleReviewRequestsBanner } from "~/components/external-employee/OffCycleReviewRequestsBanner";
import { value } from "~/components/helpers";
import { Button } from "~/components/ui/core/Button";
import { NextLinkComposed } from "~/components/ui/core/Link";
import { Skeleton } from "~/components/ui/core/Skeleton";
import { trackSidePanelOpened } from "~/lib/external/segment/web/events";
import { useI18n } from "~/lib/i18n/useI18n";
import { formatPercent } from "~/lib/math";
import { parseNumber, parseString } from "~/lib/queryParams";
import { currentUrlWithQueryParams } from "~/lib/url";
import { useFetchExternalEmployeeForPanelQuery } from "~/pages/api/external-employee/fetch-external-employee-for-panel";
import { type GetAllAdditionalFieldsResult } from "~/services/additional-field/getAllAdditionalFields";
import { CompensationReviewContext } from "~/services/compensation-review/campaigns/campaignHandler";
import { formatExternalEmployeeName } from "~/services/external-employee";
import { type ExternalEmployeePanelSelect } from "~/services/external-employee/fetchExternalEmployeeForPanel";

type Tab = "overview" | "compensation-history" | string;

type ExtraTab = {
  value: string;
  label: string | JSX.Element;
  component: JSX.Element;
};

type Props = {
  currency: Pick<Currency, "code" | "euroExchangeRate">;
  externalEmployeeId: number;
  performanceRating: ComponentProps<typeof PerformanceRatingTooltip>["performanceRating"] | null;
  additionalFields: GetAllAdditionalFieldsResult;
  banner?: JSX.Element;
  topRightSection?: JSX.Element;
  extraTabs?: ExtraTab[];
  defaultTab?: Tab;
  actions?: JSX.Element;
  onEditClick?: (externalEmployeeId: number) => void;
};

const TAB_KEY = "employee-tab";

export const EXTERNAL_EMPLOYEE_ID_KEY = "external-employee-id";
export const EMPLOYEE_ID_KEY = "employee-id";

type Row = {
  externalEmployee: ExternalEmployeePanelSelect;
  performanceRating: Props["performanceRating"];
};

export const useExternalEmployeePanel = <T extends Row>(params: { prefix?: string; rows: T[] }) => {
  const router = useRouter();
  const queryKey = params.prefix ? `${params.prefix}-${EXTERNAL_EMPLOYEE_ID_KEY}` : EXTERNAL_EMPLOYEE_ID_KEY;

  const externalEmployeeId = parseNumber(router.query, queryKey);

  const activeRow = params.rows.find((row) => {
    return row.externalEmployee.id === externalEmployeeId;
  });

  const open = (externalEmployeeId: number | null) => {
    void router.push(currentUrlWithQueryParams(router, { [queryKey]: externalEmployeeId }), undefined, {
      shallow: true,
    });

    if (!!externalEmployeeId) {
      //otherwise, we double the events with close and open
      void trackSidePanelOpened({ name: "External Employee Panel", router, externalEmployeeId });
    }
  };

  return {
    open,
    sidePanelProps: {
      isOpen: !!activeRow,
      onClose: () => open(null),
      disableBottomPadding: true,
    },
    activeRow,
    externalEmployeeId,
  };
};

export const ExternalEmployeePanel: React.FC<Props> = ({
  currency,
  externalEmployeeId,
  additionalFields,
  banner: extraBanner,
  topRightSection,
  performanceRating,
  extraTabs,
  defaultTab = "overview",
  actions,
  onEditClick,
}) => {
  const { t } = useI18n();
  const router = useRouter();
  const tab: Tab = parseString(router.query, TAB_KEY) ?? defaultTab;

  const compensationReviewContext = useContext(CompensationReviewContext);

  const { data: externalEmployee } = useFetchExternalEmployeeForPanelQuery({
    input: { id: externalEmployeeId, fromCompensationReview: !!compensationReviewContext },
  });

  const onTabChange = (tab: string) => {
    void router.push(currentUrlWithQueryParams(router, { [TAB_KEY]: tab }), undefined, { shallow: true });
  };

  const extraTab = extraTabs?.find((extraTab) => extraTab.value === tab);

  const banner = value(() => {
    if (extraBanner) return extraBanner;

    const request = externalEmployee?.offCycleReviewRequests[0];
    if (!!request) {
      return <OffCycleReviewRequestsBanner externalEmployee={externalEmployee} request={request} />;
    }
  });

  return externalEmployee ? (
    <Stack className="h-full">
      {banner}

      <Stack className="p-6" direction="row" spacing={12} justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={12} alignItems="center">
          <Stack spacing={4} alignItems="start">
            <EmployeePicture
              className="border-2 border-white shadow-xl"
              size="3xl"
              picture={externalEmployee.picture}
              placeholderId={externalEmployee.id}
              pictureUrl={externalEmployee.userPermissions?.user?.profilePictureUrl}
            />
            <Stack spacing={2}>
              <Typography variant="h2">{formatExternalEmployeeName(externalEmployee)}</Typography>
              {externalEmployee.job && (
                <Typography variant="subtitle1" color="text.secondary">
                  {externalEmployee.job.name}
                </Typography>
              )}
              {externalEmployee.fteDivider && (
                <Stack direction="row" spacing={2}>
                  <Chip
                    label={t("components.external-employee-panel.header.part-time", {
                      fteDivider: formatPercent(externalEmployee.fteDivider),
                    })}
                    size="small"
                    color="secondary"
                    variant="outlined"
                  />
                </Stack>
              )}
            </Stack>
          </Stack>

          <Stack spacing={5}>
            {externalEmployee.level && (
              <Stack>
                <Typography variant="subtitle1">{t("components.external-employee-panel.header.level")}</Typography>
                <Typography color="text.secondary">{externalEmployee.level.name}</Typography>
              </Stack>
            )}

            {externalEmployee.location && (
              <Stack>
                <Typography variant="subtitle1">{t("components.external-employee-panel.header.location")}</Typography>
                <Typography color="text.secondary">
                  {externalEmployee.location.name}
                  {externalEmployee.location.country && (
                    <span>
                      <br />
                      {externalEmployee.location.country.name}
                    </span>
                  )}
                </Typography>
              </Stack>
            )}

            {performanceRating && (
              <Stack>
                <Typography variant="subtitle1">
                  {t("components.external-employee-panel.header.performance-rating")}
                </Typography>
                <Typography color="text.secondary">
                  <PerformanceRatingTooltip performanceRating={performanceRating} />
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>

        {topRightSection}
      </Stack>

      <Tabs value={tab} onChange={(e, tab) => onTabChange(tab)} className="mb-0 border-b px-4">
        {extraTabs?.map((extraTab) => (
          <Tab key={extraTab.value} label={extraTab.label} value={extraTab.value} className="normal-case" />
        ))}
        <Tab label={t("components.external-employee-panel.tab.overview")} value="overview" className="normal-case" />
        <Tab
          label={t("components.external-employee-panel.tab.history")}
          value="compensation-history"
          className="normal-case"
        />

        <Stack className="ml-auto" direction="row" spacing={2} alignItems="center">
          {actions}

          {onEditClick ? (
            <Button variant="outlined" color="gray" size="small" onClick={() => onEditClick(externalEmployee.id)}>
              {t("common.edit")}
            </Button>
          ) : (
            <Button
              component={NextLinkComposed}
              newTab
              color="gray"
              size="small"
              variant="outlined"
              to={{
                pathname: "/account/imported-employees",
                query: {
                  query: externalEmployee.externalId,
                  [EMPLOYEE_ID_KEY]: `${externalEmployee.id}`,
                },
              }}
              endIcon={<OpenInNew />}
            >
              {t("common.edit")}
            </Button>
          )}
        </Stack>
      </Tabs>

      <div className="h-full p-4">
        {tab === "overview" && (
          <ExternalEmployeeOverview
            currency={currency}
            additionalFields={additionalFields}
            externalEmployee={externalEmployee}
          />
        )}
        {tab === "compensation-history" && (
          <LegacyExternalEmployeeCompensationHistory externalEmployeeId={externalEmployee.id} />
        )}
        {extraTab && extraTab.component}
      </div>
    </Stack>
  ) : (
    <Skeleton variant="rectangular" height="100%" />
  );
};

import { CompensationReviewRecommendationStatus, type Prisma } from "@prisma/client";
import { mapSeries } from "bluebird";
import { value } from "~/components/helpers";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { type FetchCompensationReviewEmployeesResult } from "~/services/compensation-review/campaigns/admin/fetchCompensationReviewEmployees";
import {
  isCompensationReviewCampaignContext,
  type CompensationReviewContext,
} from "~/services/compensation-review/compensationReviewContext";
import { prismaCompensationReviewScope } from "~/services/compensation-review/compensationReviewScope";
import { getCompensationReviewBudget } from "~/services/compensation-review/shared/compensationReviewBudget";
import { computeDateProration } from "~/services/compensation-review/shared/computeDateProration";
import {
  fetchRecommendationForUpdate,
  submitRecommendation,
} from "~/services/compensation-review/shared/updateRecommendation";

export const initialiseCompensationReviewRecommendations = async (
  ctx: CompensationReviewContext,
  params: {
    employee: FetchCompensationReviewEmployeesResult["items"][number];
  }
) => {
  const user = getRequiredUser(ctx);
  const { employee } = params;

  const commonPayload = {
    ...prismaCompensationReviewScope(ctx.scope),
    companyId: user.companyId,
    employeeId: employee.id,
  };

  if (employee.adjustments.find((adjustment) => adjustment.recommendedAmount !== null)) {
    await createRecommendationAndAdjustments(ctx, {
      employee,
      recommendationPayload: {
        ...commonPayload,
        position: -1,
        status: CompensationReviewRecommendationStatus.SUBMITTED,
        isFinalRecommendation: false,
        isListedToReviewer: true,
        submittedAt: new Date(),
      },
    });
  }

  const reviewersIds: number[] = [];

  await mapSeries(employee.reviewers, async (reviewer, position) => {
    if (!reviewer) return;

    const isListedToReviewer = !reviewersIds.includes(reviewer.id);

    await createRecommendationAndAdjustments(ctx, {
      employee,
      recommendationPayload: {
        ...commonPayload,
        reviewerId: reviewer.id,
        position,
        status:
          position === 0
            ? CompensationReviewRecommendationStatus.PENDING_FOR_REVIEW
            : CompensationReviewRecommendationStatus.WAITING_FOR_ANOTHER_REVIEW,
        isFinalRecommendation: false,
        isListedToReviewer,
      },
    });

    reviewersIds.push(reviewer.id);
  });

  if (ctx.parameters.finalReviewerId) {
    const isListedToReviewer = !reviewersIds.includes(ctx.parameters.finalReviewerId);

    await createRecommendationAndAdjustments(ctx, {
      employee,
      recommendationPayload: {
        ...commonPayload,
        reviewerId: ctx.parameters.finalReviewerId,
        position: reviewersIds.length,
        status: reviewersIds.length
          ? CompensationReviewRecommendationStatus.WAITING_FOR_ANOTHER_REVIEW
          : CompensationReviewRecommendationStatus.PENDING_FOR_REVIEW,
        isFinalRecommendation: true,
        isListedToReviewer,
      },
    });
  }
};

const createRecommendationAndAdjustments = async (
  ctx: CompensationReviewContext,
  params: {
    employee: FetchCompensationReviewEmployeesResult["items"][number];
    recommendationPayload: Prisma.CompensationReviewRecommendationUncheckedCreateInput;
  }
) => {
  const recommendation = await ctx.prisma.compensationReviewRecommendation.create({
    data: params.recommendationPayload,
  });

  const adjustmentsWithPermissions = await mapSeries(params.employee.adjustments, async (adjustment) => {
    if (!recommendation.reviewerId) return { ...adjustment, canAllocate: true };

    const permissions = await ctx.prisma.compensationReviewReviewerBudgetPermission.findFirst({
      where: {
        reviewerId: recommendation.reviewerId,
        budgetId: adjustment.budgetId,
      },
      select: { canAllocate: true },
    });

    return {
      ...adjustment,
      ...permissions,

      canAllocate: permissions?.canAllocate ?? false,
    };
  });

  const adjustmentsToCreate = adjustmentsWithPermissions.filter((adjustment) => adjustment.canAllocate);

  await mapSeries(adjustmentsToCreate, async (employeeAdjustment) => {
    const proratedSubmittedAmount = value(() => {
      if (!isCompensationReviewCampaignContext(ctx)) return null;

      const budget = getCompensationReviewBudget(ctx, employeeAdjustment.budgetId);

      const { proratedAmount: proratedSubmittedAmount } = computeDateProration({
        amount: employeeAdjustment.convertedRecommendedAmount,
        prorationStartDate: budget.prorationStartDate,
        effectiveDate: params.employee.effectiveDate,
      });

      return proratedSubmittedAmount;
    });

    await ctx.prisma.compensationReviewAdjustment.create({
      data: {
        ...prismaCompensationReviewScope(ctx.scope),
        companyId: ctx.user.companyId,
        recommendationId: recommendation.id,
        budgetId: employeeAdjustment.budgetId,
        compensationItem: employeeAdjustment.compensationItem,
        recommendedAmount: employeeAdjustment.recommendedAmount,
        convertedRecommendedAmount: employeeAdjustment.convertedRecommendedAmount,
        ...(recommendation.status === CompensationReviewRecommendationStatus.SUBMITTED && {
          submittedAmount: employeeAdjustment.recommendedAmount,
          convertedSubmittedAmount: employeeAdjustment.convertedRecommendedAmount,
          proratedSubmittedAmount,
        }),
      },
    });
  });

  if (recommendation.status === CompensationReviewRecommendationStatus.SUBMITTED) {
    const recommendationForUpdate = await fetchRecommendationForUpdate(ctx, {
      recommendationId: recommendation.id,
    });

    const adjustmentsToSubmit = adjustmentsToCreate.filter((adjustment) => {
      const budget = getCompensationReviewBudget(ctx, adjustment.budgetId);

      return budget.isAdminBudget;
    });

    await submitRecommendation(ctx, {
      recommendation: recommendationForUpdate,
      adjustments: adjustmentsToSubmit.map((adjustment) => {
        const proratedSubmittedAmount = value(() => {
          if (!isCompensationReviewCampaignContext(ctx)) return null;

          const budget = getCompensationReviewBudget(ctx, adjustment.budgetId);

          const { proratedAmount: proratedSubmittedAmount } = computeDateProration({
            amount: adjustment.convertedRecommendedAmount,
            prorationStartDate: budget.prorationStartDate,
            effectiveDate: recommendationForUpdate.employee.effectiveDate,
          });

          return proratedSubmittedAmount;
        });

        return {
          ...adjustment,
          submittedAmount: adjustment.recommendedAmount,
          convertedSubmittedAmount: adjustment.convertedRecommendedAmount,
          proratedSubmittedAmount,
        };
      }),
    });
  }
};

import { number, object } from "yup";
import { type AppContext } from "~/lib/context";
import { makeSingletonKey } from "~/lib/makeSingletonKey";
import { BaseJobDataSchema } from "~/lib/queue/baseJobDataSchema";
import { QueueJobName } from "~/lib/queue/queueJobName";
import { sendJob } from "~/lib/queue/sendJob";
import { type YupOutputType } from "~/lib/utils";
import { synchroniseCampaignEmployees } from "~/services/compensation-review/campaigns/admin/synchroniseCampaignEmployees";
import { fetchCompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";

const SynchroniseCompensationReviewCampaignEmployeesJobDataSchema = BaseJobDataSchema.concat(
  object({ campaignId: number().required() })
);

export type SynchroniseCompensationReviewCampaignEmployeesJobData = YupOutputType<
  typeof SynchroniseCompensationReviewCampaignEmployeesJobDataSchema
>;

export const synchroniseCompensationReviewCampaignEmployeesWorkerService = async (
  ctx: AppContext,
  data: SynchroniseCompensationReviewCampaignEmployeesJobData
) => {
  const context = await fetchCompensationReviewCampaignContext(ctx, data);

  await synchroniseCampaignEmployees(context);
};

export const sendSynchroniseCompensationReviewCampaignEmployeesJob = async (
  ctx: AppContext,
  data: SynchroniseCompensationReviewCampaignEmployeesJobData
) => {
  await sendJob(ctx, {
    jobName: QueueJobName.CREATE_COMPENSATION_REVIEW_CAMPAIGN_EMPLOYEES,
    data,
    options: {
      singletonKey: makeSingletonKey({ companyId: data.companyId, campaignId: data.campaignId }, { unique: false }),
    },
  });
};

import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import {
  type CompensationReviewScope,
  prismaCompensationReviewScope,
} from "~/services/compensation-review/compensationReviewScope";

export const fetchAuthenticatedCompensationReviewReviewer = async (
  ctx: AppContext,
  params: { scope: CompensationReviewScope }
) => {
  const user = getRequiredUser(ctx);

  if (!user.permissions.externalEmployeeId) return null;

  const reviewer = await ctx.prisma.compensationReviewReviewer.findFirst({
    where: {
      ...prismaCompensationReviewScope(params.scope),
      externalEmployeeId: user.permissions.externalEmployeeId,
    },
    select: {
      id: true,
      canSeeAnalytics: true,
      budgetPermissions: {
        select: {
          budgetId: true,
          canView: true,
          canAllocate: true,
        },
      },
      _count: {
        select: {
          recommendations: true,
        },
      },
    },
  });

  if (!reviewer) return null;

  return {
    id: reviewer.id,
    canSeeAnalytics: reviewer.canSeeAnalytics,
    budgetPermissions: reviewer.budgetPermissions,
    recommendationsCount: reviewer._count.recommendations,
  };
};

export type FetchAuthenticatedCompensationReviewReviewerResult = AsyncReturnType<
  typeof fetchAuthenticatedCompensationReviewReviewer
>;

import { mapSeries } from "bluebird";
import { chain } from "~/lib/lodash";
import { assertNotNil } from "~/lib/utils";
import { type CompensationReviewContext } from "~/services/compensation-review/compensationReviewContext";
import { prismaCompensationReviewScope } from "~/services/compensation-review/compensationReviewScope";
import { isPendingRecommendation } from "~/services/compensation-review/shared/recommendationStatus";

export const ensureFirstRecommendationIsListedToReviewer = async (
  ctx: CompensationReviewContext,
  params: { employeeId: number }
) => {
  const recommendations = await ctx.prisma.compensationReviewRecommendation.findMany({
    where: {
      ...prismaCompensationReviewScope(ctx.scope),
      employeeId: params.employeeId,
    },
    orderBy: { position: "asc" },
  });

  const pendingRecommendation = recommendations.find((recommendation) => isPendingRecommendation(recommendation));
  if (!pendingRecommendation) return;

  const recommendationsWithSameReviewer = chain(recommendations)
    .groupBy((recommendation) => recommendation.reviewerId)
    .filter((recommendations) => recommendations.length > 1 && !!recommendations[0]?.reviewerId)
    .value();

  await mapSeries(recommendationsWithSameReviewer, async (recommendations) => {
    const visibleRecommendation =
      recommendations.find((recommendation) => recommendation.position >= pendingRecommendation.position) ??
      assertNotNil(recommendations[0]);

    await ctx.prisma.compensationReviewRecommendation.update({
      where: { id: visibleRecommendation.id },
      data: { isListedToReviewer: true },
    });

    const otherRecommendations = recommendations.filter(
      (recommendation) => recommendation.id !== visibleRecommendation.id
    );
    await ctx.prisma.compensationReviewRecommendation.updateMany({
      where: { id: { in: otherRecommendations.map((recommendation) => recommendation.id) } },
      data: { isListedToReviewer: false },
    });
  });
};

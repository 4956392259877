import { mapSeries } from "bluebird";
import { ensure } from "~/lib/ensure";
import { type UpdateCompensationReviewMaxReviewersCountInput } from "~/pages/api/compensation-review/update-compensation-review-max-reviewers-count";
import { buildReviewersUpdatePayload } from "~/services/compensation-review/campaigns/admin/employeeReviewers";
import {
  buildFetchCompensationReviewEmployeesInput,
  fetchCompensationReviewEmployees,
} from "~/services/compensation-review/campaigns/admin/fetchCompensationReviewEmployees";
import {
  type CompensationReviewContext,
  isCompensationReviewCampaignContext,
} from "~/services/compensation-review/compensationReviewContext";
import { CompensationReviewScopeType } from "~/services/compensation-review/compensationReviewScope";
import { findOrCreateCompensationReviewReviewers } from "~/services/compensation-review/shared/findOrCreateCompensationReviewReviewer";
import { updateCompensationReviewScope } from "~/services/compensation-review/shared/updateCompensationReviewScope";
import { sendRefreshCampaignEmployeesReviewersJob } from "~/workers/refreshCampaignEmployeesReviewers";

export const updateCompensationReviewMaxReviewersCount = async (
  ctx: CompensationReviewContext,
  input: UpdateCompensationReviewMaxReviewersCountInput
) => {
  ensure(() => {
    if (isCompensationReviewCampaignContext(ctx)) {
      ensure(() => ctx.permissions.canConfigure);
    }

    return true;
  });

  await updateCompensationReviewScope(ctx, {
    maxReviewersCount: input.maxReviewersCount,
  });

  if (ctx.scope.type === CompensationReviewScopeType.CAMPAIGN) {
    await sendRefreshCampaignEmployeesReviewersJob(ctx, {
      companyId: ctx.companyId,
      scope: ctx.scope,
      maxReviewersCount: input.maxReviewersCount,
    });
  }
};

export const refreshCampaignEmployeesReviewers = async (
  ctx: CompensationReviewContext,
  params: {
    maxReviewersCount: number;
  }
) => {
  const employees = await fetchCompensationReviewEmployees(ctx, {
    scope: ctx.scope,
    ...buildFetchCompensationReviewEmployeesInput(),
  });

  await mapSeries(employees.items, async (employee) => {
    if (employee.hasCustomReviewChain) {
      return ctx.prisma.compensationReviewEmployee.update({
        where: { id: employee.id },
        data: buildReviewersUpdatePayload(
          employee.reviewers.filter((reviewer) => reviewer !== null).slice(0, params.maxReviewersCount)
        ),
      });
    }

    const reviewers = await findOrCreateCompensationReviewReviewers(ctx, {
      externalEmployeeId: employee.externalEmployee.id,
      highlightConfigurationStep: true,
    });

    return ctx.prisma.compensationReviewEmployee.update({
      where: { id: employee.id },
      data: buildReviewersUpdatePayload(reviewers),
    });
  });
};

import { type Prisma } from "@prisma/client";
import { compact, includes, lowerCase } from "~/lib/lodash";
import { stripDiacritics } from "~/lib/utils";
import { LEVELS_MAP } from "~/services/employee/employeeLevel";
import { USER_ROLE_MAP } from "~/services/users/userRoleMap";

export const searchUserInvitation = (query: string) => {
  const matches = { contains: query, mode: "insensitive" } as const;
  const lowerQuery = lowerCase(query);
  const userRoles = Object.entries(USER_ROLE_MAP)
    .filter(([key]) => includes(lowerCase(key), lowerQuery))
    .map(([, value]) => value);

  return {
    OR: compact([{ email: matches }, userRoles.length > 0 && { permissions: { role: { in: userRoles } } }]),
  } satisfies Prisma.UserWhereInput;
};

export const searchUser = (query: string) => {
  const matches = { contains: query, mode: "insensitive" } as const;
  const lowerQuery = lowerCase(query);
  const userRoles = Object.entries(USER_ROLE_MAP)
    .filter(([key]) => includes(lowerCase(key), lowerQuery))
    .map(([, value]) => value);

  return {
    OR: compact([
      { firstName: matches },
      { lastName: matches },
      { email: matches },
      userRoles.length > 0 && { permissions: { role: { in: userRoles } } },
    ]),
  } satisfies Prisma.UserWhereInput;
};

export const searchExternalEmployee = (query: string) => {
  const lowerQuery = lowerCase(stripDiacritics(query));
  const employeeLevels = Object.entries(LEVELS_MAP)
    .filter(([key]) => includes(lowerCase(key), lowerQuery))
    .map(([, value]) => value);

  return {
    AND: lowerQuery.split(" ").flatMap((part) => {
      const matches = { contains: part, mode: "insensitive" } as const;

      return {
        OR: compact([
          { employeeNumber: matches },
          { normalisedColumns: matches },
          {
            mappedEmployee: {
              OR: compact([
                { job: { OR: [{ name: matches }, { family: { name: matches } }] } },
                { location: { OR: [{ name: matches }, { country: { name: matches } }] } },
                employeeLevels.length > 0 && { level: { in: employeeLevels } },
              ]),
            },
          },
          {
            level: {
              OR: compact([{ name: matches }, employeeLevels.length > 0 && { mappedLevel: { in: employeeLevels } }]),
            },
          },
          { job: { OR: [{ name: matches }, { mappedJob: { name: matches } }] } },
          {
            location: {
              OR: [
                { name: matches },
                { country: { name: matches } },
                { mappedLocation: { OR: [{ name: matches }, { country: { name: matches } }] } },
              ],
            },
          },
        ]),
      };
    }),
  } satisfies Prisma.ExternalEmployeeWhereInput;
};

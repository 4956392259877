import { type CompensationReviewConfigurationStep } from "@prisma/client";
import { uniq } from "~/lib/lodash";
import { type CompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";

export const highlightConfigurationStep = async (
  ctx: CompensationReviewCampaignContext,
  step: CompensationReviewConfigurationStep
) => {
  await ctx.prisma.compensationReviewCampaign.update({
    where: { id: ctx.campaign.id },
    data: { highlightedConfigurationSteps: uniq([...ctx.campaign.highlightedConfigurationSteps, step]) },
  });
};

import { Stack, Typography } from "@mui/material";
import { QueueJobStatus } from "@prisma/client";
import React, { useContext, useEffect, useState } from "react";
import { match } from "ts-pattern";
import { Button } from "~/components/ui/core/Button";
import { ProgressBar } from "~/components/ui/core/ProgressBar";
import { SnackBar } from "~/components/ui/core/SnackBar";
import { useJobQueue } from "~/hooks/useJobQueue";
import { useSession } from "~/hooks/useSession";
import { useI18n } from "~/lib/i18n/useI18n";
import { noop } from "~/lib/lodash";
import { QueueJobName } from "~/lib/queue/queueJobName";

type ExternalEmployeesSyncProgressProps = {
  message: string;
  inProgress: boolean;
  jobStatus: QueueJobStatus | null;
  completionRatio?: number;
  triggerSyncPolling: (onCompleteAction?: { action: () => void; message: string }) => void;
  onComplete?: { action: () => void; message: string };
  isSnackBarOpen: boolean;
  setIsSnackBarOpen: (isSnackBarOpen: boolean) => void;
};

const ExternalEmployeesSyncProgressContext = React.createContext<ExternalEmployeesSyncProgressProps>({
  message: "We are processing your data",
  inProgress: false,
  jobStatus: null,
  completionRatio: 0,
  triggerSyncPolling: () => noop,
  isSnackBarOpen: false,
  setIsSnackBarOpen: () => noop,
});

export const ExternalEmployeesSyncSnackbar: React.FC = () => {
  const { completionRatio, message, jobStatus, onComplete, isSnackBarOpen, setIsSnackBarOpen } =
    useExternalEmployeesSyncProgress();

  const onCompleteClick = () => {
    onComplete?.action();
    setIsSnackBarOpen(false);
  };

  return (
    <SnackBar
      open={isSnackBarOpen}
      autoHideDuration={6000}
      message={message}
      {...(jobStatus === QueueJobStatus.COMPLETED && { onClose: () => setIsSnackBarOpen(false) })}
    >
      <Stack className="mt-2 w-full" spacing={2}>
        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
          <ProgressBar className="w-full" BarProps={{ value: completionRatio, variant: "determinate" }} />

          <Typography variant="caption">{completionRatio}%</Typography>
        </Stack>

        {onComplete && (
          <Button
            color="white"
            size="small"
            className="!ml-auto mt-1"
            variant="contained"
            onClick={onCompleteClick}
            disabled={jobStatus !== QueueJobStatus.COMPLETED}
          >
            {onComplete.message}
          </Button>
        )}
      </Stack>
    </SnackBar>
  );
};

export const useExternalEmployeesSyncProgress = (): ExternalEmployeesSyncProgressProps => {
  return useContext(ExternalEmployeesSyncProgressContext);
};

export const ExternalEmployeesSyncProgressProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useI18n();
  const { user } = useSession();
  const [isPolling, setIsPolling] = useState(false);
  const [onComplete, setOnComplete] = useState<{ action: () => void; message: string }>();
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);

  const syncJob = useJobQueue({
    jobName: QueueJobName.SYNC_EXTERNAL_EMPLOYEES,
    singletonKey: user?.company.id.toString() ?? "",
    multipartJob: true,
    enablePolling: isPolling,
  });

  const inProgress = syncJob.jobStatus === QueueJobStatus.IN_PROGRESS || syncJob.jobStatus === QueueJobStatus.SCHEDULED;

  useEffect(() => {
    setIsSnackBarOpen(
      (inProgress || syncJob.jobStatus === QueueJobStatus.COMPLETED) && syncJob.jobStatus !== QueueJobStatus.FAILED
    );
  }, [inProgress, syncJob.jobStatus]);

  const triggerSyncPolling = async (onCompleteAction?: { action: () => void; message: string }) => {
    syncJob.setJobStatus(QueueJobStatus.SCHEDULED);
    syncJob.setJobProgress(0);

    setIsPolling(true);

    if (onCompleteAction) {
      setOnComplete(onCompleteAction);
    }
  };

  const message = match(syncJob.actualJobName)
    .with(QueueJobName.PRE_SYNC_EXTERNAL_EMPLOYEES, () =>
      t("components.ui.external-employee-synchronisation.pre-sync-message")
    )
    .with(QueueJobName.POST_SYNC_EXTERNAL_EMPLOYEES, () =>
      t("components.ui.external-employee-synchronisation.post-sync-message")
    )
    .otherwise(() => t("components.ui.external-employee-synchronisation.sync-message"));

  const displayedMessage =
    syncJob.jobStatus === QueueJobStatus.COMPLETED
      ? t("components.ui.external-employee-synchronisation.done-message")
      : message;

  return (
    <ExternalEmployeesSyncProgressContext.Provider
      value={{
        inProgress,
        jobStatus: syncJob.jobStatus,
        completionRatio: syncJob.progress,
        message: displayedMessage,
        triggerSyncPolling,
        onComplete,
        isSnackBarOpen,
        setIsSnackBarOpen,
      }}
    >
      {children}
    </ExternalEmployeesSyncProgressContext.Provider>
  );
};

import { type Prisma } from "@prisma/client";
import { type CompensationReviewContext } from "~/services/compensation-review/compensationReviewContext";

export const selectReviewerIds = {
  reviewer1Id: true,
  reviewer2Id: true,
  reviewer3Id: true,
  reviewer4Id: true,
  reviewer5Id: true,
  reviewer6Id: true,
  reviewer7Id: true,
  reviewer8Id: true,
  reviewer9Id: true,
  reviewer10Id: true,
} satisfies Prisma.CompensationReviewEmployeeSelect;

export type EmployeeWithReviewerIds = Prisma.CompensationReviewEmployeeGetPayload<{ select: typeof selectReviewerIds }>;

export const includeReviewers = <T extends Prisma.CompensationReviewReviewerInclude>(include: T) => {
  return {
    reviewer1: { include },
    reviewer2: { include },
    reviewer3: { include },
    reviewer4: { include },
    reviewer5: { include },
    reviewer6: { include },
    reviewer7: { include },
    reviewer8: { include },
    reviewer9: { include },
    reviewer10: { include },
  } satisfies Prisma.CompensationReviewEmployeeInclude;
};

export const getEmployeeReviewerIds = (employee: EmployeeWithReviewerIds) => {
  return [
    employee.reviewer1Id,
    employee.reviewer2Id,
    employee.reviewer3Id,
    employee.reviewer4Id,
    employee.reviewer5Id,
    employee.reviewer6Id,
    employee.reviewer7Id,
    employee.reviewer8Id,
    employee.reviewer9Id,
    employee.reviewer10Id,
  ];
};

export const buildReviewersUpdatePayload = (reviewers: { id: number }[]) => {
  return {
    reviewer1Id: reviewers[0]?.id ?? null,
    reviewer2Id: reviewers[1]?.id ?? null,
    reviewer3Id: reviewers[2]?.id ?? null,
    reviewer4Id: reviewers[3]?.id ?? null,
    reviewer5Id: reviewers[4]?.id ?? null,
    reviewer6Id: reviewers[5]?.id ?? null,
    reviewer7Id: reviewers[6]?.id ?? null,
    reviewer8Id: reviewers[7]?.id ?? null,
    reviewer9Id: reviewers[8]?.id ?? null,
    reviewer10Id: reviewers[9]?.id ?? null,
  } satisfies Prisma.CompensationReviewEmployeeUncheckedUpdateInput;
};

export const buildReviewersWherePayload = (
  ctx: CompensationReviewContext,
  where: Prisma.IntNullableFilter<"CompensationReviewEmployee"> | number
) => {
  const conditions = [
    { reviewer1Id: where },
    { reviewer2Id: where },
    { reviewer3Id: where },
    { reviewer4Id: where },
    { reviewer5Id: where },
    { reviewer6Id: where },
    { reviewer7Id: where },
    { reviewer8Id: where },
    { reviewer9Id: where },
    { reviewer10Id: where },
  ].slice(0, ctx.parameters.maxReviewersCount);

  return { OR: conditions } satisfies Prisma.CompensationReviewEmployeeWhereInput;
};

export const buildRevieweesWherePayload = (
  ctx: CompensationReviewContext,
  where: Prisma.CompensationReviewEmployeeWhereInput
) => {
  const conditions = [
    { reviewees1: { some: where } },
    { reviewees2: { some: where } },
    { reviewees3: { some: where } },
    { reviewees4: { some: where } },
    { reviewees5: { some: where } },
    { reviewees6: { some: where } },
    { reviewees7: { some: where } },
    { reviewees8: { some: where } },
    { reviewees9: { some: where } },
    { reviewees10: { some: where } },
  ].slice(0, ctx.parameters.maxReviewersCount);

  return { OR: conditions } satisfies Prisma.CompensationReviewReviewerWhereInput;
};
